import Repository from 'repository';
import API from 'API';
import { unfilteredSegmentList } from 'selectors/productSelector';
import { normalizeSegments } from 'reducers/products';

const persistProject = (action) => async (dispatch, getState, additional) => {
  if (_.isPlainObject(action)) action = _.partial(dispatch, action);
  const result = await action(dispatch, getState, additional);
  Repository.persistProject();
  return result;
};

function receivedProducts(products) {
  return {type: 'products/received', products: normalizeSegments(products)};
}

export function getProductsByPrefix(prefix) {
  return async (dispatch) => {
    const products = await API.getProducts({prefix});
    dispatch(receivedProducts(products))
  }
}

export function addLoad(id) {
  return persistProject({type: 'load/add', id});
}

export function removeLoad(id) {
  return persistProject({type: 'load/remove', id});
}

export function updateLoad(id, load) {
  return persistProject({type: 'load/update', id, load});
}

export function updateLoadPileFilter(loadId, filters) {
  return persistProject((dispatch, getState) => {
    const products = unfilteredSegmentList(getState());
    dispatch({type: 'load/pile/update', loadId, filters, products});
  });
}

export function setLoadLead(loadId, lead) {
  return persistProject((dispatch, getState) => {
    const products = unfilteredSegmentList(getState());
    const filters = _.pick(lead, 'helixConfig');
    dispatch({type: 'load/pile/update', loadId, lead, filters, products});
  });
}
