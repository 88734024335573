import {soilTestUnit} from 'config/soil-test-units';
import { SETTINGS as DEFAULT_SETTINGS} from 'reducers/projects/defaults';

export default function settings(state=DEFAULT_SETTINGS, action) {
  switch(action.type) {
    case 'SET_PROJECT_SETTINGS':
      return {...state, ...action.settings};
    case 'RECEIVED_NEW_TEST_UNIT_TYPE':
      return {...state, testUnit: soilTestUnit(action.testUnitType, state.system)};
    case 'RECEIVED_NEW_UNIT_TYPE':
      return {
        ...state,
        ..._.pick(action, ['lengthUnit', 'smallLengthUnit', 'forceUnit', 'interval', 'system', 'unitType']),
        testUnit: soilTestUnit(state.testUnit.value, action.system),
      };
    default:
      return state;
  }
}
