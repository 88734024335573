import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { IonCol, IonRow } from '@ionic/react';

import { readOnlySelector } from 'selectors/projectSelector';
import { ProjectContext } from 'components/layouts/Project';
import PileGraph from 'components/project/pile-visualizer/PileGraph';
import PileOptions from './PileOptions';

export default function Pile({load}) {
  const {settings, data} = useContext(ProjectContext);
  const readonly = useSelector(readOnlySelector);

  return (
    <IonRow className="narrow-container no-wrap">
      <IonCol size={3}>
        <PileGraph load={load}
          {..._.pick(data, 'blowCounts', 'boundaries', 'soilTypes')}
          {..._.pick(settings, 'interval', 'lengthUnit')}/>
      </IonCol>
      <IonCol>
        <PileOptions load={load} data={data} settings={settings} readonly={readonly}/>
      </IonCol>
    </IonRow>
  );
}
