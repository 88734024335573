import { useContext } from 'react';
import { ProjectContext } from 'components/layouts/Project';

export default function withProject(WrappedComponent) {
  return (props) => {
    const project = useContext(ProjectContext);

    return <WrappedComponent project={project} {...props}/>;
  };
};
