import { IonIcon, IonLabel, IonItem, IonInput, IonText } from '@ionic/react';
import { helpCircle } from 'ionicons/icons';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';
import useHelpAlert from 'hooks/useHelpAlert';

export default function SafetyFactor({safetyFactor, readonly, help}) {
  const showHelp = useHelpAlert({title: "Safety Factor", ...(_.isPlainObject(help) ? help : {content: help})});
  const changeSafetyFactor = (event) => ProjectSettingsActions.setSafetyFactor(event.target.value);

  return (
    <IonItem>
      <IonText aria-hidden onClick={showHelp}>
        Safety Factor <IonIcon icon={helpCircle}/>
      </IonText>
      <IonLabel aria-label="Safety Factor"/>
      <IonInput data-testid="safety-factor" type="number" min="1" step="0.5" style={{textAlign: "right"}} value={safetyFactor} onIonInput={changeSafetyFactor} readonly={readonly}/>
    </IonItem>
  );
}
