export function SegmentViews({value, allowBlank=false, children}) {
  const child = _.find(children, ({type, props}) => {
    return props?.value === value && type === SegmentView;
  });

  if (child === undefined && !allowBlank) {
    console.warn(`No SegmentView defined for value "${value}":\nThis will display a blank if no segment is present; set allowBlank true to suppress this message`)
  }

  return child || null;
}

export function SegmentView({value:_value, children}) {
  return children;
}
