import MainLayout from 'components/layouts/MainLayout';
import NavBar from './helpers/navbar';

export default function ProjectsLayout({children}) {
  return (
    <MainLayout navBar={
      <NavBar color="dark" tabs={[
        {title: "My Projects", path: "projects"},
        {title: "Global Projects", path: "global-projects"}
      ]}/>
    }>
      {children}
    </MainLayout>
  );
};
