import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonList, IonIcon, IonItemDivider, IonInput, IonItem, IonNote, IonAccordion, IonAccordionGroup, IonLabel, IonButton
} from '@ionic/react';
import { helpCircle, add, removeOutline } from 'ionicons/icons';

import HelpText from 'config/help-text';
import { readOnlySelector } from 'selectors/projectSelector';
import { addLoad, removeLoad, updateLoad } from 'actions/projectData';
import { getValue, stopPropagation } from 'helpers/events';
import { forceUnitDisplay } from 'helpers/unit-display';
import { DELETE_LOAD_CONFIRM_MESSAGE, loadChanged, loadName, loadNameDefault } from 'helpers/loads';
import { presentAsLine } from 'lib/text';
import useHelpAlert from 'hooks/useHelpAlert';

import withProject from 'components/helpers/withProject';
import SoilTestUnitSelector from './loads/SoilTestUnitSelector';
import LoadUnitSelector from './loads/LoadUnitSelector';
import SafetyFactor from './loads/SafetyFactor';
import PileCount from './loads/PileCount';
import LoadInput from './loads/LoadInput';

function Loads({project}) {
  const dispatch = useDispatch();
  const readonly = useSelector(readOnlySelector);
  const {settings, data: {loads, safetyFactor}} = project;

  const presentCapacityHelp = useHelpAlert({title: "Required Capacity", content: HelpText.loadDesign});
  const [openLoad, setOpenLoad] = useState(loads.length === 1 ? loads[0].id : null);
  const newLoad = () => {
    const newId = _.uuid();
    dispatch(addLoad(newId));
    setOpenLoad(newId);
  };
  const remove = (id) => () => {
    const load = _.find(loads, {id});
    if (loadChanged(load) || confirm(DELETE_LOAD_CONFIRM_MESSAGE)) {
      dispatch(removeLoad(id));
      if (openLoad === id) setOpenLoad(null);
    }
  };

  return (
    <IonList lines="full" className="narrow-container">
      <IonItemDivider>
        <h3>Project Settings</h3>
      </IonItemDivider>
      <SoilTestUnitSelector selected={settings.testUnit} system={settings.system} checked={settings.soilTestUnits} help={HelpText.soilTestUnits}/>
      <LoadUnitSelector settings={settings} />
      <SafetyFactor safetyFactor={safetyFactor} readonly={readonly} help={HelpText.safetyFactor}/>
      <IonItemDivider>
        <h3 onClick={presentCapacityHelp}>Required Capacity <IonIcon icon={helpCircle}/></h3>
        {loads.length <= 12 && !readonly && (
          <IonButton slot="end" color="secondary" onClick={newLoad}>
            <IonIcon icon={add} slot="start"/> Load
          </IonButton>
        )}
      </IonItemDivider>
      <IonAccordionGroup value={openLoad} data-testid="loads-list" onIonChange={getValue(setOpenLoad)}>
        {loads.map((load, index) => (
          <IonAccordion key={load.id} value={load.id} toggleIconSlot="start" className={`load-group load-${index}`}>
            <IonItem slot="header">
              <IonLabel>
                {loadName(load, index)}
                <p>
                  {presentAsLine(
                    load.compression && `Compression: ${forceUnitDisplay(load.compression, settings)}`,
                    load.tension && `Tension: ${forceUnitDisplay(load.tension, settings)}`,
                  )}
                </p>
              </IonLabel>
              <IonNote className="ion-padding">x{load.pileCount}</IonNote>
              {loads.length > 1 && !readonly && (
                <IonButton slot="end" color="warning" onClick={remove(load.id)}>
                  <IonIcon slot="icon-only" size="small" icon={removeOutline}/>
                </IonButton>
              )}
            </IonItem>
            <div slot="content">
              <IonItem>
                <IonInput label="Name"
                  type="text"
                  value={load.name}
                  placeholder={loadNameDefault(index)}
                  onIonChange={stopPropagation(getValue((v) => dispatch(updateLoad(load.id, {name: v}))))}/>
              </IonItem>
              <LoadInput load={load} loadType="compression" readonly={readonly} unit={settings.forceUnit}/>
              <LoadInput load={load} loadType="tension" readonly={readonly} unit={settings.forceUnit}/>
              <PileCount load={load} pileCount={load.pileCount} readonly={readonly}/>
            </div>
          </IonAccordion>
        ))}
      </IonAccordionGroup>
    </IonList>
  );
}

export default withProject(Loads);
