import SoilLogDeleteCallout from './SoilLogDeleteCallout';

const CONTROL_HEIGHT = 10;

export default class SoilBoundary extends React.Component {
  state = {
    dragging: false
  }

  boundaryLevelInput = React.createRef()

  componentDidMount() {
    this.setState({pixelRatio: this.getPixelRatio()});
  }

  getPixelRatio = () => {
    if (!this.boundaryLevelInput.current) return 0;
    return this.boundaryLevelInput.current.getBoundingClientRect().height / CONTROL_HEIGHT;
  }

  startDragging = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.setCallout();
    const startY = e.pageY || e.changedTouches[0].pageY;
    this.setState({dragging: true, startLevel: this.props.level, startY, pixelRatio: this.getPixelRatio()});
    const domNode = this.props.parentEventTarget;
    domNode.addEventListener('touchmove', this.setLevel, {passive: false});
    domNode.addEventListener('mousemove', this.setLevel, false);
    domNode.addEventListener('touchend', this.stopDragging, false);
    domNode.addEventListener('mouseup', this.stopDragging, false);
    return false;
  }

  stopDragging = (event) => {
    const {level, removeSoilLayer} = this.props;
    if (level == this.state.startLevel) {
      event.preventDefault();
      this.props.setCallout({Component: SoilLogDeleteCallout, level, removeSoilLayer});
    }
    this.setState({dragging: false, startLevel: null, startY: null});
    const domNode = this.props.parentEventTarget;
    domNode.removeEventListener('touchmove', this.setLevel);
    domNode.removeEventListener('mousemove', this.setLevel);
    domNode.removeEventListener('touchend', this.stopDragging);
    domNode.removeEventListener('mouseup', this.stopDragging);
  }

  setLevel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {startY, startLevel, pixelRatio} = this.state;
    const delta = (e.pageY || e.changedTouches[0].pageY) - startY;
    const levelChange = delta/pixelRatio/this.props.factorY;
    const newLevel = Math.round(startLevel + levelChange);
    this.props.updateSoilBoundary(newLevel);
  }

  render() {
    const {readonly, level, factorY, idNonce} = this.props;
    return (
      <rect
        className={[
            "boundary-control",
            readonly ? 'readonly' : '',
            this.state.dragging ? 'active' : ''
          ].join(' ')}
        x="-2"
        y={level * factorY - CONTROL_HEIGHT/2}
        width="14"
        height={CONTROL_HEIGHT}
        ref={this.boundaryLevelInput}
        style={{fill: readonly ? "" : `url(#slider-control-${idNonce})`}}
        onMouseDown={this.startDragging}
        onTouchStart={this.startDragging} />
    );
  }
}
