import { IonIcon, IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import { warning, helpCircle } from 'ionicons/icons';
import useHelpAlert from 'hooks/useHelpAlert';
import { getValue } from 'helpers/events';

export default function SelectItem({
  label, readonly, value, onValueChange, onChange, options, optionDisplay=_.identity,
  allowBlank=true, className, help, errorText
}) {
  const showHelp = useHelpAlert({title: label, ...(help || {})});
  const showError = useHelpAlert({title: "Warning", content: errorText});

  onChange = onChange || getValue((value) => onValueChange(parseInt(value, 10) || value));
  const onIonChange = (e) => onChange({target: {value: e.detail.value}});

  return (
    <IonItem className={className} aria-label={label}>
      <IonText slot="start" onClick={errorText ? showError : help && showHelp}>
        <span aria-hidden>{label}</span>
        {errorText ? (
          <IonIcon icon={warning} color="danger"/>
        ) : (
          help && <IonIcon icon={helpCircle} aria-label="help"/>
        )}
      </IonText>
      <IonLabel/>
      <IonSelect disabled={readonly} onIonChange={onIonChange} data-testid={`select-${label}`} value={value}>
        {allowBlank && <IonSelectOption value="">(all)</IonSelectOption>}
        {_.map(options, (option) => (
          <IonSelectOption key={option} value={option}>{optionDisplay(option)}</IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
}
