import { useState, useEffect } from 'react';
import { useSwiper } from 'swiper/react';
import { IonButtons, IonButton, IonIcon, IonTitle, IonToolbar, } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';

export default function SlideHeader({children, className, color}) {
  const swiper = useSwiper();
  const [showNav, setShowNav] = useState(false);
  useEffect(() => {
    const updateShowNav = () => setShowNav(swiper.slidesPerViewDynamic() < swiper.slides.length)
    swiper.on('resize', updateShowNav);
    return () => swiper.off('resize', updateShowNav);
  }, []);

  return (
    <IonToolbar color={color} mode="ios" className={className}>
      <IonTitle>{children}</IonTitle>
      {showNav && <>
        <IonButtons slot="start">
          <IonButton onClick={() => swiper.slidePrev()}>
            <IonIcon icon={chevronBackOutline}/>
          </IonButton>
        </IonButtons>
        <IonButtons slot="end">
          <IonButton onClick={() => swiper.slideNext()}>
            <IonIcon icon={chevronForwardOutline}/>
          </IonButton>
        </IonButtons>
      </>}
    </IonToolbar>
  );
}
