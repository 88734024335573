import { set } from 'lodash/fp';
import { CURRENT_SCHEMA_VERSION } from 'lib/constants';
import PartList from 'lib/part-list';
import { normalizeProject } from './normalize';
import { LOAD, PILE } from '../projects/defaults';

const transformValues = (object, transformation) => (
  _.transform(object, (result, value, key) => result[key] = transformation(value), {})
)

export const migrations = [
  function v1(project) {
    const setSchema = set('data.schema', 1);
    return _.flow([upgradeOldPiles, fixkN, setSchema])(project);

    function ensurePrefix(segment) {
      if (segment.prefix || !segment.sku) return segment;
      const prefix = _.get(segment.sku.replace('MHL', 'MH').match(/(MH.+BR?)/), 1);
      return {...segment, prefix};
    }
    function ensureHelixConfig(segment) {
      if (segment.helixConfig || !segment.sku) return segment;
      const helixConfig = segment.sku.match(/(\d+[SD])/g);
      return {...segment, helixConfig};
    }
    function upgradeOldPiles(project) {
      if (!_.get(project, 'data.pileSegments.length')) return project;

      const lead = ensureHelixConfig(ensurePrefix(_.get(project, 'data.pileSegments.0')));
      const pileLength = _.sumBy(_.get(project, 'data.pileSegments'), 'length')/12;
      const upgradedSegments = PartList.generatePile(lead, pileLength);
      project = set('settings.pile.pileLength', pileLength, project);
      return set('data.pileSegments', upgradedSegments, project)
    }
    function fixkN(project) {
      const isUsingKN = _.get(project, 'settings.forceUnit.value') === 'kn';
      if (!isUsingKN) return project;
      const convert = (value) => value / 0.100361135657 / 8.896443231;
      const convertedLoads = transformValues(_.get(project, 'data.loads'), convert);
      return set('data.loads', convertedLoads, project);
    }
  },
  function v2(project) {
    const setSchema = set('data.schema', 2);
    return _.flow([multipleLoads, setSchema])(project);

    function multipleLoads(project) {
      const newLoadFormat = {
        ...LOAD,
        id: _.uuid(),
        pileCount: _.get(project, 'data.pileCount', 1),
        pileSegments: _.get(project, 'data.pileSegments', []),
        pileOptions: _.get(project, 'settings.pile', PILE),
        ..._.pick(project.settings,
          'aboveGrade', 'batteredPile', 'batterAngle',
          'includeShaftFriction', 'reduceShaftFriction', 'includeFillSensitive', 'includeBedrock'
        ),
        ..._.get(project, 'data.loads', {})
      };
      return set('data.loads', [newLoadFormat], project);
    }
  }
]

export default function migrate(project) {
  const projectVersion = (project.data ?? {schema: 'unknown'}).schema;
  if (projectVersion === CURRENT_SCHEMA_VERSION || projectVersion === 'unknown') {
    return normalizeProject(project);
  }
  const migrationsToRun = _.flow(_.slice(migrations, projectVersion, CURRENT_SCHEMA_VERSION));
  return normalizeProject(migrationsToRun(project));
}
