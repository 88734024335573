import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import PUBLIC_PIN from "images/map-pin-bbbbbb.png";
import UNPUBLISHED_PIN from "images/map-pin-675d50.png";
import OWN_PIN from "images/map-pin-3556A4.png";

export { Marker, InfoWindow } from "@react-google-maps/api";

export const US_CENTER = {lat: 40, lng: -95};

const API_KEY = process.env.LEGACY_GOOGLE_API_KEY || process.env.GOOGLE_API_KEY
const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${API_KEY}`;

export const position = ({latitude, longitude}) => ({lat: parseFloat(latitude, 10), lng: parseFloat(longitude, 10)});

export const MARKER_COLORS = {
  PUBLIC: 'bbbbbb',
  UNPUBLISHED: '675d50',
  OWN: '3556A4'
};

const MARKER_PINS = {
  PUBLIC: PUBLIC_PIN,
  UNPUBLISHED: UNPUBLISHED_PIN,
  OWN: OWN_PIN
};
export const markerIconFor = (project) => {
  if (project.owned) return markerIconURL('OWN');
  if (project.published) return markerIconURL('PUBLIC');
  return markerIconURL('UNPUBLISHED');
}
export const markerIconURL = (colorName="OWN") => MARKER_PINS[colorName];

export default function Map({children, loadingElement, ...props}) {
  const {isLoaded} = useJsApiLoader({id: 'google-map-script', googleMapsApiKey: API_KEY });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{height: '100%'}}
      center={US_CENTER}
      mapTypeId="terrain"

      {...props}
    >
      {children}
    </GoogleMap>
  ) : (
    loadingElement || <div className="padding">loading...</div>
  );
}
