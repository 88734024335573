import { capacities } from 'lib/capacity-calculations';
import maxGraphDepth from 'lib/graph-depth';
import DepthGraph from 'components/graph/DepthGraph';


export default function CapacityGraph({project={}, load, maxDim}) {
  const {data, settings: {lengthUnit, forceUnit}} = project;
  const loads = load ? [load] : data?.loads;
  const loadIndex = (id) => _.findIndex(data?.loads, {id});

  return (
    <DepthGraph
      loads={
        _.map(loads, ({id, name, compression, tension}) => ({
          id, name,
          data: capacities(project, loadIndex(id)),
          envelope: [capacities(project, loadIndex(id), -1), capacities(project, loadIndex(id), 1)],
          guides: {
            'Required Compression Capacity': compression,
            'Required Tension Capacity': tension
          }
        }))
      }
      unitX={forceUnit}
      unitY={lengthUnit}
      maxDepth={maxGraphDepth({data: project.data})}
      baseScale="25"
      maxDim={maxDim} />
  );
}
