import { useSelector } from 'react-redux';

import torqueUnitFor from 'config/torque-units';
import maxGraphDepth from 'lib/graph-depth';
import { displayForUnit, smallUnitDisplay } from 'helpers/unit-display';
import { loadName } from 'helpers/loads';
import { cutSheetPresent } from 'helpers/report';
import { productTypesSelector } from 'selectors/productSelector';

import PileGraph from 'components/project/pile-visualizer/PileGraph';
import TorqueGraph from './TorqueGraph';
import CapacityGraph from './CapacityGraph';
import Page from './Page';
import CutSheetPage from './CutSheetPage';

export default function LoadPages({project, load, index, singleLoad}) {
  const {settings, data} = project;
  const torqueUnit = torqueUnitFor(settings?.lengthUnit);
  const smallLengthUnitDisplay = displayForUnit(settings?.smallLengthUnit);
  const lengthUnitDisplay = displayForUnit(settings?.lengthUnit);
  const lead = _.first(load?.pileSegments);
  const maxDepth = maxGraphDepth(project);
  const productTypes = useSelector(productTypesSelector);
  const hasCutSheet = cutSheetPresent(productTypes, load.pileOptions);

  return (
    <>
      <Page>
        {!singleLoad && <h1>{loadName(load, index)}</h1>}
        <div className="uk-grid uk-grid-small">
          <div className="uk-width-1-5">
            <h2>Pile</h2>
            <div style={ {maxHeight: '8in', overflow: 'hidden'} }>
              <PileGraph load={load}
                {..._.pick(data, 'blowCounts', 'boundaries', 'soilTypes')}
                {..._.pick(settings, 'interval', 'lengthUnit')}
                maxDepth={maxDepth}
                maxDim={{width: 300, height: 800}}/>
            </div>
          </div>
          <div className="uk-width-4-5">
            <h2>Configuration</h2>
            <table className="uk-table uk-table-condensed">
              <tbody>
                <tr><th>Number of Piles</th><td>{data.pileCount}</td></tr>
                <tr><th>Shaft</th><td>{load.pileOptions.prefix}</td></tr>
                {lead && <>
                  <tr><th>Shaft Diameter</th><td>{smallLengthUnitDisplay(lead.diameter)}</td></tr>
                  <tr><th>Shaft Wall Thickness</th><td>{smallLengthUnitDisplay(lead.wall, 2)}</td></tr>
                  <tr><th>Shaft Coupling</th><td>{lead.coupling}</td></tr>
                </>}
                <tr><th>Coating</th><td>{load.pileOptions.coating}</td></tr>
                <tr><th>Pitch</th><td>{smallLengthUnitDisplay(load.pileOptions.pitch)}</td></tr>
                <tr>
                  <th>Length</th>
                  <td>
                    {lengthUnitDisplay(load.pileOptions.pileLength + load.aboveGrade)}&nbsp;
                    {load.aboveGrade != 0 && (
                      <em>({lengthUnitDisplay(Math.abs(load.aboveGrade))} {load.aboveGrade > 0 ? "above" : "below" } grade)</em>
                    )}
                  </td>
                </tr>
                {load.batteredPile && !!load.batterAngle && (
                  <tr><th>Batter Angle</th><td>{load.batterAngle}°</td></tr>
                )}
                <tr>
                  <th>Helix Config</th>
                  <td>
                    {load.pileOptions.helixConfig}
                    <ul>
                      {_.map(lead?.helices, ({type, spacing, diameter}, i) => (
                        <li key={i}>
                          {_.capitalize(type)}&nbsp;
                          {smallUnitDisplay(diameter, settings?.smallLengthUnit)}&nbsp;
                          <em>(spacing: {smallUnitDisplay(spacing, settings?.smallLengthUnit)})</em>
                        </li>
                      ))}
                    </ul>
                    <small><em>*Note:  Helical spacing is conceptual.  Actual manufactured helix spacing may vary.</em></small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Page>
      <Page>
        {!singleLoad && <h1>{loadName(load, index)}</h1>}
        <div className="uk-grid uk-grid-small">
          <div className="uk-width-2-4">
            <h2>Allowable Capacity ({settings?.forceUnit.abbreviation})</h2>
            <div style={{maxHeight: '7.25in', overflow: 'hidden'}}>
              <CapacityGraph project={project} load={load} maxDim={{width: 325, height: 700}}/>
            </div>
          </div>
          <div className="uk-width-2-4">
            <h2>Torque ({torqueUnit.abbreviation})</h2>
            <div style={{maxHeight: '7.25in', overflow: 'hidden'}}>
              <TorqueGraph project={project} load={load} maxDim={{width: 325, height: 700}} />
            </div>
          </div>
        </div>
      </Page>
      {hasCutSheet && <CutSheetPage/>}
    </>
  );
}
