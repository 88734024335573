import { useState, useEffect } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IonButton, IonList, IonItem } from '@ionic/react';

import { loggedInSelector, errorsSelector as userErrorsSelector } from 'selectors/userSelector';
import { appIsOnline } from 'selectors/AppSelector';

import AuthenticationActions from 'actions/AuthenticationActions';
import UnauthenticatedLayout from 'components/layouts/Unauthenticated';
import MagnumInput from '../helpers/MagnumInput';

function Login() {
  const history = useHistory();
  const location = useLocation();
  const online = useSelector(appIsOnline);
  const loggedIn = useSelector(loggedInSelector);
  const error = useSelector(userErrorsSelector);
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  useEffect(()   => {
    if (loggedIn) {
      if (location.state && location.state.nextPathname) {
        history.replace(location.state.nextPathname);
      } else {
        history.push('/projects');
      }
    }
  }, [loggedIn]);

  const login = (event) => {
    event.preventDefault();
    const {email, password} = state;
    AuthenticationActions.login(email, password);
  }

  const setField = (field) => (e) => {
    setState((state) => ({...state, [field]: e.target.value, error: false}));
  };
  const {email, password} = state;

  return (
    <UnauthenticatedLayout>
      <h2 className="ion-padding">Welcome</h2>

      <form onSubmit={login}>
        <IonList lines="full">
          {online ? <>
            {error && (
              <IonItem color="warning">
                The email or password you entered is incorrect
              </IonItem>
            )}
            <IonItem>
              <MagnumInput label="Email"
                type="email"
                value={email}
                onIonChange={setField('email')}
                placeholder="yourname@example.com"
                autoComplete="username"
                required/>
            </IonItem>
            <IonItem>
              <MagnumInput label="Password"
                type="password"
                value={password}
                onIonChange={setField('password')}
                placeholder="****"
                autoComplete="current-password"
                required/>
            </IonItem>
            <div className="ion-padding">
              <IonButton expand="block" type="submit" color="primary">
                Login
              </IonButton>
              <br/>
              <IonButton expand="block" type="button" color="dark" routerLink="/passwordmanager">
                Reset Password
              </IonButton>
            </div>
          </> : <>
            <IonItem color="warning">You must be online to log in</IonItem>
            <IonButton expand="full" type="button" onClick={login}>
              Retry
            </IonButton>
          </>}
        </IonList>
      </form>
    </UnauthenticatedLayout>
  );
}

export default withRouter(Login);
