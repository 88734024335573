import MainLayout from 'components/layouts/MainLayout';
import ProjectForm from 'components/project/ProjectForm';

export default function NewProject() {
  return (
    <MainLayout title="New Project" parentPath="/projects" parentName="Projects">
      <ProjectForm/>
    </MainLayout>
  );
}
