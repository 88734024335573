import { map } from 'lodash/fp';
import { useEffect, useState, useMemo } from 'react';
import { Prompt, withRouter } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  IonButton, IonList, IonSpinner, IonCheckbox, IonItem, IonSegment, IonSegmentButton, IonText
} from '@ionic/react';
import Geocode from 'react-geocode';

import { userInfoSelector } from 'selectors/userSelector';
import { projectsSelector, projectStatusSelector } from 'selectors/projectSelector';
import ProjectActions from 'actions/ProjectActions';

import useModel from 'hooks/useModel';
import { preventDefault, getValue } from 'helpers/events';
import MagnumInput from 'components/helpers/MagnumInput';
import withProject from 'components/helpers/withProject';
import { withOnline } from 'components/helpers/online-helper';
import CountrySelect from 'components/helpers/CountrySelect';
import CoordinateSelection from 'components/project/CoordinateSelection';

Geocode.setApiKey(process.env.GOOGLE_API_KEY);

function geocodeAddress(address) {
  return Geocode.fromAddress(address).then(
    (response) => response.results[0].geometry.location,
    (_error) => ({})
  );
}

const deriveProject = (props, copiedName) => {
  const {project={}} = props;

  return {
    ...project,
    name: copiedName ?? project.name,
    client: project.client || "",
    address: project.address || "",
    city: project.city || "",
    state: project.state || "",
    zipcode: project.zipcode || "",
    country: project.country || "US",
    isPrivate: !project.published,
    verified: project.verified !== false
  }
}

const ADDRESS_FIELDS = ["address", "city", "state", "zipcode", "country"];
const COORDINATE_FIELDS = ["latitude", 'longitude'];
const projectNamesSelector = createSelector(projectsSelector,map('name'));

const ENDING_DIGIT = /(\s+)?\d+$/;
const PROJECT_FIELDS = ["name", "client", ...ADDRESS_FIELDS, ...COORDINATE_FIELDS, "schema", "data", "settings"];

export function ProjectForm(props) {
  const location = useLocation();
  const history = useHistory();

  const {global_editor} = useSelector(userInfoSelector);

  const copyMode = useMemo(() => {
    return !!location.pathname.match(/copy/);
  }, [location.pathname]);

  const projectNames = useSelector(projectNamesSelector);

  const copiedName = useMemo(() => {
    if (copyMode) {
      let newName = props.project.name + ' copy';

      while (_.includes(projectNames, newName)) {
        while (_.includes(projectNames, newName)) {
          const trailingNumber = _.get(newName.match(/\d+$/), '0', 1);
          const newVersion = parseInt(trailingNumber, 10) + 1;
          newName = newName.replace(ENDING_DIGIT, '') + ' ' + newVersion;
        }
      }

      return newName;
    }
  }, [props.project?.name, copyMode, projectNames]);

  const [project, setProjectProperty, mergeProject, setProject] = useModel(deriveProject(props, copiedName));

  const [submitted, setSubmitted] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [mapKey, setMapKey] = useState('initial');

  const existingNoAddress = !zipcode && longitude;
  const [activeTab, setActiveTab] = useState(existingNoAddress ? "coordinates" : "address");
  const [dirtyLocation, setDirtyLocation] = useState(() => project.latitude && project.longitude);

  const projectStatus = useSelector(projectStatusSelector);
  useEffect(() => {
    if (copyMode && project.id === projectStatus.projectID) {
      ProjectActions.setCurrentProject();
    }
  }, [copyMode, projectStatus]);
  const [error, setError] = useState(projectStatus.error);
  const [errorMsg, setErrorMsg]= useState(projectStatus.errorMsg);

  const {can_publish:userCanPublish=false, is_admin:isAdmin=false} = useSelector(userInfoSelector);

  useEffect(() => {
    const {lastUpdateSuccess=false, error, errorMsg, projectID} = projectStatus;

    // I'm not sure I understand the specified behavior here; this is behaving oddly
    if (submitted && lastUpdateSuccess && projectID) {
      setSubmitted(false);
      history.push(`/projects/${projectID}/loads`);
    } else if (error) {
      setSubmitted(false);
      setError(error);
      setErrorMsg(errorMsg);
    } else {
      setDirty(false);
    }
  }, [submitted, projectStatus]);

  useEffect(() => {
    deriveProject(props, copiedName);
  }, [location.pathname]);

  useEffect(() => ProjectActions.clearErrors(), []);

  const set = (property, value) => {
    const isLocation = _.includes(['latitude', 'longitude'], property);
    setProjectProperty(property, value);
    setDirty(true);
    setDirtyLocation(isLocation);
  }
  const setValue = (name) => (e) => set(name, e.target.value ?? e.target.checked);

  const selectTab = (tabname) => {
    setActiveTab(tabname);
  };

  const getProjectField = (f) => project[f];
  useEffect(() => {
    const fullAddress = _.join(_.compact(_.map(ADDRESS_FIELDS, getProjectField)), ", ");
    const userProvidedLocation = (project.longitude && project.latitude) || dirtyLocation;
    const addressPresent = fullAddress.length > 2;

    if (addressPresent && !userProvidedLocation) {
      geocodeAddress(fullAddress).then(({lat, lng}) => {
        setMapKey(fullAddress);
        mergeProject({
          latitude: _.round(lat, 8),
          longitude: _.round(lng, 8),
        });
        setDirtyLocation(false);
      });
    }
  }, [..._.map(ADDRESS_FIELDS, getProjectField), ..._.map(COORDINATE_FIELDS, getProjectField)]);

  const persistProject = () => {
    const {id} = project;
    setSubmitted(true);
    setError(null);
    setErrorMsg(null);

    const published =  userCanPublish ? !project.isPrivate : undefined;
    const verified =  isAdmin ? project.verified : undefined;

    if (id && !copyMode) {
      ProjectActions.updateProjectSettings({..._.pick(project, PROJECT_FIELDS), published, verified, id}, props.project);
    } else {
      ProjectActions.addProject({..._.pick(project, PROJECT_FIELDS), published, verified, owned: true});
    }
  }

  const {online} = props;

  const {
    name, client, address, city, state, zipcode, country,
    isPrivate, verified, latitude, longitude,
  } = project;
  const readonly = !copyMode && project.id && !project.owned && !global_editor;

  return (
    <div className="narrow-container">
      <Prompt when={dirty && !submitted} message="You have unsaved changes! Do you want to continue without saving?" />
      {error && (
        <div className="ion-padding">
          <p className="alert error">{errorMsg}</p>
        </div>
      )}
      <form className="list" onSubmit={preventDefault(persistProject)} disabled={submitted}>
        <IonList>
          <IonItem>
            <MagnumInput required={!readonly} value={name} onChange={setValue('name')} disabled={readonly}>
              <div slot="label">Project Name<IonText color="danger">*</IonText></div>
            </MagnumInput>
          </IonItem>
          <IonItem>
            <MagnumInput label="Client" value={client} onChange={setValue('client')} disabled={readonly}/>
          </IonItem>
          <br/>
          <IonSegment value={activeTab} onIonChange={preventDefault(getValue(selectTab))} mode="md">
            <IonSegmentButton value="address">Address</IonSegmentButton>
            <IonSegmentButton value="coordinates">Coordinates</IonSegmentButton>
          </IonSegment>

          <div style={{display: activeTab !== "address" ? 'none' : null}}>
            <IonItem>
              <MagnumInput label="Address" value={address} onChange={setValue('address')} disabled={readonly}/>
            </IonItem>

            <IonItem>
              <MagnumInput label="City" value={city} onChange={setValue('city')} disabled={readonly}/>
            </IonItem>

            <IonItem>
              <MagnumInput label="State/Province" value={state} onChange={setValue('state')}  disabled={readonly}/>
            </IonItem>

            <IonItem required={!readonly && !longitude && !latitude}>
              <MagnumInput label="Postal Code" value={zipcode} onChange={setValue('zipcode')} disabled={readonly}/>
            </IonItem>

            <IonItem>
              <CountrySelect value={country || 'US'} onChange={(e) => set('country', e.target.value)} disabled={readonly} labelPlacement="floating"/>
            </IonItem>
          </div>

          <div style={{display: activeTab !== "coordinates" ? 'none' : null}}>
            <CoordinateSelection key={mapKey} location={{latitude, longitude}} onChange={set} readonly={readonly}/>
            {!readonly && online && (
              <IonItem color="tertiary">
                  {latitude && longitude ? (
                    "Drag the map marker to change the project's location."
                  ) : (
                    "Click the project's location on the map to set the latitude and longitude."
                  )}
              </IonItem>
            )}
            <IonItem>
              <MagnumInput label="Latitude" value={latitude || ''} disabled={readonly} onChange={setValue('latitude')}/>
            </IonItem>

            <IonItem>
              <MagnumInput label="Longitude" value={longitude || ''} disabled={readonly} onChange={setValue('longitude')}/>
            </IonItem>
          </div>


          {userCanPublish && !readonly && (
            <IonItem>
              <IonCheckbox color="primary"
                checked={isPrivate}
                onIonChange={getValue(setProjectProperty('isPrivate'))}
                labelPlacement="end"
                justify="start"
              >
                Private
              </IonCheckbox>
            </IonItem>
          )}

          {isAdmin && (
            <IonItem>
              <IonCheckbox color="primary"
                checked={verified}
                onIonChange={getValue(setProjectProperty('verified'))}
                labelPlacement="end"
                justify="start"
              >
                Verified
              </IonCheckbox>
            </IonItem>
          )}
          <IonItem lines="none">
            {!readonly && <IonText color="danger">* = required fields</IonText>}
          </IonItem>

          <div className="padding">
            {(isAdmin || !readonly) && (
              <IonButton expand="block" type="submit" disabled={submitted}>
                {submitted && <IonSpinner name="lines-sharp-small"/>}
                SAVE {copyMode && 'COPY'}
              </IonButton>
            )}
          </div>
        </IonList>
      </form>
    </div>
  );
}

export default withProject(withOnline(withRouter(ProjectForm)));
