import { IonList, IonItem, IonLabel } from '@ionic/react';
import SoilLabels from 'config/soil-labels';
import SoilLogCallout from './SoilLogCallout';

export default function SoilLogInfoCallout({x, y, selectedSoil, startLevel, endLevel}) {
  const {label='undefined'} = _.find(SoilLabels, {value: selectedSoil}) || {};

  return (
    <SoilLogCallout y={y} x={x}>
      <IonList lines="none" color="dark">
        <IonItem>
          <IonLabel><h1>{label}</h1></IonLabel>
        </IonItem>
      </IonList>
    </SoilLogCallout>
  );
}
