import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonInput, IonList, IonItem } from '@ionic/react';

import UserActions from 'actions/UserActions';
import AuthenticationActions from 'actions/AuthenticationActions';

import MainLayout from 'components/layouts/MainLayout';
import { withOnline } from 'components/helpers/online-helper';
import { useSelector } from 'react-redux';
import { destroySuccessSelector, destroyRequestSuccessSelector, errorsSelector, loggedInSelector } from '../../selectors/userSelector';

export default withOnline(function UserDestroy({online, location}) {
  const history = useHistory();

  const [email, setEmail] = useState(_.get(location, 'query.email') || '');
  const loggedIn = useSelector(loggedInSelector);
  const token = _.get(location, 'query.t');
  const errors = useSelector(errorsSelector)
  const destroySuccess = useSelector(destroySuccessSelector)
  const destroyRequestSuccess = useSelector(destroyRequestSuccessSelector);

  const deleteUser = () => {
    UserActions.delete().then(() => {
      AuthenticationActions.logout();
      history.push({pathname: '/'});
    }).catch((error) => {
      console.warn(error);
    });
  };

  const isAuthorized = email && token;

  return (
    <MainLayout title="Delete Account" narrow>
      {errors && <p className="error">{errors}</p>}
      {destroySuccess && <p className="success">Your account has been deleted.</p>}
      {destroyRequestSuccess && <p className="success">An email has been sent to confirm account deletion.</p>}

      {(loggedIn || isAuthorized) ? (
        <div className="ion-padding">
          <p>If you delete your account, all of your projects will also permanently be deleted after backups are purged in 60 days.</p>
          <IonButton color="warning"
            onClick={loggedIn ? deleteUser : () => UserActions.deleteUnauthenticated({email, token})}
          >
            Permanently Delete Account and Projects
          </IonButton>
        </div>
      ) : (
        <div>
          <p className="ion-padding">Enter your email below to send an email to your account to delete it.</p>
          <form onSubmit={() => UserActions.requestDestroy(email)}>
            <IonList lines="full">
              <IonItem>
                <IonInput label="Email"
                  labelPlacement="floating"
                  type="email"
                  value={email}
                  onIonChange={(e) => setEmail(e.target.value)}
                  autoComplete="username"
                  required/>
                </IonItem>
              </IonList>
            <IonButton color="warning" type="submit" expand="block">
              Delete Account
            </IonButton>
          </form>
        </div>
      )}
    </MainLayout>
  );
});
