import { IonBadge, IonIcon, IonLabel, IonItem, IonSegment, IonSegmentButton, IonText } from '@ionic/react';
import { helpCircle } from 'ionicons/icons';
import HelpText from 'config/help-text';
import useHelpAlert from 'hooks/useHelpAlert';

export default function ShaftFrictionSelector({includeShaftFriction, reduceShaftFriction, groutedShaft, onValueChange}) {
  const showHelp = useHelpAlert({title: "Safety Factor", content: HelpText.shaftFriction});

  const setFriction = (value) => {
    onValueChange({
      includeShaftFriction: value !== 'Off',
      reduceShaftFriction: value === 'Reduce'
    });
  };

  return (
    <IonItem className="responsive-wrap">
      <IonText slot="start" onClick={showHelp}>
        Shaft Friction <IonIcon icon={helpCircle}/>
      </IonText>
      <IonLabel/>
      {groutedShaft ? (
        <IonBadge color="primary" mode="md">Included for grouted shafts</IonBadge>
      ) : (
        <IonSegment value={includeShaftFriction ? (reduceShaftFriction ? 'Reduce' : 'Include') : 'Off'} mode="ios">
          {['Include', 'Reduce', 'Off'].map((option) => (
            <IonSegmentButton key={option} value={option} onClick={() => setFriction(option)}>
              {option}
            </IonSegmentButton>
          ))}
        </IonSegment>
      )}
    </IonItem>
  );
}
