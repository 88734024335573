import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import UnitLabels from 'config/unit-labels';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';

export default function LoadUnitSelector({settings}) {
  const {unitType:selected} = settings;
  const changeUnits = (e) => ProjectSettingsActions.setUnits(e.detail.value);

  return (
    <IonItem>
      <IonSelect label="Primary units" onIonChange={changeUnits} value={selected}>
        {UnitLabels.map(({label, value}) => (
          <IonSelectOption key={value} value={value}>{label}</IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
}
