import { renderToStaticMarkup } from 'react-dom/server';
import { useIonAlert } from '@ionic/react';

export default function useHelpAlert({
  title="Help", content, image, checkBox, checkedAction, ...props
}) {
  const [present] = useIonAlert();

  return () => present({
    ...props,
    header: title,
    message: renderToStaticMarkup(
      <div className="preformatted">
        {content}{image && <img width="280" src={image} />}
      </div>
    ),
    inputs: checkBox ? [
      {type: "checkbox", label: checkBox, labelPlacement: "end", value: 'checked'}
    ] : [],
    buttons: ['Close'],
    onDidDismiss: ({detail: {data: {values}={}}}) => {
      if (checkedAction && _.includes(values, 'checked')) checkedAction();
    },
  })
}
