import { useLocation, useHistory } from 'react-router-dom';
import { IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';

export default function NavBar({tabs, pathPrefix='/', color}) {
  const location = useLocation();
  const history = useHistory();
  const navigate = (path) => (_e) => history.push(pathPrefix + path);

  return (
    <IonToolbar color={color} mode="md">
      <IonSegment value={_.replace(location.pathname, pathPrefix, '')}>
        {tabs.map(({title, path}) => (
          <IonSegmentButton key={path} value={path} onClick={navigate(path)}>
            {title}
          </IonSegmentButton>
        ))}
      </IonSegment>
    </IonToolbar>
  );
}
