import { IonRouterLink } from '@ionic/react';
import { userInfo } from 'config/terms-conditions';
import { useSelector } from 'react-redux';

import { loggedInSelector } from '../../selectors/userSelector';

import MainLayout from 'components/layouts/MainLayout';

export default function UserInfo() {
  const loggedIn = useSelector(loggedInSelector);

  return (
    <MainLayout title="Information Policy"
      parentPath={loggedIn && '/account'}
      parentName={loggedIn && 'Account'}
      narrow
    >
      <div className="ion-padding">
        <p>{userInfo}</p>
        <p>See <IonRouterLink routerLink="privacy">Privacy Policy</IonRouterLink></p>
      </div>
    </MainLayout>
  );
}
