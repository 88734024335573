import store from 'store';
import Repository from 'repository';

export default {
  setSafetyFactor(factor) {
    store.dispatch({type: 'RECEIVED_NEW_SAFETY_FACTOR', factor});
    Repository.persistProject();
  },

  setUnits(unitType) {
    store.dispatch({type: 'RECEIVED_NEW_UNIT_TYPE', unitType});
    Repository.persistProject();
  },

  setTestUnit(testUnitType) {
    store.dispatch({type: 'RECEIVED_NEW_TEST_UNIT_TYPE', testUnitType});
    Repository.persistProject();
  },

  set(settings) {
    store.dispatch({type: 'SET_PROJECT_SETTINGS', settings});
    Repository.persistProject();
  }
};
