import { IonIcon, IonInput, IonItem, IonLabel, IonText } from '@ionic/react';
import { warning, helpCircle } from 'ionicons/icons';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';
import useHelpAlert from 'hooks/useHelpAlert';
import { numerifyInputValue } from 'helpers/events';

export default function NumberInput({
  label, property, value, onValueChange, unit, max, min, readonly, help, errorText, className
}) {
  const showHelp = useHelpAlert({title: label, ...(_.isPlainObject(help) ? help : {content: help})});
  const showError = useHelpAlert({title: "Warning", content: errorText});

  const displayValue = _.round(unit?.toDisplay(value), 2);
  const setValue = (value) => ProjectSettingsActions.set({[property]: value});
  const onIonChange = numerifyInputValue((value) => (onValueChange || setValue)(unit?.toBase(value)));

  return (
    <IonItem className={className}>
      <IonText slot="start" aria-label={label} onClick={errorText ? showError : help && showHelp}>
        {label}
        {errorText ? (
          <IonIcon icon={warning} color="danger"/>
        ) : (
          help && <IonIcon icon={helpCircle}/>
        )}
      </IonText>
      <IonLabel/>
      <IonText slot="end">
        {unit?.label}
      </IonText>
      <IonInput type="number"
        value={displayValue}
        readonly={readonly}
        max={max}
        min={min}
        step="1"
        onIonInput={onIonChange}
        errorText={errorText}/>
    </IonItem>
  );
}
