import MainLayout from 'components/layouts/MainLayout';
import NavBar from 'components/layouts/helpers/navbar'

export default function UnauthenticatedLayout({children}) {
  return (
    <MainLayout
      navBar={
        <NavBar color="dark" tabs={[
          {title: "Log in", path: "login"},
          {title: "Sign up", path: "registration"}
        ]}/>
      }
      narrow
    >
      {children}
    </MainLayout>
  );
}
