import { IonIcon } from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import { numerifyInputValue, stopPropagation } from 'helpers/events';

import SoilLogCallout from './SoilLogCallout';

export default function FineAdjustmentCallout({y, x, value, precision, setBlowCount}) {
  const incrementValue = 10**(-precision);
  return (
    <SoilLogCallout y={y} x={x} onClick={stopPropagation()}>
      <button onClick={() => setBlowCount(value - incrementValue)}>
        <IonIcon icon={chevronBack}/>
      </button>
      <input type="number" value={value} onChange={numerifyInputValue(setBlowCount)}/>
      <button onClick={() => setBlowCount(value + incrementValue)}>
        <IonIcon icon={chevronForward}/>
      </button>
    </SoilLogCallout>
  );
}
