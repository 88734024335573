import { useSelector } from 'react-redux';

import torqueUnitFor from 'config/torque-units';
import SoilList from 'config/soil-labels';
import { requiredInstallTorque } from 'lib/capacity-calculations';
import { toSignificant } from 'lib/number';
import { displayForUnit } from 'helpers/unit-display';
import { loadName } from 'helpers/loads';
import { LOADS_PER_PAGE, reportPageIndex } from 'helpers/report';

import { userInfoSelector } from 'selectors/userSelector';
import { productTypesSelector } from 'selectors/productSelector';

import { usePageNumber } from 'hooks/usePageNumber';
import StaticMap from 'components/helpers/static-map';
import SoilLog from 'components/project/soil-log/SoilLog';
import Page from './report/Page';
import LoadPages from './report/LoadPages';

import CALCULATIONS_PAGE from 'images/calculations.png';

export default function Report({project}) {
  const {settings, data} = project;
  const user = useSelector(userInfoSelector)
  const torqueUnit = torqueUnitFor(settings?.lengthUnit);
  const forceUnitDisplay = displayForUnit(settings?.forceUnit);
  const smallLengthUnitDisplay = displayForUnit(settings?.smallLengthUnit);
  const productTypes = useSelector(productTypesSelector);
  const singleLoad = data?.loads.length === 1 ? _.first(data?.loads) : false;
  const pageIndex = reportPageIndex(project, productTypes);
  const tableOfContentsColumns = Math.min(_.ceil(pageIndex.length / 15), 3);
  usePageNumber(0);

  return (
    <div>
      <Page>
        <div className="uk-grid">
          <div className="uk-width-1-2">
            <h2>Prepared by</h2>
            <table className="uk-table uk-table-condensed"><tbody>
              <tr>
                <th>Designer</th>
                <td>{ user.name }</td>
              </tr>
              <tr>
                <th>Company</th>
                <td>{ user.company }</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{ user.address }<br/>{ user.city }, { user.state }  { user.zipcode }</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{ user.country }</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{ user.phone }</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{ user.email }</td>
              </tr>
            </tbody></table>
          </div>
          <div className="uk-width-1-2">
            <h2>Prepared for</h2>
            <table className="uk-table uk-table-condensed"><tbody>
              <tr>
                <th>Client</th>
                <td>{ project.client }</td>
              </tr>
              <tr>
                <th>Project Name</th>
                <td>{ project.name }</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{ project.address }<br/>{ project.city }, { project.state }  { project.zipcode }</td>
              </tr>
            </tbody></table>
          </div>
          <div className="uk-width-1-2">
            {!!project.latitude && <h2>Project Location</h2>}
            <StaticMap
              height="220"
              width="380"
              zoom="9"
              latitude={project.latitude}
              longitude={project.longitude}
            />
          </div>
        </div>
        <div style={{width: tableOfContentsColumns > 1 ? '100%' : '50%', margin: '1em auto'}}>
          <h2 className="uk-text-center">Table of Contents</h2>
          <div className="uk-grid">
          {_.chunk(pageIndex, _.ceil(pageIndex.length / tableOfContentsColumns)).map((column, i) => (
          <div key={i} className={`uk-width-1-${tableOfContentsColumns}`}>
            <table className="uk-table uk-table-condensed">
              <thead>
                <tr><th>Contents</th><th>Page</th></tr>
              </thead>
              <tbody>
                {column.map(({title, pageNumber}) => (
                  <tr key={title + pageNumber}><td>{title}</td><td>MP{pageNumber}</td></tr>
                ))}
              </tbody>
            </table>
        </div>
      ))}
      </div>
        </div>
      </Page>
      <Page>
        <h2>Introduction</h2>
        <p>
          The following calculations were prepared using Magnum&reg; GeoApp&reg; software.  This software is offered
          exclusively for use by design professionals and construction estimators for sizing helical piles manufactured
          by Magnum Piering, Inc.  The software utilizes the individual bearing method of helical pile bearing/pullout
          capacity estimation and theoretical capacity:torque ratios as described by Perko (2009), <em>Helical Piles:
          A Practical Guide to Design and Installation</em>, Wiley, New York.
        </p>
        {!!project.latitude && <h2>Approximate Pile Locations</h2>}
        <StaticMap
          height="350"
          width="1000"
          zoom="14"
          latitude={project.latitude}
          longitude={project.longitude}
          maptype="satellite"/>
        <div className="uk-grid">
          <div className="uk-width-1-2">
            <h2>Design Options</h2>
            <table className="uk-table uk-table-condensed"><tbody>
              <tr>
                <th>Soil Strength Test Units</th>
                <td>{settings?.testUnit?.label}</td>
              </tr>
              <tr>
                <th>Project Units</th>
                <td>{settings?.forceUnit?.label} - {settings?.lengthUnit.label}</td>
              </tr>
              <tr>
                <th>Factor of Safety</th>
                <td>{data?.safetyFactor}</td>
              </tr>
              {singleLoad && <>
                <tr>
                  <th>Include Shaft Friction:</th>
                  <td>{(singleLoad.includeShaftFriction || singleLoad.groutedShaft) ? 'YES' : 'NO'}</td>
                </tr>
                <tr>
                  <th>Reduce Shaft Friction</th>
                  <td>{singleLoad.reduceShaftFriction ? 'YES' : 'NO'}</td>
                </tr>
                <tr>
                  <th>Grouted Shaft</th>
                  <td>{singleLoad.groutedShaft ? 'YES' : 'NO'}</td>
                </tr>
                <tr>
                  <th>Grout Diameter</th>
                  <td>
                    {singleLoad.groutedShaft ? (
                      singleLoad.groutDiameter ? smallLengthUnitDisplay(singleLoad.groutDiameter) : 'not specified'
                    ) : 'n/a'}
                  </td>
                </tr>
              </>}
            </tbody></table>
          </div>
          {singleLoad && (
            <div className="uk-width-1-2">
              <h2>Design Loads</h2>
              <table className="uk-table uk-table-condensed"><tbody>
                {singleLoad.compression > 0 && (
                  <tr>
                    <th>Compression</th>
                    <td>{forceUnitDisplay(singleLoad.compression)}</td>
                  </tr>
                )}
                {singleLoad.tension > 0 && (
                  <tr>
                    <th>Tension</th>
                    <td>{forceUnitDisplay(singleLoad.tension)}</td>
                  </tr>
                )}
                {requiredInstallTorque(project) > 0 && (
                  <tr>
                    <th>Required Torque</th>
                    <td>
                      {singleLoad.groutedShaft ? 'n/a' : (
                        toSignificant(torqueUnit?.toDisplay(requiredInstallTorque(project)), 3).toLocaleString()
                      )} {!singleLoad?.groutedShaft && torqueUnit?.abbreviation}
                    </td>
                  </tr>
                )}
              </tbody></table>
            </div>
          )}
        </div>
      </Page>
      {!singleLoad && _.chunk(data?.loads, LOADS_PER_PAGE).map((loadsChunk, p) => (
        <Page>
          <h2>Design Loads {p > 0 && "(cont.)"}</h2>
          {loadsChunk.map((load, i) => {
            const loadIndex = p * LOADS_PER_PAGE + i;
            return <>
              <h3>{loadName(load, loadIndex)}</h3>
              <div className="uk-grid">
                <div className="uk-width-1-2">
                  <table className="uk-table uk-table-condensed">
                    <tbody>
                      {load.compression > 0 && (
                        <tr>
                          <th className="uk-width-1-2">Compression</th>
                          <td>{forceUnitDisplay(load.compression)}</td>
                        </tr>
                      )}
                      {load.tension > 0 && (
                        <tr>
                          <th className="uk-width-1-2">Tension</th>
                          <td>{forceUnitDisplay(load.tension)}</td>
                        </tr>
                      )}
                      {requiredInstallTorque(project, loadIndex) > 0 && (
                        <tr>
                          <th className="uk-width-1-2">Required Torque</th>
                          <td>
                            {load.groutedShaft ? 'n/a' : (
                              toSignificant(torqueUnit?.toDisplay(requiredInstallTorque(project, loadIndex)), 3).toLocaleString()
                            )} {!load.groutedShaft && torqueUnit?.abbreviation}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="uk-width-1-2">
                  <table className="uk-table uk-table-condensed">
                    <tbody>
                      <tr>
                        <th className="uk-width-1-2">Include Shaft Friction:</th>
                        <td>{(load.includeShaftFriction || load.groutedShaft) ? 'YES' : 'NO'}</td>
                      </tr>
                      {load.includeShaftFriction && (
                        <tr>
                          <th>Reduce Shaft Friction</th>
                          <td>{load.reduceShaftFriction ? 'YES' : 'NO'}</td>
                        </tr>
                      )}
                      <tr>
                        <th>Grouted Shaft</th>
                        <td>{load.groutedShaft ? 'YES' : 'NO'}</td>
                      </tr>
                      {load.groutedShaft && (
                        <tr>
                          <th>Grout Diameter</th>
                          <td>
                            {load.groutDiameter ? smallLengthUnitDisplay(load.groutDiameter) : 'not specified'}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          })}
        </Page>
      ))}
      <Page>
        <h2>Engineering Logic</h2>
        <img src={process.env.CALCULATIONS_IMAGE || (window.location.origin + CALCULATIONS_PAGE)} />
      </Page>
      <Page>
        <h2>Baseline Soil Profile</h2>
        <div className="uk-grid">
          <div className="uk-width-1-3">
            <div style={{maxHeight: '8in', overflow: 'hidden'}}>
              <SoilLog project={project} maxDim={{width: 300, height: 800}} readonly/>
            </div>
          </div>
          <div className="uk-width-2-3">
            <div className="uk-panel uk-panel-box">
              <h3>Legend</h3>
              <table className="uk-table">
                <thead>
                  <tr>
                    <th>Pattern</th>
                    <th>Description</th>
                    <th>Soil Coef</th>
                    <th>Cone Coef</th>
                  </tr>
                </thead>
                <tbody>
                  {SoilList.map((soil) => (
                    <tr key={soil.value}>
                      <td><div className={"swatch " + soil.value}/></td>
                      <td>{soil.label}</td>
                      <td>{soil.soilCoef}</td>
                      <td>{soil.coneCoef}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Page>
      {_.map(data?.loads, (load, index) => (
        <LoadPages key={load.id} project={project} load={load} index={index} singleLoad={singleLoad}/>
      ))}
      <Page>
        <h2>Limitations and Permissions for Use</h2>
        <p>Helical piles shall be installed to appropriate depth into suitable bearing stratum as determined by
          geotechnical engineer or local practice.  A minimum factor of safety of 2.0 is recommended for determining
          allowable capacity from correlations with final installation torque when there exists good geotechnical
          information for proper pile sizing.  A factor of safety of 2.5 or higher is recommended where geotechnical
          data is limited.  Net deflections of 0.5&quot; are typical at pile allowable capacity.  Higher factors of safety
          may be required for smaller deflections and for movement sensitive structures.  </p>
        <p>Results shown depend on proper helix geometry, helix spacing, and installation methods.  Therefore, the
          results apply only to helical piles manufactured by Magnum Piering, Inc. and installed by Magnum authorized
          installers.  Use of these results for other helical pile systems by other installers is hereby prohibited.  </p>
        <p>These calculations may be used for preliminary pile sizing and estimation purposes.  Final pile design should
          be by a registered design professional.  Helical pile performance is a function of installation, which Magnum
          cannot control, and ground conditions, which can vary with location and depth; as such, no warrantee is made,
          express or implied.  This software checks only individual bearing methods of capacity estimation.  The results
          depend on proper helix spacing to avoid reductions based on cylindrical shear modes.  Magnum® helix bearing
          elements are spaced to generally provide similar capacity for individual bearing and cylindrical shear modes.
          Nonetheless, it is recommended design professionals check cylindrical shear modes of bearing/pullout capacity
          development where they may represent a limiting scenario.  </p>
        <p>Magnum recommends bidders include provisions for additional pile length and/or obstructions in their bid
          documents.  Magnum recommends installers keep helical pile extensions inventoried on site to avoid delays
          should subsurface conditions vary and additional length be required.  Magnum recommends installation and pile
          load tests be performed where practicable to verify helical pile size, depth, and capacity.</p>
      </Page>
    </div>
  );
}
