import { useHistory } from 'react-router-dom';
import { withOnline } from 'components/helpers/online-helper';
import UnauthenticatedLayout from 'components/layouts/Unauthenticated';
import UserForm from 'components/user/UserForm';

export default withOnline(function Registration({online}) {
  const history = useHistory();
  const goToTermsPage = () => history.push({pathname: '/terms'});

  if (!online) {
    return (
      <div className="ion-padding">
        <h2>Sign up</h2>
        <p className="error">You must be online to sign up.</p>
      </div>
    );
  }

  return (
    <UnauthenticatedLayout>
      <UserForm actionLabel="Register" onSuccess={goToTermsPage}/>
    </UnauthenticatedLayout>
  );
});
