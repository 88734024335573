import Loads from 'components/project/Loads';
import SoilLog from 'components/project/soil-log/SoilLog';
import Pile from 'components/project/Pile';
import Results from 'components/project/Results';
import { useContext } from 'react';
import { ProjectContext } from 'components/layouts/Project';

const STYLES = '#app{max-width: 100%}.all-tabs{display: flex}.all-tabs>div{width: 25%}';

export default function({user}) {
  const project = useContext(ProjectContext);
  return (
    <div className="all-tabs">
      <Loads project={project} user={user}/>
      <SoilLog project={project} user={user}/>
      <Pile project={project} user={user}/>
      <Results project={project} user={user}/>
      <style type="text/css" dangerouslySetInnerHTML={{__html: STYLES}}/>
    </div>
  )
}
