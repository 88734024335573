const SI_SUFFIXES = ['','k','M','B','T'];

export function mod(x, y) {
  return x - y * Math.floor(x / y);
}

export function toSignificant(n, digits=1) {
  var multiple = Math.pow(10, digits - Math.floor(Math.log(n) / Math.LN10) - 1);
  return Math.round(n * multiple) / multiple;
}

export function abbreviateNumber(value) {
  if (value < 100) return _.round(value, 1);
  const numDigits = value.toFixed().length;
  const suffixIndex = Math.floor((numDigits-1) / 3);
  const normalizedValue = value / Math.pow(1000, suffixIndex);

  return toSignificant(normalizedValue, 3) + SI_SUFFIXES[suffixIndex];
}

export function nextPowerN(n) {
  return (x) => Math.pow(n, Math.ceil(Math.log(x) / Math.log(n)));
}
export const nextPower2 = nextPowerN(2);
export const nextPower10 = nextPowerN(10);

export function autoScaleMax(value, baseScale=25, unit={toBase: n => n, toDisplay: n => n}) {
  const n = unit?.toDisplay(value);
  const tens = nextPower10(n) / nextPower10(baseScale);
  const fives = Math.max(nextPower2(n / tens / baseScale), 1) * baseScale;
  return unit.toBase(tens * fives);
}
