import { groupTranslate } from 'lib/svg-helpers';
import HelixGraphic from './HelixGraphic';
import ShaftGraphic from './ShaftGraphic';
import PostGrout from './PostGrout';

const helixHeight = (shafts) => {
  const lead = _.last(shafts);
  if (!lead) return 0;
  if (!lead.helices.length) return 1 / 12;

  const sumOfHelixSpacings = _.sum(_.map(lead.helices, 'spacing'));
  const topHelixHeight = _.last(lead.helices).diameter * (lead.pitch == 6 ? 0.95 : 0.7);
  return (sumOfHelixSpacings + topHelixHeight) / 12;
}

const groutDepth = (shafts) => (
  _.sumBy(shafts, 'length') / 12 - helixHeight(shafts)
)

const POST_GROUT_WIDTH_MARGIN = 2;
const postGroutWidth = (helices) => (
  _.maxOf(helices, 'diameter') + POST_GROUT_WIDTH_MARGIN
);

export default function PileGraphic({
  offsetX, offsetY, factorY, shafts=[], helices=[], coating,
  load: {aboveGrade=0, groutedShaft, groutDiameter, postGroutLead}
}) {
  const shaftWidth = shafts.length ? shafts[0].diameter : 0;
  const pitch = shafts.length ? (shafts[0].pitch || 3) : 3;
  const groutTop = Math.max(-aboveGrade, 0) || 0;
  const groutHeight = Math.max(groutDepth(shafts) - groutTop, 0) || 0;

  return (
    <g transform={groupTranslate()(offsetX, offsetY)} className={coating}>
      {groutedShaft && _.isFinite(groutDiameter) && (
        <rect className="grout"
          x={-groutDiameter/2}
          y={factorY*groutTop}
          height={factorY*groutHeight}
          width={groutDiameter}/>
      )}
      {groutedShaft && postGroutLead && (
        <PostGrout
          top={factorY*(groutTop + groutHeight)}
          height={factorY*helixHeight(shafts)}
          width={postGroutWidth(helices)}/>
      )}
      {shafts.map((shaft, i) => (
        <ShaftGraphic key={i} {...shaft} aboveGrade={aboveGrade} factorY={factorY}/>
      ))}
      {helices.map((helix, i) => (
        <HelixGraphic key={i} {...helix} i={i} pitch={pitch} shaftWidth={shaftWidth} factorY={factorY}/>
      ))}
    </g>
  );
};
