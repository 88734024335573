import { pathify } from 'lib/svg-helpers';

export default ({value, label, factorX, maxData, GRAPH_DIMENTIONS}) => {
  if (!value) return null;

  const {OFFSET_X, OFFSET_Y, GRAPH_HEIGHT} = GRAPH_DIMENTIONS;
  const scaledValue = OFFSET_X + value * factorX;
  const path = [{x: scaledValue, y: OFFSET_Y}, {x: scaledValue, y: GRAPH_HEIGHT}];
  const showWarn = _.startsWith(label, 'Manufacturer Torque Rating')
  return (
    <g className={(maxData > value && showWarn) ? 'warn' : null}>
      <rect className="guide-zone" x={scaledValue} y={OFFSET_Y} width="12" height={GRAPH_HEIGHT}/>
      <text id={`guidelabel-${label}`} className="guide-label" x={scaledValue + 6} y={OFFSET_Y + 2}>{label}</text>
      <path id={`guide-${label}`} className="guide" d={pathify(path)} />
    </g>
  );
}
