import { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import torqueUnitFor from 'config/torque-units';
import { ProjectContext } from 'components/layouts/Project';
import SlideHeader from 'components/helpers/SlideHeader';
import TorqueGraph from './report/TorqueGraph';
import CapacityGraph from './report/CapacityGraph';

export default () => {
  const project = useContext(ProjectContext);
  const {settings: {lengthUnit, forceUnit}={}} = project ?? {};

  return (
    <Swiper breakpoints={{800: {slidesPerView: 2}}} loop>
      <SwiperSlide>
        <SlideHeader color="primary">Torque ({torqueUnitFor(lengthUnit).abbreviation})</SlideHeader>
        <TorqueGraph project={project}/>
      </SwiperSlide>
      <SwiperSlide>
        <SlideHeader color="primary">Allowable Capacity ({forceUnit.abbreviation})</SlideHeader>
        <CapacityGraph project={project}/>
      </SwiperSlide>
    </Swiper>
  );
}
