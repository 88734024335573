import { get, reject } from 'lodash/fp';
import { createSelector } from 'reselect';
import { userInfoSelector } from './userSelector';

export const SORT_OPTIONS = ["Most recent first", "Alphabetical"];

export const currentProjectIdSelector = _.property('projectStatus.projectID');
export const publicProjectsSelector = _.property('publicProjects');
export const projectStatusSelector = _.property('projectStatus');
export const projectsSelector = createSelector(_.property('projects'), reject({owned: false}));

const _id = _.nthArg(1);
export const projectByIdSelectorBase = createSelector(
  _id,
  _.property('projects'),
  (id, projects) => {
    return _.find(projects, {id});
  }
);
export const projectByIdSelector = (id) => (state) => projectByIdSelectorBase(state, id);

const _state = _.identity;
export const currentProjectSelector = createSelector(
  _state,
  currentProjectIdSelector,
  projectByIdSelectorBase
);
export const sortedProjects = createSelector(_.nthArg(0), _.nthArg(1), (projectList, sortOrder) => {
  if (sortOrder == "Most recent first") {
    return projectList;
  } else if (sortOrder == "Alphabetical") {
    return _.sortBy(projectList, ['name']);
  }
});

export const readOnlySelector = createSelector(
  currentProjectSelector,
  userInfoSelector,
  (project, user) => !project?.owned && !user.global_editor
);

export const settingsSelector = _.flow(currentProjectSelector, _.property('settings'));
settingsSelector.at = function settingsAtSelector(path) {
  return _.flow(settingsSelector, get(path));
}
export const dataSelector = _.flow(currentProjectSelector, _.property('data'));
dataSelector.at = function dataAtSelector(path) {
  return _.flow(dataSelector, get(path));
}

