import { projectInfo } from 'config/terms-conditions';
import MainLayout from 'components/layouts/MainLayout';

export default function ProjectInfo() {
  return (
    <MainLayout title="Project Information" narrow>
      <p className="ion-padding">{projectInfo}</p>
    </MainLayout>
  );
}
