export default function upsertBy(coll, item, pred) {
  coll = _.isArrayLike(coll) ? coll : [];

  let result = [];
  const val = _.isFunction(pred) ? pred : _.property(pred);
  const isMatch = (a, b) => val(a) === val(b);
  let [head, ...copy] = coll;

  while (head && result.length < coll.length) {
    if (isMatch(head, item)) {
      head = item;
      break;
    }
    result = [...result, head];
    [head, ...copy] = copy;
  }

  return [...result, head ?? item, ...copy];
}
