import { useSelector, useDispatch } from 'react-redux';
import { IonToggle } from '@ionic/react';
import { getValue } from 'helpers/events';

import { userSettingsSelector } from 'selectors/userSelector';
import { setUserSettings } from 'actions/UserActions';

export default function DualCutterFilter() {
  const dispatch = useDispatch();
  const includeDualCutter = useSelector(userSettingsSelector.at('includeDualCutter')) ?? true;
  const setDualCutter = (includeDualCutter) => dispatch(setUserSettings({includeDualCutter}));

  return (
    <IonToggle label="Include Dual Cutter" checked={includeDualCutter} onClick={getValue(setDualCutter)}>
      Include Dual Cutter
    </IonToggle>
  );
}
