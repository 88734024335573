import Repository from 'repository';
import store from 'store';
import API from 'API';

const ServerActions = {
  receivedPublicProjectList(projectList) {
    store.dispatch({type: 'RECEIVED_PUBLIC_PROJECT_LIST', projectList});
  },

  receivedProjectList: ({deleted}={}) => (projectList) => {
    if (deleted) store.dispatch({type: 'DELETED_PROJECT', project: {id: deleted}});
    store.dispatch({type: 'RECEIVED_PROJECT_LIST', projectList});
    Repository.persistProject();
  },

  receivedProject: (tempId) => (project) => {
    store.dispatch({type: 'RECEIVED_PROJECT', project, tempId});
    Repository.persistProject();
  },

  projectError(body) {
    const errors = _.get(body, 'errors');
    store.dispatch({type: 'PROJECT_ERROR', errors});
  },

  projectCreateError: (project, createdOfflineAt) => (body, statusCode) => {
    if (statusCode > 0) return ServerActions.projectError(body);
    if (!createdOfflineAt) {
      store.dispatch({
        type: 'RECEIVED_PROJECT',
        project: {
          ...project,
          id: _.uuid(),
          createdOfflineAt: new Date().toISOString()
        }
      });
    }
    store.dispatch({type: 'SET_NETWORK_STATUS', network_status: false});
    Repository.persistProject();
  },

  projectUpdateError: (project) => (body, statusCode) => {
    if (statusCode > 0) return ServerActions.projectError(body);
    store.dispatch({
      type: 'RECEIVED_PROJECT',
      project: {
        ...project,
        updatedOfflineAt: new Date().toISOString()
      }
    });
    store.dispatch({type: 'SET_NETWORK_STATUS', network_status: false});
  },

  projectDeleteError: (projectId) => (body, statusCode) => {
    if (statusCode > 0) return ServerActions.projectError(body);
    store.dispatch({type: 'MARK_PROJECT_DELETED', projectId});
    store.dispatch({type: 'SET_NETWORK_STATUS', network_status: false});
    Repository.persistProject();
  },

  newRegistration(registration) {
    store.dispatch({type: 'NEW_REGISTRATION', registration});
    API.initializeAppData();
  },

  registrationError(body) {
    const errors = _.get(body, 'errors');
    store.dispatch({type: 'REGISTRATION_ERROR', errors});
  },

  newAcceptance(acceptance) {
    store.dispatch({type: 'ACCEPTANCE', acceptance});
  },

  newSoilHelpSetting(setting) {
    store.dispatch({type: 'SOIL_HELP_SETTING', setting});
  },

  receivedProductTypes(productTypeList) {
    store.dispatch({type: 'RECEIVED_PRODUCT_TYPE_LIST', productTypeList});
  },

  receivedCurrentUser(userInfo) {
    store.dispatch({type: 'RECEIVED_USER_INFO', userInfo});
  },

  receivedPasswordReset({code, token}) {
    store.dispatch({type: 'RECEIVED_PASSWORD_RESET', code, token})
  },

  receivedAccessToken(token, email) {
    store.dispatch({type: 'LOGIN', token})
  },

  receivedLoginError(body) {
    const errors = _.get(body, 'errors', body);
    store.dispatch({type: 'AUTHENTICATION_ERROR', errors})
  },

  receivedDestroyError(body) {
    const errors = _.get(body, 'errors');
    store.dispatch({type: 'DESTRUCTION_ERROR', errors});
  },

  newDestroyRequest(body) {
    store.dispatch({type: 'DESTRUCTION_REQUEST'});
  },

  destroyedUser(body) {
    store.dispatch({type: 'DESTROYED_USER'});
  }
};

export default ServerActions;
