import { IonIcon, IonList, IonItem, IonLabel } from '@ionic/react';
import { addCircle, checkmarkCircle } from 'ionicons/icons';
import SoilLabels from 'config/soil-labels';
import SoilLogCallout from './SoilLogCallout';

export default function SoilLogEditCallout({y, level, splitSoilLayer, selectedSoil, setSoil}) {
  return (
    <SoilLogCallout y={y}>
      <IonList lines="none" color="dark">
        <IonItem onClick={() => splitSoilLayer(level)} lines="full" button detail="false">
          <IonIcon icon={addCircle} size="large" color="dark" slot="start"/>
          <IonLabel>Add Boundary</IonLabel>
        </IonItem>
        {SoilLabels.map(({label, value}) => {
          const isSelected = value === selectedSoil;
          return (
            <IonItem key={value} onClick={() => setSoil(value)} button detail="false">
              {isSelected ? (
                <IonIcon icon={checkmarkCircle} size="large" slot="start" color="dark"/>
              ) : (
                <div className={`swatch ${value}`} slot="start"/>
              )}
              <IonLabel>{label}</IonLabel>
            </IonItem>
          );
        })}
      </IonList>
    </SoilLogCallout>
  );
}
