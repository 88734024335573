import { IonIcon, IonItem, IonText, IonSelect, IonSelectOption, IonLabel } from '@ionic/react';
import { helpCircle } from 'ionicons/icons';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';
import SoilTestUnits from 'config/soil-test-units';
import useHelpAlert from 'hooks/useHelpAlert';

export default function SoilTestUnitSelector({selected, system, help}) {
  const showHelp = useHelpAlert({title: "Soil Test Units", ...(_.isPlainObject(help) ? help : {content: help})});
  const changeTestUnits = (event) => ProjectSettingsActions.setTestUnit(event.detail.value);

  const selectedValue = selected[system].copyOf || selected.value;

  return (
    <IonItem aria-label="Soil Test Units">
      <IonText aria-label="Help" id="soil-test-units" slot="start" onClick={showHelp}>
        Soil Test Units <IonIcon icon={helpCircle}/>
      </IonText>
      <IonSelect onIonChange={changeTestUnits} value={selectedValue} slot="end">
        {_.map(SoilTestUnits, (unit) => {
          if (unit[system].copyOf) return null;
          const label = `${unit.label} (${unit[system].unit})`;
          return (
            <IonSelectOption key={unit.value} value={unit.value}>
              {label}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </IonItem>
  );
};
