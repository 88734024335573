import { IonButton, IonIcon, IonItem } from "@ionic/react";
import { add } from 'ionicons/icons';

export default function Lead({lead, setLead}) {
  return (
    <IonItem>
      {lead.helixConfig}
      <IonButton onClick={() => setLead(lead)} slot="end">
        <IonIcon icon={add} slot="icon-only" size="large" />
      </IonButton>
    </IonItem>
  );
}
