import { MAX_LEVEL } from 'lib/constants';
import { pathify } from 'lib/svg-helpers';
import Svg from 'components/helpers/svg';
import SoilList from './SoilList';
import BlowCountInput from './BlowCountInput';
import SoilTexturePatterns from './SoilTexturePatterns';

const AXIS_X = 18;
const OFFSET_Y = 10;
const GRAPH_WIDTH = 100;
const GRAPH_HEIGHT = 814;

export default class SoilLogGraph extends React.Component {
  state = {
    idNonce: _.uuid()
  }

  factorY = () => 4

  labelsY = () => {
    const coordX = AXIS_X - 3;

    return (
      <g className="labels labels-y">
        {_.times(this.props.blowCounts.length, (n) => {
          let depth = n * this.props.interval;
          let coordY = OFFSET_Y + this.props.lengthUnit.toBase(depth*this.factorY());
          return (<g key={'label-y-'+depth}>
            <text x={coordX} y={coordY} textAnchor="end">{this.depthLabel(n)}</text>
            <path className="axis" d={pathify([{x: coordX+1, y: coordY}, {x: AXIS_X, y: coordY}])} />
          </g>);
        })}
      </g>
    );
  }

  depthLabel = (i) => {
    return _.round((i * this.props.interval), 1) + this.props.lengthUnit.abbreviation;
  }

  render() {
    const {
      maxDim, readonly, boundaries, soilTypes, blowCounts, blowCountsSD,
      lengthUnit, testUnit, interval, setCallout, maxDepth, bulkSoil
    } = this.props;

    const {idNonce} = this.state;
    const graphHeight = readonly ? GRAPH_HEIGHT * (maxDepth/MAX_LEVEL) + OFFSET_Y : GRAPH_HEIGHT;

    return (
      <Svg width={GRAPH_WIDTH} height={graphHeight} maxDim={maxDim}>
        <SoilTexturePatterns idNonce={idNonce}/>
        <path id="y-axis" className="axis" d={pathify([{x: AXIS_X, y: OFFSET_Y}, {x: AXIS_X, y: GRAPH_HEIGHT}])} />
        {this.labelsY()}
        <SoilList
          idNonce={idNonce}
          offsetX={AXIS_X + 5}
          offsetY={OFFSET_Y}
          factorY={this.factorY()}
          boundaries={boundaries}
          soilTypes={soilTypes}
          readonly={readonly}
          setCallout={setCallout} />
        <BlowCountInput
          offsetX={AXIS_X + 21}
          offsetY={OFFSET_Y}
          factorY={this.factorY()}
          unitX={testUnit}
          baseScale="100"
          blowCounts={blowCounts}
          blowCountsSD={blowCountsSD}
          interval={lengthUnit.toBase(interval)}
          readonly={readonly}
          setCallout={setCallout}
          bulkSoil={bulkSoil}
          />
      </Svg>
    );
  }
}
