import { BLOW_COUNT_INTERVAL_E } from 'lib/constants';

const loadPileLength = ({pileSegments}) => _.sumBy(pileSegments, 'length') / 12;

export default function maxGraphDepth(project, loadIndex) {
  const {data: {loads=[], blowCounts}} = project;
  const maxPileLength = _.isFinite(loadIndex) ? loadPileLength(loads[loadIndex]) : _.max(_.map(loads, loadPileLength));

  const lastBlowCountIndex = _.findLastIndex(blowCounts, (b) => b > 0);
  const blowCountDepth = lastBlowCountIndex * BLOW_COUNT_INTERVAL_E;

  return Math.max(25, maxPileLength, blowCountDepth) + 6;
}
