import { IonList, IonItem, IonIcon, IonText } from '@ionic/react';
import { cog, copy, trash } from 'ionicons/icons';
import { useSelector } from 'react-redux';

import { userInfoSelector } from 'selectors/userSelector';

import ProjectActions from 'actions/ProjectActions';

import ReportButton from './ReportButton';

const deleteProject = (project) => {
  if (window.confirm(`Are you sure you want to delete '${project.name}'`)) {
    ProjectActions.deleteProject(project);
  }
};

export default function ProjectMenu({project}) {
  const {global_editor=false} = useSelector(userInfoSelector);
  return <>
    <IonList lines="full">
      <ReportButton project={project}/>
      {(global_editor || project.owned) && (
        <IonItem routerLink={`/projects/${project.id}/settings`} detail="false">
          <IonIcon icon={cog} slot="start"/>
          Edit Project
        </IonItem>
      )}
      <IonItem routerLink={`/projects/${project.id}/copy`} detail="false">
        <IonIcon icon={copy} slot="start"/>
        Copy Project
      </IonItem>
      {(project.id && project.owned) && (
        <IonItem lines="none" onClick={() => deleteProject(project)}>
          <IonIcon icon={trash} slot="start" color="danger"/>
          <IonText color="danger">Delete Project</IonText>
        </IonItem>
      )}
    </IonList>
  </> ;
}
