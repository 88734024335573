import {abbreviateNumber} from 'lib/number';

export default ({x, y, factorX, factorY, unitY, unitX, GRAPH_DIMENTIONS}) => {
  const {OFFSET_X, OFFSET_Y} = GRAPH_DIMENTIONS;
  const coordY = OFFSET_Y + unitY.toBase((y || 1) * factorY);
  const coordX = OFFSET_X + x * factorX + 2;

  return (
    <text x={coordX} y={coordY} textAnchor="start">
      {abbreviateNumber(unitX?.toDisplay(x))}
    </text>
  );
}
