import MainLayout from 'components/layouts/MainLayout';
import { useSelector } from 'react-redux';

import { loggedInSelector } from '../../selectors/userSelector';

export default function PrivacyPolicy() {
  const loggedIn = useSelector(loggedInSelector);

  return (
    <MainLayout title="Privacy Policy"
      parentPath={loggedIn && '/account'}
      parentName={loggedIn && 'Account'}
      narrow
    >
      <div className="ion-padding privacy-policy">
        <h2>MOBILE APPLICATION PRIVACY POLICY</h2>
        <p>Updated: August 14th, 2024</p>
        <p>
          This Privacy Policy (the “Privacy Policy”) applies to the MAGNUM Geo App mobile application
          (the “App”), owned and operated by MAGNUM PIERING, INC, an Ohio corporation (“Magnum” “we,”
          “us,” or “our”). We have created this Privacy Policy to tell you what information the
          App collects, how we use that information, and who we will share that information with,
          if at all. This Privacy Policy does not address the privacy practices of any third parties
          that we do not own, control, or are affiliated with. Capitalized terms not defined in this
          Privacy Policy will have the meaning stated in our Terms of Use. By visiting and/or using our
          App, you are agreeing to the terms of this Privacy Policy and the accompanying Terms of Use.
          We encourage you to read the Privacy Policy, and to use the information it contains to help
          you make informed decisions.
        </p>
        <ol>
          <li>
            <strong>Information We Collect or Receive.</strong>  In the course of operating the App,
            we will collect and/or receive the following types of information.  You authorize us to
            collect and/or receive such information.
            <ol>
              <li>
                <em>Personal Information.</em> We only receive or collect information that identifies
                you personally if you choose to provide such personally identifiable information to us
                via email or other means. When you sign up to become a user or contact us, you will be
                required to provide us with Personal Information about yourself (collectively, the
                “Personal Information”). Such Personal Information may include your name, email address,
                physical address, and phone number. We do not collect any Personal Information from you
                when you use the App unless you provide us with the Personal Information voluntarily.
              </li>
              <li>
                <em>Identity Provider Log In.</em> If you sign in through a third-party service that
                stores and verifies user identity (an “Identity Provider”), you are authorizing us to
                collect, store, and use, in accordance with this Privacy Policy, any and all
                information that you agreed that the Identity Provider would provide to us through
                the Identity Provider’s Application Programming Interface (“API”). Such information
                may include, without limitation, your first and last name, Identity Provider username,
                Identity Provider profile picture, headline, unique identifier and access token, and
                email address.
              </li>
              <li>
                <em>Payment Information.</em> If you choose to make a purchase or subscribe to a
                feature or service ours that requires a fee, you will be required to provide us with
                your Payment Information, including, without limitation, bank account numbers,
                credit card or debit card numbers, account details, ACH information, and similar data
                (collectively, “Payment Information”). Such Payment Information will be collected and
                processed by our third-party payment vendors under the terms and conditions of their
                privacy policies and terms of use, and we do not obtain access to any Payment Information
                in connection with such purchases or subscriptions.
              </li>
              <li>
                <em>Geolocational Information.</em> Certain features and functionalities of the App
                may be based on your location. In order to provide such features and functionalities
                while you are using your mobile device, we may, with your consent, automatically
                collect network, GPS, and other location information from your mobile device or
                wireless carrier and/or certain third-party service providers
                (collectively, <em>“Geolocational Information”</em>). Collection of such
                Geolocational Information occurs only when the App is running on your device.
                You may decline to allow us to collect such Geolocational Information, in which
                case we will not be able to provide certain features or functionalities to you.
              </li>
              <li>
                <em>Third-Party Analytics.</em> We and the third-party technology providers, ad
                exchanges, ad networks, advertisers, agencies, ad exchanges, and ad servers with
                which we work use third-party analytics services (e.g., Google Analytics) to evaluate
                your use of the App, compile reports on activity, collect demographic data, analyze
                performance metrics, and collect and evaluate other information relating to the App
                and mobile and Internet usage. These third parties use cookies and other technologies
                to help analyze and provide us the data. You consent to the processing of data about
                you by these analytics providers in the manner and for the purposes set out in this
                Privacy Policy. For more information on these third parties, including how to opt out
                from certain data collection, please visit
                New Relic at <a href="https://newrelic.com/termsandconditions/privacy" target="_blank">
                  https://newrelic.com/termsandconditions/privacy
                </a>;
                Google Analytics at <a href="https://policies.google.com/technologies/partner-sites" target="_blank">
                  https://policies.google.com/technologies/partner-sites
                </a>;
                and Rollbar at <a href="https://docs.rollbar.com/docs/privacy-policy" target="_blank">
                  https://docs.rollbar.com/docs/privacy-policy
                </a>. Please be advised that if you opt out of
                any such service, you may not be able to use the full functionality of the App.
              </li>
              <li>
                <em>Other Information.</em> In addition to the Personal Information, Payment
                Information, and Geolocational Information, we may automatically collect or
                receive additional information regarding you and your use of the App; your
                interactions with us and our advertising; and information regarding your computer
                and mobile devices used to access the App (collectively, the “Other Information”).
                Such <em>Other Information</em> may include:
                <ol>
                  <li>
                    <em>From You.</em> Additional information about yourself that you voluntarily
                    provide to us, such as your gender and your product and service preferences.
                  </li>
                  <li>
                    <em>From Your Activity.</em> We may collect or receive information regarding:
                    <ol>
                      <li>
                        IP address, which may consist of a static or dynamic IP address and will
                        sometimes point to a specific identifiable computer or mobile device;
                      </li>
                      <li>
                        Browser type and language;
                      </li>
                      <li>
                        Referring and exit pages and URLs;
                      </li>
                      <li>
                        Date and time; and
                      </li>
                      <li>
                        Details regarding your activity on the App, such as search queries
                        and other performance and usage data.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <em>About Your Mobile Device.</em> We may collect or receive information regarding:
                    <ol>
                      <li>
                        Type of mobile device;
                      </li>
                      <li>
                        Advertising Identifier (“IDFA” or “AdID”);
                      </li>
                      <li>
                        Operating system and version (e.g., iOS, Android, or Windows);
                      </li>
                      <li>
                        Carrier; and
                      </li>
                      <li>
                        Network type (Wi-Fi, 3G, 4G, LTE).
                      </li>
                    </ol>
                  </li>
                  <li>
                    <em>From Cookies.</em> We may use both session cookies, which expire once you
                    close the App, and persistent cookies, which stay on your mobile device until
                    you delete them and other technologies to help us collect data and to enhance
                    your experience with the App. Cookies are small text files an app can use to
                    recognize a repeat visitor to the app. If you do not want us to deploy cookies
                    in the App, you can opt out by setting your mobile device to reject cookies.
                    You can still use the App if you choose to disable cookies, although your
                    ability to use some of the features may be affected.  We may use cookies for
                    various purposes, including to:
                    <ol>
                      <li>
                        Personalize your experience;
                      </li>
                      <li>
                        Analyze which portions of the App are visited and used most frequently; and
                      </li>
                      <li>
                        Measure and optimize advertising and promotional effectiveness.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>Information Collected by or Through Third-Party Advertising Companies.</strong>
            We may share Other Information about your activity on the App with third parties for
            ad distribution and ad optimization (defined as the tailoring, targeting (i.e.,
            behavioral, contextual, retargeting, analyzing, managing, reporting, and optimizing
            of ads)). These third parties may use cookies, pixel tags (also called web beacons
            or clear gifs), and/or other technologies to collect Other Information for such
            purposes. Pixel tags enable us and these third-party advertising companies to
            recognize a browser’s cookie when a browser visits the site on which the pixel tag
            is located in order to learn which advertisement brings a user to a given site.
            In addition, we may receive Other Information from advertisers and/or their
            service providers such as advertising identifiers, IP addresses, and
            post-conversion data.
          </li>
          <li>
            <strong>How to Opt-Out of Third-Party Interest-Based Advertising.</strong>
            If you wish to opt out of third-party interest-based advertising,
            please visit <a href="http://www.networkadvertising.org" target="_blank">
            http://www.networkadvertising.org</a> and <a href="http://www.aboutads.info/choices" target="_blank">
            http://www.aboutads.info/choices</a> for details on how to do so.
            Additionally, users of mobile devices can follow the below instructions:
            <table>
              <tbody>
                <tr>
                  <td>
                    <em>Android Users</em>
                  </td>
                  <td>
                    <em>iOS Users</em>
                  </td>
                </tr>
                <tr>
                  <td>
                    Google Settings > Ads > Enable “Opt out of interest-based advertising.”
                  </td>
                  <td>
                    Settings > Select Privacy > Select Advertising > Enable the “Limit Ad Tracking” setting.
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <strong>How Information is Used and Shared.</strong>
            <ol>
              <li>
                You authorize us to use the Personal Information, Payment Information,
                Geolocational Information, and the Other Information (collectively, the
                “Information”) to:
                <ol>
                  <li>Provide and improve our App;</li>
                  <li>Provide our services;</li>
                  <li>Administer our promotional programs;</li>
                  <li>Solicit your feedback; and</li>
                  <li>Inform you about our products and services.</li>
                </ol>
              </li>
              <li>
                In order to provide our services and administer our promotional programs, we may
                share the Information with our third-party promotional and marketing partners,
                including, without limitation, businesses participating in our various programs.
              </li>
              <li>
                We engage third-party companies and individuals to perform functions on our
                behalf. Examples may include providing technical assistance, customer service,
                marketing assistance, and administration of promotional programs. These other
                companies will have access to the Information only as necessary to perform their
                functions and to the extent permitted by law.
              </li>
              <li>
                In an ongoing effort to better understand our users, the App, and our products
                and services, we may analyze certain Information in anonymized and aggregate form
                to operate, maintain, manage, and improve the App and/or such products and services.
                This aggregate information does not identify you personally. We may share and/or
                license this aggregate data to our affiliates, agents, business, and promotional
                partners, and other third parties. We may also disclose aggregated user statistics
                to describe the App and these products and services to current and prospective
                business partners and investors and to other third parties for other lawful purposes.
              </li>
              <li>
                We may share some or all of your Information with any of our parent companies,
                subsidiaries, joint ventures, or other companies under common control with us.
              </li>
              <li>
                As we develop our businesses, we might sell or buy businesses or assets. In the
                event of a corporate sale, merger, reorganization, sale of assets, dissolution,
                or similar event, the Information may be part of the transferred assets.
              </li>
              <li>
                To the extent permitted by law, we may also disclose the Information:
                <ol>
                  <li>
                    When required by law, court order, or other government or law enforcement
                    authority or regulatory agency; or
                  </li>
                  <li>
                    Whenever we believe that disclosing such Information is necessary or advisable,
                    for example, to protect the rights, property, or safety of us or others,
                    including you.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>Accessing and Modifying Information and Communication Preferences.</strong>
            If you have provided us any Personal Information, you may access, remove, review,
            and/or make changes to the same by contacting us as set forth below. In addition,
            you may manage your receipt of marketing and non-transactional communications by
            clicking on the “unsubscribe” link located on the bottom of any of our marketing
            emails. We will use commercially reasonable efforts to process such requests in a
            timely manner. You should be aware, however, that it is not always possible to
            completely remove or modify information in our subscription databases. You cannot opt
            out of receiving transactional emails related to the App (e.g., requests for support).
            <br/>We may also deliver notifications to your mobile device (e.g., push notifications).
            You can disable these notifications by deleting the relevant service or by changing
            the settings on your mobile device.
          </li>
          <li>
            <strong>How We Protect Your Information.</strong> We take commercially reasonable
            steps to protect the Information from loss, misuse, and unauthorized access,
            disclosure, alteration, or destruction. Please understand, however, that no
            security system is impenetrable. We cannot guarantee the security of our databases
            or the databases of the third parties with which we may share such Information,
            nor can we guarantee that the Information you supply will not be intercepted while
            being transmitted over the Internet. In particular, email sent to us may not be
            secure, and you should therefore take special care in deciding what information
            you send to us via email.
          </li>
          <li>
            <strong>Important Notices to Non-U.S. Residents.</strong>
            The App and its servers are operated in the United States. If you are located
            outside of the United States, please be aware that your Information, including
            your Personal Information, may be transferred to, processed, maintained, and used
            on computers, servers, and systems located outside of your state, province,
            country, or other governmental jurisdiction where the privacy laws may not be
            as protective as those in your jurisdiction. If you are located outside the
            United States and choose to use the App, you hereby irrevocably and
            unconditionally consent to such transfer, processing, and use in the United States
            and elsewhere.
          </li>
          <li>
            <strong>App Stores; External Websites.</strong>
            <ol>
              <li>
                Your app store (e.g., iTunes or Google Play) may collect certain information in
                connection with your use of the App, such as Personal Information, Payment
                Information, Geolocational Information, and other usage-based data. We have
                no control over the collection of such information by a third-party app store,
                and any such collection or use will be subject to that third party’s applicable
                privacy policies.
              </li>
              <li>
                The App may contain links to third-party websites. We have no control over the
                privacy practices or the content of these websites. As such, we are not responsible
                for the content or the privacy policies of those third-party websites. You should
                check the applicable third-Party Privacy Policy and Terms of Use when visiting
                any other websites.
              </li>
            </ol>
          </li>
          <li>
            <strong>Children.</strong> The App is not directed to children under the age of 13.
            We adhere to the Children’s Online Privacy Protection Act (COPPA) and will not
            knowingly collect Personal Information from any child under the age of 13. We ask
            that minors (under the age of 13) not use the App. If a child under the age of 13
            has provided us with Personal Information, a parent or guardian of that child may
            contact us and request that such information be deleted from our records.
          </li>
          <li>
            <strong>Changes to This Privacy Policy.</strong>
            This Privacy Policy is effective as of the date stated at the top of this
            Privacy Policy. We may change this Privacy Policy from time to time.
            Any such changes will be posted on the App. By accessing the App after we make
            any such changes to this Privacy Policy, you are deemed to have accepted
            such changes. Please be aware that, to the extent permitted by applicable law,
            our use of the Information is governed by the Privacy Policy in effect at the
            time we collect the Information. Please refer back to this Privacy Policy on a
            regular basis.
          </li>
          <li>
            <strong>How to Contact Us.</strong> If you have questions about this Privacy Policy,
            please email us at info@magnumpiering.com with “Privacy Policy” in the subject line
            or mail us at the following address:
            <br/>Magnum Piering, Inc.
            <br/>156 Circle Freeway
            <br/>Cincinnati, Ohio 45246
            <br/>Attn: Privacy Policy
          </li>
        </ol>
      </div>
    </MainLayout>
  );
}
