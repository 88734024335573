import API from 'API';
import store from 'store';
import { userSettingsSelector } from 'selectors/userSelector';

export function setUserSettings(settings) {
  return (dispatch, getState) => {
    dispatch({type: 'user/settings/update', settings});
    const updatedSettings = userSettingsSelector(getState());
    API.setUserSettings(updatedSettings);
  };
}

export default {
  loadUserInfo: API.getCurrentUser,

  passwordReset(email) {
    API.passwordReset(email);
  },

  changePassword(newPassword, token, email) {
    API.changePassword(newPassword, token, email);
  },

  update: API.updateUser,
  requestDestroy: API.requestDestroyUser,
  delete: API.deleteUser,
  deleteUnauthenticated: API.deleteUnauthenticatedUser,

  clearUserInfo() {
    store.dispatch({type: 'CLEAR_USER_INFO'});
  },

  setSoilHelpSetting(soil_help) {
    store.dispatch(setUserSettings({soil_help}));
  }
};
