function filterLengths({minlength=0, maxlength}, segments) {
  maxlength = maxlength || 1e6;
  return _.filter(segments, (segment) => {
    return segment.length >= minlength && segment.length <= maxlength;
  });
}

function pickClean(obj, ...propNames) {
  return _.pickBy(_.pick(obj, propNames), _.identity);
}

function filterLeadList(segments, options={}) {
  options = {...options, length: options.length ? parseInt(options.length, 10) : null};
  const leads = _.filter(segments, {lead_extension: 'Lead', ...pickClean(options, 'diameter', 'pitch', 'coupling', 'wall')});
  return filterLengths({maxlength: options.pileLength*12}, leads);
}

function compatible(lead={}, pileOptions={}) {
  return (
    lead.diameter == pileOptions.diameter &&
    lead.pitch == pileOptions.pitch &&
    lead.coupling == pileOptions.coupling &&
    lead.wall == pileOptions.wall &&
    lead.length/12 <= pileOptions.pileLength
  );
}
const findByHelix = (leads, config) => (
  _.find(leads, ({helixConfig}) => _.join(helixConfig) == _.join(config))
);

export default function compatableLead(lead, pileOptions, products) {
  if (compatible(lead, pileOptions)) return lead;

  const leadList = _.orderBy(filterLeadList(products, pileOptions), 'length');
  return findByHelix(leadList, pileOptions.helixConfig);
}
