import { createSelector } from "reselect";


const irregularSide = createSelector(_.identity, (height) => {
  const coords = [];
  while (Math.abs(_.sumBy(coords, 'y')) < Math.abs(height) - 4) {
    const odd = coords.length % 2;
    coords.push({
      x: odd ? -2 : 2,
      y: (height < 0 ? -1 : 1) * _.random(3, 8)
    });
  }
  return coords.map(({x, y}) => `${x} ${y}`).join(' l ');
});

function ComplexPostGrout({top, width, height}) {
  const path = [
    'M', 0, top - 5,
    'l', width/2 - 1, 2,
    'l', irregularSide(height),
    'L', -1, top + height + 4,
    'l', -width/2 + 1, -5,
    'l', irregularSide(-height),
    'z'
  ];
  return (
    <path className="grout" d={_.join(path, ' ')}/>
  );
}

export default ComplexPostGrout;
