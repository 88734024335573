import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { IonButton, IonCheckbox, IonRouterLink } from '@ionic/react';

import { terms } from 'config/terms-conditions';
import RegistrationActions from 'actions/RegistrationActions';
import MainLayout from 'components/layouts/MainLayout';
import { loggedInSelector, regStatusSelector, cannonicalIdSelector } from '../../selectors/userSelector';

function Terms(props) {
  const [newsletter, setNewsletter] = useState(true);
  const regStatus = useSelector(regStatusSelector);
  const loggedIn = useSelector(loggedInSelector);
  const userId = useSelector(cannonicalIdSelector);

  useEffect(() => {
    if (!regStatus?.initialAccepted && regStatus?.accepted && loggedIn) {
      props.history.push('/projects');
    }
  }, [regStatus, loggedIn]);

  const toggleNewsletter = () => setNewsletter((v) => !v);

  const submitTerms = (event) => {
    event.preventDefault();
    RegistrationActions.acceptTerms(userId, newsletter);
  }

  const {initialAccepted} = regStatus;
  const inRegistrationFlow = userId && !initialAccepted;

  return (
    <MainLayout title="Terms and Conditions" narrow>
      <form className="ion-padding" onSubmit={submitTerms}>
        <p>{terms}</p>
        <p>See <IonRouterLink routerLink="privacy" target={inRegistrationFlow ? "_blank" : null}>Privacy Policy</IonRouterLink></p>
        {inRegistrationFlow && <>
          <IonCheckbox labelPlacement="end" checked={newsletter} onIonChange={toggleNewsletter}>
            Receive Quarterly Newsletter
          </IonCheckbox>
          <IonButton color="primary" type="submit" expand="block">
            Agree With Terms To Continue
          </IonButton>
        </>}
      </form>
    </MainLayout>
  );
}

export default withRouter(Terms);
