import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

global.setNetworkStatus = (network_status) => store.dispatch({type: "SET_NETWORK_STATUS", network_status})
store.dispatch = ((dispatch, getState) => (action) => {
  let error;
  try {
    return dispatch(action);
  } catch(e) {
    error = e;
    throw e;
  } finally {
    const afterState = getState();
    if (window.LogActions) {
      console.log(action, afterState, error);
    }
  }
})(store.dispatch, store.getState);
export default store;


export function loggedIn(state) {
  state ??= store.getState();
  return !!_.get(state, 'user.authentication.token');
}
