import { useEffect } from 'react';
import { IonButton } from '@ionic/react';
import { useSelector } from 'react-redux';

import { loggedInSelector } from 'selectors/userSelector';

import AuthenticationActions from 'actions/AuthenticationActions';
import UnauthenticatedLayout from 'components/layouts/Unauthenticated';

export default function Logout() {
  const loggedIn = useSelector(loggedInSelector)

  useEffect(() => {
    if (loggedIn) AuthenticationActions.logout();
  }, []);

  return (
    <UnauthenticatedLayout>
      <div className="ion-padding">
        <p>You are now logged out.</p>
        <IonButton routerLink="/login">Sign in</IonButton>
      </div>
    </UnauthenticatedLayout>
  );
};
