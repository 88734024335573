import { formatDistanceToNow } from 'date-fns';
import { connect } from 'react-redux';
import { IonList, IonIcon, IonItem, IonSelect, IonSelectOption, IonLabel, IonNote } from '@ionic/react';
import { add as iconAdd } from 'ionicons/icons';

import { presentAsLine } from 'lib/text';
import ProjectActions from 'actions/ProjectActions';
import { projectsSelector, SORT_OPTIONS, sortedProjects } from 'selectors/projectSelector';
import ProjectsLayout from 'components/layouts/Projects';

function mapStateToProps(state, ownProps) {
  return {
    projectList: projectsSelector(state),
  }
}

function withReduxConnect(Component) {
  return connect(mapStateToProps)(Component);
}

class ProjectList extends React.Component {
  state = {
    sortOrder: _.first(SORT_OPTIONS),
  }

  componentDidMount() {
    ProjectActions.getProjects();
  }

  updateSortOrder = (event) => {
    this.setState({sortOrder: event.detail.value});
  }

  render() {
    const {sortOrder} = this.state;
    const {projectList} = this.props;

    return (
      <ProjectsLayout>
        <IonList lines="full">
          <IonItem>
            <IonLabel>Sort by:</IonLabel>
            <IonSelect onIonChange={this.updateSortOrder} value={sortOrder} interface="popover">
              {_.map(SORT_OPTIONS, (option) => (
                <IonSelectOption key={option} value={option}>{option}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem color="light" routerLink="/projects/new" detail="false">
            <IonIcon icon={iconAdd} size="large" color="primary"/>
            <IonLabel color="primary"><strong>New Project</strong></IonLabel>
          </IonItem>

          {sortedProjects(projectList, sortOrder).map(({id, name, client, city, state, updated_at}) => (
            <IonItem key={id} routerLink={`/projects/${id}`}>
              <IonLabel>
                <p>
                  {name}
                </p>
                <p>
                  {presentAsLine(
                    client,
                    _.join(_.compact([city, state]), ", ")
                  )}
                </p>
              </IonLabel>
              <IonNote>{updated_at && formatDistanceToNow(updated_at) + ' ago'}</IonNote>
            </IonItem>
          ))}
        </IonList>
      </ProjectsLayout>
    );
  }
};

export default withReduxConnect(ProjectList);
