import { MAX_LEVEL } from 'lib/constants';
import { pathify, groupTranslate } from 'lib/svg-helpers';
import { calculateSTDData } from 'lib/capacity-calculations';
import BlowCountActions from 'actions/BlowCountActions';

import { bulkSoilConfirm } from 'components/helpers/confirm';
import Axes from 'components/graph/axes';
import Gridline from 'components/graph/gridline';
import FineAdjustmentCallout from './FineAdjustmentCallout';

const GRAPH_WIDTH = 64;
const X_GRIDS = 5;
const GRAPH_DIMENTIONS = {OFFSET_X: 0, OFFSET_Y: 0, GRAPH_WIDTH, GRAPH_HEIGHT: 0, X_GRIDS};
const LABEL_Y = -1

export default class BlowCountInput extends React.Component {
  state = {
    dragging: null
  }

  graphRef = React.createRef()
  labelsx = React.createRef()

  componentDidMount() {
    setTimeout(() => {this.setState({pixelRatio: this.getPixelRatio()})}, 100);
    this.updateBlowCount = _.throttle(this._updateBlowCount, 10);
  }

  groupTranslate = groupTranslate(this.props)

  getPixelRatio = () => {
    if (!this.labelsx.current) return 0.0;
    return this.labelsx.current.getBoundingClientRect().width * 1.25 / GRAPH_WIDTH;
  }

  _updateBlowCount = (e) => {
    const {startX, pixelRatio, startValue, dragging} = this.state;
    const delta = (e.pageX || e.changedTouches[0].pageX) - startX;
    const newBlowCount = delta/pixelRatio/this.factorX() + startValue;
    this.setBlowCount(dragging, newBlowCount);
  }

  setBlowCount = (i, newBlowCount) => {
    const {unitX: {maxScale, precision=0}} = this.props;
    const blowCounts = _.cloneDeep(this.props.blowCounts);
    blowCounts[i] = _.clamp(_.round(newBlowCount, precision), 0, maxScale);
    BlowCountActions.setBlowCounts(blowCounts);
    this.setCallout(i, blowCounts[i]);
  }

  startDragging = (i) => (e) => {
    if (this.props.readonly) return;

    e.stopPropagation();
    e.preventDefault();
    const {blowCounts, bulkSoil} = this.props;
    if (!bulkSoil) {
      const startX = e.pageX || e.changedTouches[0].pageX;
      this.setState({dragging: i, startValue: blowCounts[i], startX});
      document.addEventListener('touchmove', this.updateBlowCount);
      document.addEventListener('mousemove', this.updateBlowCount);
      document.addEventListener('mouseup', this.stopDragging);
      document.addEventListener('touchend', this.stopDragging);
      this.setCallout(i, blowCounts[i]);
    } else {
      bulkSoilConfirm(bulkSoil);
    }
  }

  stopDragging = (e) => {
    this.setState({dragging: null, startValue: null, startX: null});
    document.removeEventListener('touchmove', this.updateBlowCount);
    document.removeEventListener('mousemove', this.updateBlowCount);
    document.removeEventListener('mouseup', this.stopDragging);
    document.removeEventListener('touchend', this.stopDragging);
  }

  getActualHeight() {
    if (!this.graphRef.current) return 0;
    return this.graphRef.current.getBoundingClientRect().height;
  }

  setCallout = (i, value) => {
    if (this.props.readonly) return;

    const {factorY, offsetY, interval, setCallout, unitX: {precision=0}} = this.props;
    const level = i * interval;
    const actualHeight = this.getActualHeight()
    const pixelRatio = actualHeight / (MAX_LEVEL * factorY);
    setCallout({
      Component: FineAdjustmentCallout,
      y: (level * factorY + offsetY+6) * pixelRatio,
      x: value *this.factorX() * pixelRatio + 140 + 14,
      value,
      precision,
      setBlowCount: _.partial(this.setBlowCount, i)
    });
  }

  factorX = () => (GRAPH_WIDTH - 8)/this.props.unitX.maxScale;

  axisLabels = () => {
    const {maxScale} = this.props.unitX;
    return _.times(X_GRIDS, n => (maxScale / X_GRIDS) * (n + 1));
  }

  getDataCoords = (data) => {
    const {interval, factorY} = this.props;
    return data.map((datum, i) => ({
      x: datum * this.factorX(),
      y: i * interval * factorY
    }));
  }

  render() {
    const {unitX, factorY, blowCounts, blowCountsSD} = this.props;
    const factorX = this.factorX();
    const blowCountCoordinates = this.getDataCoords(blowCounts);
    const blowCountLSDCoordinates = this.getDataCoords(calculateSTDData(blowCounts, blowCountsSD, -1));
    const blowCountUSDCoordinates = this.getDataCoords(calculateSTDData(blowCounts, blowCountsSD, 1))
    const graphDimentions = {...GRAPH_DIMENTIONS, GRAPH_HEIGHT: 202 * factorY};
    return (
      <g className="blow-count-graph" ref={this.graphRef} transform={this.groupTranslate()}>
        <g className="labels labels-x" ref={this.labelsx}>
          <text x={GRAPH_WIDTH / 2} y={LABEL_Y - 4} textAnchor="middle">
            {unitX.label} ({unitX.unit})
          </text>
          {this.axisLabels().map((x) => (
            <text key={x} x={x * factorX} y={LABEL_Y} textAnchor="middle">{x}</text>
          ))}
        </g>
        <g className="gridlines gridlines-x">
          {this.axisLabels().map((x) => (
            <Gridline key={x} value={x} factor={factorX} GRAPH_DIMENTIONS={graphDimentions}/>
          ))}
        </g>
        <Axes {...graphDimentions}/>
        <path className="std-plot" d={pathify([...blowCountLSDCoordinates, ..._.reverse(blowCountUSDCoordinates)])} />
        <path id="data-plot" d={pathify(blowCountCoordinates)} />
        <g className="blow-count-controls">
          {blowCountCoordinates.map(({x, y}, i) => {
            const rightSide = x/GRAPH_WIDTH < 0.65;
            return <g key={i}>
              <circle
                className="blow-count-point"
                cx={x}
                cy={y}
                r="3"
                onMouseDown={this.startDragging(i)}
                onTouchStart={this.startDragging(i)}
                onClick={(e) => e.stopPropagation()}/>
              <text key={i}
                className="blow-count-label"
                x={x + (rightSide ? 4 : -4)}
                y={y + 2}
                textAnchor={ rightSide ? 'start' : 'end' }
              >
                {_.round(blowCounts[i], unitX.precision + 1)}
              </text>
            </g>;
          })}
        </g>
      </g>
    );
  }
}
