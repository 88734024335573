import HELIX_AREAS from 'config/helix-areas';

function addAreas(sum, helix) {
  return sum + (helix.area || HELIX_AREAS[helix.type][`${helix.diameter}`]);
}


function normalizeHelices(helices) {
  return _.map(helices, ({diameter, ...helix}) => ({
    ...helix,
    diameter: _.toNumber(diameter),
  }));
}

export function normalizeSegments(segmentList=[]) {
  return segmentList.map((segment) => ({
    ...segment,
    shaftLength: _.ceil(segment.length / 12),
    helices: normalizeHelices(segment.helices),
    area: _.reduce(segment.helices, addAreas, 0) ?? 0,
    helixConfig: _.map(segment.helices, ({diameter, type}) => (
      diameter + (type === 'single' ? 'S' : 'D')
    ))
  }));
}

function normalizeOptions(productTypeList=[]) {
  return productTypeList.map(option => {
    return {uuid: _.uuid(), ...option, coupling: option.coupling || ''};
  });
}

const DEFAULT_STATE = {productTypeList: [], segmentList: []};

export default function(state=DEFAULT_STATE, action) {
  switch(action.type) {
    case 'RESTORE':
      return action.products || DEFAULT_STATE;
    case 'products/received':
      return {
        ...state,
        segmentList: _.uniqBy([...action.products, ...state.segmentList], 'sku')
      };
    case 'RECEIVED_PRODUCT_TYPE_LIST':
      return {...state, productTypeList: normalizeOptions(action.productTypeList)};
    default:
      return state;
  }
};
