export const preventDefault = (func) => (e) => {
  e.preventDefault();
  if (_.isFunction(func)); return func(e);
}

export const stopPropagation = (func) => (e) => {
  e.stopPropagation();
  if (_.isFunction(func)) return func(e);
};

export const stopAll = (func) => stopPropagation(preventDefault(func));

export const numerifyInputValue = (onChange) => (e) => {
  const value = parseInt(e.target.value, 10);
  if (_.isFinite(value)) onChange(value);
};

export const getValue = (fn, ...args) => (event) => {
  const {detail, target} = event;
  const val = detail?.checked ?? detail?.value ?? target.checked ?? target.value;
  return fn(...args, val, event);
}
