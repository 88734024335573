import { MAX_LEVEL } from 'lib/constants';
import PartList from 'lib/part-list';
import { DATA, LOAD } from 'reducers/projects/defaults';
import compatableLead from './make-pile-consistent';

function safetyFactor(state=DATA.safetyFactor, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_SAFETY_FACTOR':
      return action.factor;
    default:
      return state;
  }
}

const getShaftLength = (lead) => _.ceil(lead?.shaftLength || (lead?.length / 12));

const clampLength = (pileLength, aboveGrade, lead) => {
  const gradeOffset = Math.max(-(aboveGrade ?? 0), 0);
  const minLength = getShaftLength(lead) + gradeOffset;
  return _.clamp(pileLength, minLength, MAX_LEVEL);
}

const pileUpdate = (load, action) => {
  const pileOptions = {...(load.pileOptions || {}), ...(action.filters || {})};
  const lead = action.lead || _.first(load?.pileSegments);
  pileOptions.pileLength = clampLength(pileOptions.pileLength, load.aboveGrade, lead);
  const newLead = compatableLead(lead, pileOptions, action.products);
  const pileSegments = newLead ? PartList.generatePile(newLead, pileOptions.pileLength) : [];
  return {...load, pileOptions, pileSegments};
}

function loads(state=DATA.loads, action) {
  switch(action.type) {
    case 'load/add':
      return [...state, {...LOAD, id: action.id || _.uuid()}];
    case 'load/remove':
      return _.reject(state, {id: action.id});
    case 'load/update':
      return _.map(state, (load) => {
        if (load.id !== action.id) return load;
        const updatedLoad = {...load, ...action.load};
        updatedLoad.aboveGrade = Math.max(updatedLoad.aboveGrade, getShaftLength(_.first(load?.pileSegments)) - load.pileOptions?.pileLength);
        return updatedLoad;
      });
    case 'load/pile/update':
      return _.map(state, (load) => {
        if (load.id !== action.loadId) return load;
        return pileUpdate(load, action);
      });
    case 'products/received':
      return _.map(state, (load) => pileUpdate(load, action));
    default:
      return state;
  }
}

function blowCounts(state=DATA.blowCounts, action) {
  switch(action.type) {
    case 'RECEIVED_NEW_BLOW_COUNTS':
      return action.blowCounts;
    case 'SOIL_RESET':
      return DATA.blowCounts;
    default:
      return state;
  }
}

function blowCountsSD(state=DATA.blowCountsSD, action) {
  switch(action.type) {
    case 'SET_BLOW_COUNT_SD':
      return action.blowCountsSD;
    case 'SOIL_RESET':
      return DATA.blowCountsSD;
    default:
      return state;
  }
}

function boundaries(state=DATA.boundaries, {boundaries, ...action}) {
  switch(action.type) {
    case 'SET_BOUNDARIES':
      return boundaries;
    case 'SOIL_RESET':
      return DATA.boundaries;
    default:
      return state;
  }
}

function soilTypes(state=DATA.soilTypes, action) {
  switch(action.type) {
    case 'SET_SOIL_TYPES':
      return action.soils;
    case 'SOIL_RESET':
      return DATA.soilTypes;
    default:
      return state;
  }
}

function bulkSoil(state=DATA.bulkSoil, action) {
  switch(action.type) {
    case 'SET_BULK_SOIL':
      return action.bulkSoil;
    case 'SET_SOIL_TYPES':
    case 'SET_BOUNDARIES':
    case 'RECEIVED_NEW_BLOW_COUNTS':
    case 'SOIL_RESET':
      return DATA.bulkSoil;
    default:
      return state;
  }
}

export default function data(state=DATA, action) {
  return {
    ...state,
    safetyFactor: safetyFactor(state.safetyFactor, action),
    loads: loads(state?.loads, action),
    blowCounts: blowCounts(state.blowCounts, action),
    blowCountsSD: blowCountsSD(state.blowCountsSD, action),
    boundaries: boundaries(state.boundaries, action),
    soilTypes: soilTypes(state.soilTypes, action),
    bulkSoil: bulkSoil(state.bulkSoil, action)
  };
}
