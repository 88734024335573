import { pullAt } from 'lodash/fp';
import { IonButton, IonIcon, IonItem, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import { removeOutline, addOutline } from 'ionicons/icons'
import { createLead, configValid, helixDiametersFor, maxHelices, warnMissingParameters } from 'lib/custom-helix';

const DRAFT_HELIX = {type: 'single', draft: true};

export default class CustomHelix extends React.Component {
  constructor(props) {
    super(props);
    const initialHelices = _.get(props.lead, 'helices', []);
    this.state = {
      helices: [...initialHelices, DRAFT_HELIX]
    };
  }

  componentDidUpdate(prevProps) {
    const {helixSpacing, leadPrototype, lead} = this.props;
    if (!leadPrototype) return;

    if (maxHelices(helixSpacing) != maxHelices(prevProps.helixSpacing)) {
      if (this.state.helices.length > maxHelices(helixSpacing)) {
        this.setLead();
      }
    }
    const leadWasCleared = prevProps.lead && !lead && leadPrototype;
    const prototypeBecameAvailable = !prevProps.leadPrototype && !lead && leadPrototype;
    if (leadWasCleared || prototypeBecameAvailable) {
      this.setState({helices: [DRAFT_HELIX]});
    }
  }

  setLead = () => {
    const {helixSpacing, lead, leadPrototype} = this.props;
    if (!_.reject(this.state.helices, 'draft').length) return this.props.clearHelixSelection();

    this.props.setLead(createLead(helixSpacing, leadPrototype || lead, this.state.helices), true);
  }

  addHelix = (helix) => {
    if (!helix.diameter || !helix.type) return;
    this.setState(({helices}) => ({
      helices: [..._.dropRight(helices), {...helix, draft: false}, DRAFT_HELIX]
    }), this.setLead);
  }

  removeHelix = (i) => {
    this.setState(({helices}) => ({helices: pullAt([i], helices)}), this.setLead);
  }

  setHelixProp = (selected, prop, transform=_.identity) => {
    return ({detail: {value}}) => {
      this.setState(({helices}) => ({
        helices: helices.map((helix, i) => selected === i ? {...helix, [prop]: transform(value)} : helix)
      }), this.setLead);
    };
  }

  render() {
    const {helixSpacing, leadPrototype} = this.props;
    const {helices} = this.state;

    if (!leadPrototype || !configValid(helixSpacing)) {
      warnMissingParameters(leadPrototype);
      return <div>Custom helices cannot be computed for the selected shaft type.</div>;
    }

    const availableHelicesWithIndex = _.take(helices, maxHelices(helixSpacing)).map((helix, i) => ({...helix, i}));

    return (
      <IonList lines="full">
        {_.reverse(availableHelicesWithIndex).map(({i, ...helix}) => (
          <IonItem className="helix-select" key={i + `${helix.draft}`}>
            <IonSelect value={helix.diameter} onIonChange={this.setHelixProp(i, 'diameter', _.toNumber)} slot="start">
              {helix.draft && <option value="">-</option>}
              {helixDiametersFor(leadPrototype).map((diameter) => (
                <IonSelectOption key={diameter} value={diameter}>{diameter}"</IonSelectOption>
              ))}
            </IonSelect>
            <IonSelect value={helix.type} onIonChange={this.setHelixProp(i, 'type')}>
              <IonSelectOption value="single">Single</IonSelectOption>
              <IonSelectOption value="dual">Dual</IonSelectOption>
            </IonSelect>
            {helix.draft ? (
              <IonButton
                color="secondary"
                disabled={!helix.diameter || !helix.type}
                onClick={() => this.addHelix(helix)}
                slot="end"
              >
                <IonIcon icon={addOutline} size="large"/>
              </IonButton>
            ) : (
              <IonButton color="warning" onClick={() => this.removeHelix(i)} slot="end">
                <IonIcon icon={removeOutline} size="large"/>
              </IonButton>
            )}
          </IonItem>
        ))}
      </IonList>
    );
  }
};
