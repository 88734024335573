import { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { loadName } from 'helpers/loads';
import { ProjectContext } from 'components/layouts/Project';
import SlideHeader from 'components/helpers/SlideHeader';
import PileSelection from './pile-selection/PileSelection';
import { IonContent, IonFooter, IonHeader } from '@ionic/react';
import RequiredCapacity from './RequiredCapacity';

export default function Pile() {
  const {data} = useContext(ProjectContext);

  return (
    <Swiper loop className="full-screen">
      {data?.loads.map((load, index) => (
        <SwiperSlide key={load.id}>
          <div className="ion-page">
            {data?.loads.length > 1 && (
              <IonHeader>
                <SlideHeader className={`pile-header load-${index}`}>
                  {loadName(load, index)}
                </SlideHeader>
              </IonHeader>
            )}
            <IonContent>
              <PileSelection load={load} />
            </IonContent>
            <IonFooter>
              <RequiredCapacity load={load} loadIndex={index}/>
            </IonFooter>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
