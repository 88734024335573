import classnames from 'classnames';
import { MAX_LEVEL } from 'lib/constants';
import { pathify } from 'lib/svg-helpers';
import { autoScaleMax } from 'lib/number';

import { loadName } from 'helpers/loads';
import Svg from 'components/helpers/svg';
import PatternDefs from 'components/graph/pattern-defs';
import Axes from 'components/graph/axes';
import Guide from 'components/graph/guide';
import GridLabel from 'components/graph/grid-label';
import Gridline from 'components/graph/gridline';
import DataLabel from 'components/graph/data-label';

const OFFSET_X = 20;
const OFFSET_Y = 10;
const GRAPH_WIDTH = 150;
const GRAPH_HEIGHT = 800;
const X_GRIDS = 5;
const GRAPH_DIMENTIONS = {OFFSET_X, OFFSET_Y, GRAPH_WIDTH, GRAPH_HEIGHT, X_GRIDS};

const invalidData = (data) => {
  return _.some(_.flatten(_.map(data, _.values)), _.negate(_.isFinite));
}

class DepthGraph extends React.Component {
  state = {
    displayed: _.map(this.props.loads, 'id'),
    highlight: null
  }

  toggleDisplay = (id) => this.setState(({displayed}) => ({
    highlight: null,
    displayed:  _.includes(displayed, id) ? _.without(displayed, id) : [...displayed, id]
  }))

  setHighlight = (id) => this.setState(({displayed}) => (!id || _.includes(displayed, id)) ? {highlight: id} : {})

  maxData = () => _.maxOf(_.flatMap(this.props.loads, 'data'), 'x') || 0;

  scaleFactor = () => {
    const {loads, baseScale, unitX} = this.props;
    const maxGuide = _.max(_.flatMap(loads, ({guides}) => _.values(guides))) || 0;
    return autoScaleMax(Math.max(this.maxData(), maxGuide), baseScale, unitX) || 1;
  }

  factorX = () => (GRAPH_WIDTH - OFFSET_X*2)/this.scaleFactor();

  factorY = () => 4;

  axisLabels = () => {
    const {unitX} = this.props;
    const maxLabel = _.round(unitX?.toDisplay(this.scaleFactor()), 2);
    return _.times(X_GRIDS, n => ((maxLabel / X_GRIDS) * (n + 1)));
  }

  dataPath = (data) => {
    const {unitY} = this.props;
    return data.map(({x, y}) => {
      return {
        x: OFFSET_X + x * this.factorX(),
        y: OFFSET_Y + unitY.toBase(y * this.factorY())
      };
    });
  }

  render() {
    const {maxDim, loads, unitX, unitY, maxDepth} = this.props;
    const [factorX, factorY] = [this.factorX(), this.factorY()];
    const {displayed, highlight} = this.state;

    if (!loads.length) {
      console.error('bad data', data);
      return null;
    }
    const graphHeight = GRAPH_HEIGHT * (maxDepth/MAX_LEVEL) + OFFSET_Y;

    return (
      <div className="depth-graph">
        <Svg width={GRAPH_WIDTH} height={graphHeight} maxDim={maxDim}>
          <PatternDefs key={_.uuid()}/>
          <g className="gridlines gridlines-x">
            {this.axisLabels().map((x) => (
              <Gridline key={x} value={x} factor={factorX} unit={unitX} GRAPH_DIMENTIONS={GRAPH_DIMENTIONS}/>
            ))}
          </g>
          {_.map(loads, (load, i) => _.includes(displayed, load.id) && (
            <g key={load.id || i} className={classnames('load-group', {[`load-${i}`]: loads.length > 1, fade: highlight && highlight != load.id})}>
              {_.map(load.guides, (value, key) => (
                <Guide key={key}
                  value={value}
                  label={key}
                  factorX={factorX}
                  maxData={this.maxData()}
                  GRAPH_DIMENTIONS={GRAPH_DIMENTIONS}/>
              ))}
              <g className="labels labels-y">
                {load.data.map(({y}) => (
                  <GridLabel key={y} y={_.round(y, 1)} factor={factorY} unit={unitY} GRAPH_DIMENTIONS={GRAPH_DIMENTIONS}/>
                ))}
              </g>
              <g className="labels labels-x">
                {this.axisLabels().map((x) => (
                  <GridLabel key={x} x={x} factor={factorX} unit={unitX} GRAPH_DIMENTIONS={GRAPH_DIMENTIONS}/>
                ))}
              </g>
              {load.envelope && (
                <path className="std-plot" d={pathify([...this.dataPath(load.envelope[0]), ..._.reverse(this.dataPath(load.envelope[1]))])} />
              )}
              <path className="data-plot" d={pathify(this.dataPath(load.data))} />
              <g className="labels labels-data">
                {load.data.map(({x, y}) => x > 0 && (
                  <DataLabel key={y} {...{x, y, factorX, factorY, unitY, unitX, GRAPH_DIMENTIONS}}/>
                ))}
              </g>
            </g>
          ))}
          <Axes {...GRAPH_DIMENTIONS}/>
        </Svg>
        {loads.length > 1 && (
          <div className="legend">
            <ul>
              {_.map(loads, (load, i) => (
                <li key={load.id || i} className={`load-${i}`} onMouseOver={() => this.setHighlight(load.id)} onMouseLeave={() => this.setHighlight(null)}>
                  <input type="checkbox" checked={_.includes(displayed, load.id)} onChange={() => this.toggleDisplay(load.id)}/>
                  {loadName(load, i)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default function({loads, data, envelope, guides, ...props}) {
  loads = _.reject(loads || [{data, envelope, guides}], ({data}) => invalidData(data));
  return <DepthGraph loads={loads} {...props}/>
}
