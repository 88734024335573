import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IonItem } from '@ionic/react';

import UserActions from 'actions/UserActions';
import { getValue } from 'helpers/events';
import { resetTokenSelector, errorsSelector } from 'selectors/userSelector';

import PasswordReset from 'components/user/PasswordReset';
import ChangePassword from 'components/user/ChangePassword';
import UnauthenticatedLayout from 'components/layouts/Unauthenticated';

export default function PasswordManager(props) {
  const [email, setEmail] = useState('');
  const [submittedCode, setSubmittedCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errors, setErrors] = useState('');
  
  const storeToken = useSelector(resetTokenSelector);
  const storeErrors = useSelector(errorsSelector);

  const [token, setToken] = useState(storeToken);
  const [fullToken, setFullToken] = useState();

  useEffect(() => { setToken(storeToken); }, [storeToken]);
  useEffect(() => { setErrors(storeErrors); }, [storeErrors]);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    if (query.get('t')) {
      setToken(query.get('t'));
      setFullToken(query.get('t'));
      setEmail(query.get('email'))
    }
  }, [props.location.search]);

  const sendPasswordResetRequest = () => {
    setErrors('');
    if (email.length > 0) {
      UserActions.passwordReset(email);
    } else {
      setErrors("Please enter an email address");
    }
  };

  const valid = () => {
    const errors = [];
    if (!fullToken && submittedCode.length !== 6) errors.push("Security code must be 6 characters.");
    if (newPassword.length < 8) errors.push("Passwords must be 8 characters or longer.");
    setErrors(errors.join(' '));
    return !errors.length;
  };

  const changePassword = () => {
    const authToken = fullToken || token + submittedCode;
    if (valid()) {
      UserActions.changePassword(newPassword, authToken, email);
    }
  };

  const resetToken = () => {
    setToken('');
    setErrors('');
  };

  return (
    <UnauthenticatedLayout>
      {errors && <IonItem color="warning">{errors}</IonItem>}

      {token ? (
        <ChangePassword
          submittedCode={fullToken ? 'prefilled' : submittedCode}
          onCodeChange={getValue(setSubmittedCode)}
          newPassword={newPassword}
          onPasswordChange={getValue(setNewPassword)}
          changePassword={changePassword}
          resetToken={resetToken} />
      ) : (
        <PasswordReset
          value={email}
          inputChange={getValue(setEmail)}
          sendPasswordRequest={sendPasswordResetRequest} />
      )}
    </UnauthenticatedLayout>
  );
}
