import { useState, useMemo } from 'react';

export const useModifier = (modifier, dependents) => {
  const [state, setState] = useState();
  const result = useMemo(() => modifier(state), [state, ...dependents]);

  return [result, setState];
};

export default useModifier;
