import { Route, Redirect } from 'react-router-dom';
import { createHashHistory } from 'history';
import { IonReactHashRouter } from '@ionic/react-router';
import { SplashScreen } from '@capacitor/splash-screen';
import { App as CapApp } from '@capacitor/app';
import { connect } from 'react-redux';

import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';

import API from 'API';

import {loggedInSelector} from 'selectors/userSelector';

import Login from 'components/user/Login';
import Logout from 'components/user/Logout';
import Registration from 'components/user/Registration';
import EditProfile from 'components/user/EditProfile';
import UserDestroy from 'components/user/UserDestroy';
import PasswordManager from 'components/user/PasswordManager';
import UserInfo from 'components/user/UserInfo';
import PrivacyPolicy from 'components/user/PrivacyPolicy';
import Terms from 'components/user/Terms';

import ProjectLayout from 'components/layouts/Project';
import ProjectList from 'components/ProjectList';
import GlobalProjects from 'components/GlobalProjects';
import NewProject from 'components/project/NewProject';
import ProjectForm from 'components/project/ProjectForm';
import Loads from 'components/project/Loads';
import SoilLog from 'components/project/soil-log/SoilLog';
import Pile from 'components/project/Pile';
import Results from 'components/project/Results';
import AllTabs from 'components/project/AllTabs';

import ProjectInfo from 'components/ProjectInfo';
import NotFound from 'components/NotFound';

setupIonicReact({
  innerHTMLTemplatesEnabled: true,
  animated: process.env.DISABLE_ANIMATIONS ? false : true
});

const history = createHashHistory();

const UnauthenticatedApp = () => (
  <IonApp>
    <IonReactHashRouter history={history}>
      <IonRouterOutlet>
        <Route exact path="/"><Redirect to="/login"/></Route>

        {/*Unauthenticated Layout*/}
        <Route exact path="/login" component={Login}/>
        <Route exact path="/logout" component={Logout}/>
        <Route exact path="/registration" component={Registration}/>
        <Route exact path="/passwordmanager" component={PasswordManager}/>

        {/*Generic Layout*/}
        <Route exact path="/userinfo" component={UserInfo}/>
        <Route exact path="/terms" component={Terms}/>
        <Route path="/privacy" component={PrivacyPolicy}/>
        <Route exact path="/projects-info" component={ProjectInfo}/>
        <Route exact path="/account" component={EditProfile}/>
        <Route exact path="/destroy_requests" component={UserDestroy}/>

        <Route><Redirect to="/login"/></Route>
      </IonRouterOutlet>
    </IonReactHashRouter>
  </IonApp>
)

const AuthenticatedApp = () => (
  <IonApp>
    <IonReactHashRouter history={history}>
      <IonRouterOutlet>
        <Route exact path="/"><Redirect to="/projects"/></Route>

        {/*Projects Layout*/}
        <Route exact path="/projects" component={ProjectList}/>
        <Route exact path="/global-projects" component={GlobalProjects}/>

        {/*Generic Layout*/}
        <Route exact path="/userinfo" component={UserInfo}/>
        <Route exact path="/terms" component={Terms}/>
        <Route path="/privacy" component={PrivacyPolicy}/>
        <Route exact path="/projects-info" component={ProjectInfo}/>
        <Route exact path="/account" component={EditProfile}/>
        <Route exact path="/destroy_requests" component={UserDestroy}/>
        <Route exact path="/passwordmanager"><Redirect to="/projects"/></Route>

        {/*NewProject Page*/}
        <Route exact path="/projects/new" component={NewProject}/>

        {/*Project Page*/}
        <Route exact path="/projects/:id(\d+)/copy">
          <ProjectLayout InnerComponent={ProjectForm}/>
        </Route>
        <Route exact path="/projects/:id/settings">
          <ProjectLayout InnerComponent={ProjectForm}/>
        </Route>
        <Route exact path="/projects/:id/loads">
          <ProjectLayout InnerComponent={Loads}/>
        </Route>
        <Route exact path="/projects/:id/soil">
          <ProjectLayout InnerComponent={SoilLog}/>
        </Route>
        <Route exact path="/projects/:id/pile">
          <ProjectLayout InnerComponent={Pile}/>
        </Route>
        <Route exact path="/projects/:id/results">
          <ProjectLayout InnerComponent={Results}/>
        </Route>
        <Route exact path="/projects/:id/all">
          <ProjectLayout InnerComponent={AllTabs}/>
        </Route>
        <Route exact path="/projects/:id(\d+)">
          <ProjectLayout InnerComponent={ProjectForm}/>
        </Route>

        <Route exact path="/logout" component={Logout}/>
        <Route exact path="/registration" component={Registration} />
        <Route exact path="/login" component={Login} />

        <Route component={NotFound}/>
      </IonRouterOutlet>
    </IonReactHashRouter>
  </IonApp>
)

function mapStateToProps(state, ownProps) {
  return {
    loggedIn: loggedInSelector(state),
  }
}

function withReduxConnect(Component) {
  return connect(mapStateToProps)(Component);
}

class App extends React.Component {
  componentDidMount() {
    CapApp.addListener('appUrlOpen', ({url}) => this.navigateToDeepLink(url));
    this.checkForDeepLink();
    SplashScreen.hide();
    setTimeout(SplashScreen.hide, 15000);
    if (window.device.platform == 'ios' && this.props.loggedIn) {
      API.requestAppTrackingTransparency();
    }
  }

  checkForDeepLink = async () => {
    const {url} = await CapApp.getLaunchUrl() || {};
    this.navigateToDeepLink(url);
  }

  navigateToDeepLink = (url) => {
    if (url) {
      const path = url.split('#').pop() || url.replace('geoapp:', '');
      hashHistory.push(path);
    }
  }

  render() {
    const {loggedIn} = this.props;

    return loggedIn ? <AuthenticatedApp/> : <UnauthenticatedApp/>;
  }
}

export default withReduxConnect(App);
history.listen((location) => {
  if (window.gtag) {
    window.gtag('config', process.env.GOOGLE_ANALYTICS_ID, {'page_path': location.pathname + location.search});
    window.gtag('event', 'pageview');
  }
});
