import { useHistory } from 'react-router-dom';

import { withOnline } from 'components/helpers/online-helper';

import { userInfoSelector } from 'selectors/userSelector';

import MainLayout from 'components/layouts/MainLayout';
import UserForm from 'components/user/UserForm';
import { useSelector } from 'react-redux';

export default withOnline(function EditProfile({online}) {
  const user = useSelector(userInfoSelector);
  const history = useHistory();
  const goToProjects = () => history.push({pathname: '/projects'});

  return (
    <MainLayout title="Edit Account" narrow>
      <div className="narrow-container">
        {online ? (
          <UserForm user={user} actionLabel="Save" onSuccess={goToProjects}/>
        ) : (
          <p className="error">
            You must be online to edit your account information.
          </p>
        )}
      </div>
    </MainLayout>
  );
});
