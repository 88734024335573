import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonMenu, IonPopover
} from '@ionic/react';
import {
  briefcase, call, chevronBack, helpBuoy, logIn, logOut, mail, person
} from 'ionicons/icons';
import HelpText from 'config/help-text';
import AuthenticationActions from 'actions/AuthenticationActions';

function mapDispatchToProps(dispatch, props) {
  return {
    logoutAction: AuthenticationActions.logout,
  }
}

function withReduxConnect(Component) {
  return connect(null, mapDispatchToProps)(Component);
}

class MainNav extends React.Component {
  menuRef = React.createRef()

  closeMenu = () => this.menuRef.current?.close();
  logOut = () => {
    this.menuRef.current?.close();
    this.props.logoutAction();
    this.props.history.push('/logout');
  }

  render() {
    const {user={}, loggedIn} = this.props;

    return (
      <IonMenu side="end" contentId="main-content" ref={this.menuRef}>
        <IonList>
          <IonItem onClick={this.closeMenu} lines="full">
            <IonIcon icon={chevronBack} size="large" aria-hidden="true" slot="start"/>
            <IonLabel>
              Close Menu
            </IonLabel>
            </IonItem>
          {loggedIn ? <>
            <IonItem color="light" lines="full">
              <IonLabel>
                <p>Signed in as:</p>
                <h2>{user.email}</h2>
                {user.is_admin && (
                  <p style={{fontSize: '0.8em', fontWeight: 'bold', opacity: 0.7}}>ADMIN</p>
                )}
                {user.global_editor && (
                  <p style={{fontSize: '0.8em', fontWeight: 'bold', opacity: 0.7}}>GLOBAL EDITOR</p>
                )}
              </IonLabel>
            </IonItem>
            <IonItem routerLink="/account" onClick={this.closeMenu} lines="full" button data-testid="edit-account">
              <IonIcon icon={person} aria-hidden="true" slot="start"/>
              <IonLabel>
                Edit Account
              </IonLabel>
            </IonItem>
            <IonItem routerLink="/projects" onClick={this.closeMenu} lines="full">
              <IonIcon icon={briefcase} aria-hidden="true" slot="start"/>
              <IonLabel>
                My Projects
              </IonLabel>
            </IonItem>
            <IonItem onClick={this.logOut} lines="full" button detail="false">
              <IonIcon icon={logOut} aria-hidden="true" slot="start"/>
              <IonLabel>
                Log Out
              </IonLabel>
            </IonItem>
          </> : (
            <IonItem routerLink="/login" onClick={this.closeMenu} lines="none">
              <IonIcon icon={logIn} aria-hidden="true" slot="start"/>
              <IonLabel>
                Log In
              </IonLabel>
            </IonItem>
          )}
          <IonItemDivider>Magnum Piering GeoApp&reg;</IonItemDivider>
          <IonItem href="mailto:help@magnumpiering.com" lines="full">
            <IonIcon icon={mail} aria-hidden="true" slot="start"/>
            Email Support
          </IonItem>
          <IonItem href="http://www.magnumpiering.com" target="_blank" lines="full">
            <IonIcon icon={helpBuoy} aria-hidden="true" slot="start"/>
            Launch Website
          </IonItem>
          <IonItem id="trigger-call" lines="full" button detail="false">
            <IonIcon icon={call} aria-hidden="true" slot="start"/>
            Phone Magnum
          </IonItem>
          <IonPopover trigger="trigger-call" triggerAction="click">
            <div className="ion-padding">
              <h4>Phone Magnum</h4>
              {HelpText.phoneMagnum}
            </div>
          </IonPopover>
        </IonList>
      </IonMenu>
    );
  }
}

export default withReduxConnect(withRouter(MainNav));
