import { get } from "lodash/fp";

export const loggedInSelector = _.property('user.authentication.token');
export const regStatusSelector = _.property('user.regStatus');
export const loginInfoSelector = _.property('user.loginInfo');
export const userInfoSelector = _.property('user.userInfo');
export const userSettingsSelector = _.property('user.userInfo.settings');
userSettingsSelector.at = (path) => _.flow(userSettingsSelector, get(path));
export const resetTokenSelector = _.property('user.resetToken');
export const errorsSelector = _.property('user.userStoreErrors');
export const emailSelector = _.property('user.userInfo.email');
export const destroySuccessSelector = _.property('user.destroySuccessful');
export const destroyRequestSuccessSelector = _.property('user.destroyRequestSuccessful');
export const cannonicalIdSelector = (state) => state.user?.userInfo?.id || state.user?.regStatus?.userId
