import SoilLabels from 'config/soil-labels';
import { MAX_LEVEL } from 'lib/constants';
import { pathify, groupTranslate } from 'lib/svg-helpers';
import PartList from 'lib/part-list';
import Svg from 'components/helpers/svg';
import SoilTexturePatterns from 'components/project/soil-log/SoilTexturePatterns';
import SoilSelector from 'components/project/soil-log/SoilSelector';
import PileGraphic from './PileGraphic';

const AXIS_X = 22;
const OFFSET_Y = 10;
const GRAPH_WIDTH = 60;
const GRAPH_HEIGHT = 1012;

export const SoilAngleIndicator = ({x, y, angle}) => {
  const LENGTH = 8;
  const xDistance = Math.sin((angle * Math.PI) / 180) * LENGTH;
  return (
    <g transform={`translate(${x}, ${y})`}>
      <line x1={0} y1={0} x2={0} y2={-LENGTH} strokeWidth={0.3} stroke="#888" strokeDasharray="2,1"/>
      <line x1={0} y1={0} x2={xDistance} y2={- Math.cos((angle * Math.PI) / 180) * LENGTH} strokeWidth={0.5} stroke="#222"/>
      <text x={(xDistance < 0 ? -1 : 1) * 3 + xDistance} textAnchor="middle" y="-3" fontSize="3">
        {angle}°
      </text>
    </g>
  );
}

export default class PileGraph extends React.Component {
  groupTranslate = groupTranslate(this.props)

  factorY = () => 5

  depthLabel = (i) => {
    return _.round((i * this.props.interval), 1) + this.props.lengthUnit.abbreviation;
  }

  labelsY = () => {
    const coordX = AXIS_X - 3;

    return (
      <g className="labels labels-y">
        {_.times(this.props.blowCounts.length, (n) => {
          const depth = n * this.props.interval;
          const coordY = OFFSET_Y + this.props.lengthUnit.toBase(depth*this.factorY());
          return (
            <g key={'label-y-'+depth}>
              <text x={coordX} y={coordY} textAnchor="end">{this.depthLabel(n)}</text>
              <path className="axis" d={pathify([{x: coordX+1, y: coordY}, {x: AXIS_X, y: coordY}])} />
            </g>
          );
        })}
      </g>
    );
  }

  getSoilLevels = () => this.props.boundaries.map((boundary, i, boundaries) => {
    const startLevel = boundaries[i-1] || 0;
    return {
      lastItem: i+1 == boundaries.length,
      startLevel: startLevel,
      endLevel: boundaries[i],
      soilType: this.props.soilTypes[i] || {id: `undefined-${i}`, name: 'undefined'},
      minLevel: Math.min(startLevel + 1, MAX_LEVEL),
      maxLevel: (boundaries[i+1] - 1) || MAX_LEVEL
    };
  });

  render() {
    const {load, maxDim, maxDepth} = this.props;
    const {pileOptions, pileSegments, batteredPile, batterAngle} = load;
    const graphHeight = maxDepth ? GRAPH_HEIGHT * (maxDepth/MAX_LEVEL) + OFFSET_Y : GRAPH_HEIGHT;

    return (
      <Svg width={GRAPH_WIDTH} height={graphHeight} maxDim={maxDim}>
        <SoilTexturePatterns key={_.uuid()} />
        <path id="y-axis" className="axis" d={pathify([{x: AXIS_X, y: OFFSET_Y}, {x: AXIS_X, y: graphHeight}])} />
        {this.labelsY()}
        {batteredPile && batterAngle && batterAngle != 0 && (
          <SoilAngleIndicator x={AXIS_X + 2 + 15} y={OFFSET_Y} angle={batterAngle}/>
        )}
        <g className="soil-list" transform={this.groupTranslate(AXIS_X + 2, OFFSET_Y)}>
          {this.getSoilLevels().map(({soilType, startLevel, endLevel}, i) => (
            <SoilSelector
              key={soilType.id}
              soilType={soilType.name}
              soilLabels={SoilLabels}
              startLevel={startLevel}
              endLevel={endLevel}
              factorY={this.factorY()}
              angle={batteredPile ? batterAngle ?? 0 : 0}
              width="30" />
          ))}
        </g>
        <PileGraphic
          load={load}
          shafts={PartList.shafts(pileSegments)}
          helices={PartList.helices(pileSegments)}
          coating={pileOptions?.coating}
          offsetX={AXIS_X + 2 + 15}
          offsetY={OFFSET_Y - 1}
          factorY={this.factorY()}/>
      </Svg>
    );
  }
}
