import { Device } from '@capacitor/device';
import { render } from 'react-dom';
import {Provider as ReduxProvider} from 'react-redux';
import uuid from 'lodash-uuid';
import maxOf from './helpers/maxOf';
import upsertBy from './helpers/upsertBy';

_.mixin(uuid, {chain: true});
_.mixin({upsertBy, maxOf});

import Rollbar from 'rollbar';
import rollbarConfig from 'config/rollbar';
window.Rollbar = new Rollbar(rollbarConfig);

import store, { loggedIn } from 'store';
import Repository from 'repository';
import API from 'API';
import UserActions from 'actions/UserActions';

import App from 'app';
import StoreContext from 'contexts/StoreContext';
import 'styles/main.scss';

function getDeviceInfo() {
  return Device.getInfo().then((info) => {
    window.device = info;
    document.getElementById('root').classList.add(info.platform);
  });
}

function initRepository() {
  return Repository.loadLocalStorage().then(() => {
    Repository.persist();
    if (loggedIn()) {
      store.subscribe(_.throttle(Repository.syncOfflineProjects, 30000, {leading: true}));
      UserActions.loadUserInfo();
      API.getProductTypes();
    }
    store.subscribe(() => {
      const {user: {userInfo={}}} = store.getState();
      userInfo.id = userInfo.id || userInfo.email;
      window.Rollbar.configure({payload: {
        person: _.pick(userInfo, 'id', 'email', 'name', 'global_editor', 'is_admin')
      }});
    });
  });
}

const contexts = (
  <ReduxProvider store={store} >
    <StoreContext.Provider value={store}>
      <App />
    </StoreContext.Provider>
  </ReduxProvider>
)

Promise.all([getDeviceInfo(), initRepository()]).then(() => {
  render(contexts, document.getElementById('root'));
});
