import { loadName } from 'helpers/loads';

export const LOADS_PER_PAGE = 5;

export const cutSheetPresent = (productTypes, {prefix, pitch}) => {
  const productType = _.find(productTypes, {prefix});
  return !!productType?.cut_sheets?.[pitch];
};

const capacityPages = (loads) => _.ceil(loads.length / LOADS_PER_PAGE);
const firstLoadPageNumber = (loads) => loads.length === 1 ? 3 : 3 + capacityPages(loads);

export function cutSheetPages({data: {loads}}, productTypes) {
  let page = firstLoadPageNumber(loads);
  return _.fromPairs(_.reduce(loads,
    (result, load) => {
      page = page + 2;
      if (cutSheetPresent(productTypes, load.pileOptions)) {
        page = page + 1;
        return [...result, [page, _.pick(load.pileOptions, 'prefix', 'pitch')]];
      }
      return result;
    }, []
  ));
}

function loadPageReportIndex(page, loads, productTypes) {
  return _.flatMap(loads,
    (load, i) => _.compact([
      {title: `${loads.length > 1 ? `${loadName(load, i)} ` : ''}Pile Configuration`, pageNumber: page += 1},
      {title: `${loads.length > 1 ? `${loadName(load, i)} ` : ''}Graphical Results`, pageNumber: page += 1},
      cutSheetPresent(productTypes, load.pileOptions) && {
        title: `Helical Pile Cut Sheet ${load.pileOptions.prefix}`, pageNumber: page += 1
      }
    ])
  );
}

export function reportPageIndex({data: {loads}}, productTypes) {
  let page = 2;
  const capacityPageCount = capacityPages(loads);
  const standardPages = [
    {title: "Introduction", pageNumber: page},
    {title: "Approximate Pile Locations", pageNumber: page},
    {title: "Design Options", pageNumber: page},
    {title: "Required Capacity", pageNumber: capacityPageCount > 1 ? `${page += 1}-${page += capacityPageCount - 1}` : page},
    {title: "Engineering Logic", pageNumber: page += 1},
    {title: "Baseline Soil Profile", pageNumber: page += 1},
  ];
  const loadPages = loadPageReportIndex(page, loads, productTypes);
  return [
    ...standardPages,
    ...loadPages,
    {title: "Limitations and Conditions of Use", pageNumber: _.last(loadPages).pageNumber + 1},
  ];
}
