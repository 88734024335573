const INCHES_IN_FEET = 12;
const inToFt = (inches) => inches / INCHES_IN_FEET;

function extensionFromLead(lead) {
  return {..._.pick(lead, ['capacity', 'coupling', 'diameter', 'max_torque', 'pitch', 'torque', 'wall']), length: 12};
}

function extensionsForLength(extension, extensionLength, startId=2) {
  return _.times(extensionLength, (n) => ({...extension, id: n + startId}));
}

const helixArea = ({diameter, type}) => (
  (inToFt(parseInt(diameter, 10))**2) * (Math.PI/4) * (type === 'dual' ? 0.847 : 1)
);

export default {
  helix(helix, segment, cumulativeOffset) {
    const helixPosition = inToFt(helix.spacing + cumulativeOffset);
    helix.positionFromTop = segment.positionFromTop + (segment.shaftLength - helixPosition);
    helix.positionFromBottom = segment.positionFromBottom + helixPosition;
    helix.area = helixArea(helix);
    return helix;
  },

  shafts(segments=[]) {
    segments = _.cloneDeep(segments || []);

    const totalLength = this.totalLength(segments);
    let cumulativeOffset = 0;
    return _.flatMap(_.reverse(segments), (segment) => {
      segment.shaftLength = inToFt(segment.length);
      segment.positionFromTop = cumulativeOffset;
      segment.positionFromBottom = totalLength - cumulativeOffset - segment.shaftLength;

      cumulativeOffset += segment.shaftLength;
      if (!_.isEmpty(segment.helices)) {
        const helixHeight = _.round(inToFt(_.reduce(segment.helices, (total, helix) => total + helix.spacing, segment.pitch)));
        if (helixHeight < segment.shaftLength) {
          const extension = this.extensionFromLead(segment);
          const nonHelixLength = segment.shaftLength - helixHeight;
          const startingId = _.maxOf(segments, 'id') + 1;

          return [
            ..._.times(nonHelixLength, (n) => ({
              ...extension,
              id: n + startingId,
              shaftLength: 1,
              positionFromTop: segment.positionFromTop + n,
              positionFromBottom: segment.shaftLength - 1 - n
            })),
            {...segment, shaftLength: helixHeight, length: helixHeight * INCHES_IN_FEET, positionFromTop: cumulativeOffset - helixHeight}
          ];
        }
      }
      return segment;
    });
  },

  helices(segments=[]) {
    let cumulativeOffset = 0;
    return _.flatMap(this.shafts(segments), (segment) => {
      return _.map(segment.helices, (helix) => _.tap(
        this.helix(helix, segment, cumulativeOffset), () => {
          cumulativeOffset += helix.spacing;
        }
      ));
    });
  },

  maxTorque(segments) {
    return _.minBy(segments, 'torque')?.torque; // the weakest link
  },

  totalLength(segments=[]) {
    return inToFt(_.sumBy(segments, 'length'));
  },

  extensionFromLead,
  extensionsForLength,

  generatePile(lead, pileLength) {
    const extensionLength = pileLength - (lead.length / 12);
    const extension = extensionFromLead(lead);
    return [
      {...lead, id: 1},
      ...extensionsForLength(extension, extensionLength)
    ];
  }
}
