import { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  IonToggle
} from '@ionic/react';
import { removeOutline } from 'ionicons/icons'

import HelpText from 'config/help-text';
import { pitchLabelForUnit } from 'helpers/unit-display';
import degreeUnits from 'config/degree-units';
import useModifier from 'hooks/useModifier';

import { leadListSelector, productTypesSelector } from 'selectors/productSelector';

import API from 'API';
import {
  updateLoad, updateLoadPileFilter, setLoadLead, getProductsByPrefix
} from 'actions/projectData';

import { getValue, preventDefault } from 'helpers/events';
import { withOnline } from 'components/helpers/online-helper';
import SelectInput from 'components/helpers/SelectInput';
import NumberInput from 'components/helpers/number-input';
import BooleanInput from 'components/helpers/boolean-input';
import { displayDiameterError, DISCONTINUED_PILE_WARNING } from 'components/helpers/error-helper';
import { SegmentView, SegmentViews } from 'components/helpers/SegmentView';

import DualCutterFilter from './DualCutterFilter';
import ShaftFrictionSelector from './ShaftFrictionSelector';
import Lead from './Lead';
import CustomHelix from './CustomHelix';

const GALVANIZATION_TYPES = ["Non-Galvanized", "Galvanized"];
const PITCH_OPTIONS = [3.0, 6.0];

const soilPresent = (soilTypes, soil) => _.includes(_.map(soilTypes, 'name'), soil);

const prefixFromSku = (sku) => {
  const match = sku.match(/^[A-Z]+(\d+BR?)/);
  return match ? `MH${match[1]}` : '';
}
function leadOptions(leadList=[]) {
  return _.uniqBy(_.orderBy(leadList, 'length'), ({helixConfig}) => _.join(helixConfig));
}
const maxBlowCountDepth = (blowCounts=[]) => blowCounts.slice(1).indexOf(0) * 5;
const productTypeForCapacity = (productTypes, minCapacity) => (
  _.minBy(productTypes.filter((t) => t.capacity >= minCapacity), 'capacity')
) || (
  _.maxBy(productTypes, 'capacity')
);
const defaultPitchFor = ({pitches=[], diameter}={}) => (
  pitches.length === 1 ? pitches[0] : (diameter >= 5 ? 6 : 3)
);
const defaultLengthFor = (blowCounts) => Math.max(maxBlowCountDepth(blowCounts), 12);

function PileOptions(props) {
  const dispatch = useDispatch();
  const {data={}, settings={}, readonly, online, load: {
    id: loadId, pileOptions:selected, pileSegments, compression=0, tension=0,
    aboveGrade=0, groutDiameter, groutedShaft, batteredPile, batterAngle,
    includeShaftFriction, reduceShaftFriction, includeFillSensitive, includeBedrock
  }} = props;
  const {lengthUnit, smallLengthUnit} = settings;
  const leadSegment = _.first(pileSegments);

  const update = (...args) => updateLoad(loadId, ...args);
  const updatePileFilter = (...args) => updateLoadPileFilter(loadId, ...args);

  const rawProductTypes = useSelector(productTypesSelector);
  const productTypes = useMemo(() => {
    const leadProductType = leadSegment ? {...leadSegment, prefix: prefixFromSku(leadSegment.sku), discontinued: true} : null;
    return _.uniqBy(_.compact([...rawProductTypes, leadProductType]), 'prefix');
  }, [rawProductTypes, pileSegments]);

  const [loading, setLoading] = useState(false);
  const pageRef = useRef();

  const minCapacity = Math.max(tension, compression);
  const defaultProductType = useMemo(() => {
    return productTypeForCapacity(productTypes, minCapacity);
  }, [productTypes, minCapacity]);

  const [selectedProductType, setSelectedProductType] = useModifier((state) => {
    const {prefix} = selected ?? {};
    return state || prefix ? _.find(productTypes, {prefix}) : defaultProductType;
  }, [selected?.prefix, productTypes, defaultProductType]);

  const [activeTab, setActiveTab] = useState(() => leadSegment?.custom ? "custom" : "preset");

  useEffect(() => {
    if (_.isEmpty(rawProductTypes)) API.getProductTypes();
    if (!selected?.pileLength) {
      dispatch(updatePileFilter({pileLength: defaultLengthFor(data?.blowCounts)}));
    }
  }, []);

  useEffect(() => {
    fetchLeads();
  }, [selected?.prefix]);

  const setProductType = (prefix) => {
    const productType = _.find(productTypes, {prefix}) || defaultProductType;

    if (productType) {
      if (!_.includes(productType.pitches, selected?.pitch)) {
        productType.pitch = defaultPitchFor(productType);
      }
      if (!_.includes(productType.lengths, parseInt(selected?.length, 10))) {
        productType.length = '';
      }
      dispatch(updatePileFilter(productType));

      if (!groutDiameter || !groutedShaft || groutDiameter <= productType.diameter) {
        dispatch(update({groutDiameter: _.ceil(productType.diameter + 2)}));
      }
    }

    setSelectedProductType(productType);
    if (leadSegment?.custom) clearHelixSelection();
  };

  const clearHelixSelection = () => {
    dispatch(updatePileFilter({helixConfig: null}));
  };

  const productsLoading = useRef(false);
  const fetchLeads = async () => {
    if (productsLoading.current == selectedProductType?.prefix) return;

    setLoading(true);
    productsLoading.current = selectedProductType?.prefix;
    await dispatch(getProductsByPrefix(selectedProductType?.prefix))
    setLoading(selectedProductType?.prefix !== productsLoading.current);
  };

  const changeLead = (lead, custom=false) => {
    if (!lead) return clearHelixSelection();
    if (!custom) pageRef.current?.scrollIntoView();
    if (!selected?.prefix) setProductType(selectedProductType?.prefix);
    dispatch(setLoadLead(loadId, lead));
  };

  const showLoading = loading && online;
  const availablePitches = _.pull(_.sortBy(selectedProductType?.pitches || PITCH_OPTIONS), 0);
  const leadList = useSelector(leadListSelector({...(selected ?? {}), ...selectedProductType, pileLength: selected?.pileLength})) || [];

  return <>
    <IonList lines="full" ref={pageRef}>
      <h3>Pile</h3>
      {selectedProductType?.admin_only && (
        <div className="ion-padding">
          <p className="admin-alert">You are viewing an admin only product.</p>
        </div>
      )}
      <SelectInput label="Shaft"
        readonly={readonly}
        value={selectedProductType?.prefix}
        onValueChange={setProductType}
        options={["Auto Select", ..._.map(productTypes, 'prefix')]}
        allowBlank={false}
        className="label-max-width"
        errorText={
          (groutedShaft && displayDiameterError(selected.diameter, groutDiameter, data))
          || (selectedProductType?.discontinued && DISCONTINUED_PILE_WARNING)
        }
        help={HelpText.shaftSize}/>
      <SelectInput label="Pitch"
        readonly={readonly}
        value={selected?.pitch || defaultPitchFor(selectedProductType)}
        onValueChange={(pitch) => dispatch(updatePileFilter({pitch}))}
        options={availablePitches}
        optionDisplay={pitchLabelForUnit(smallLengthUnit)}
        allowBlank={false}/>
      <SelectInput label="Coating"
        readonly={readonly}
        value={selected?.coating}
        onValueChange={(coating) => dispatch(updatePileFilter({coating}))}
        options={GALVANIZATION_TYPES}
        allowBlank={false}/>
      <BooleanInput label="Grouted Shaft?"
        value={groutedShaft}
        onValueChange={(groutedShaft) => dispatch(update({groutedShaft}))} />
      {groutedShaft && (
        <NumberInput label="Grouted Diameter"
          value={groutDiameter}
          onValueChange={(groutDiameter) => dispatch(update({groutDiameter}))}
          unit={smallLengthUnit}
          help={HelpText.standardGroutDiameters}
          errorText={displayDiameterError(selected.diameter, groutDiameter, data)}
          min={1}/>
      )}
      <h3>Calculation</h3>
      <ShaftFrictionSelector
        {...{includeShaftFriction, reduceShaftFriction, groutedShaft}}
        onValueChange={(values) => dispatch(update(values))}/>
      {(includeShaftFriction || groutedShaft) && <>
          {soilPresent(data?.soilTypes, 'sensitive') && (
            <BooleanInput label="Include Fill/Sensitive?"
              help={HelpText.includeFill}
              value={includeFillSensitive}
              onValueChange={(includeFillSensitive) => dispatch(update({includeFillSensitive}))}/>
          )}
          {soilPresent(data?.soilTypes, 'bedrock') && (
            <BooleanInput label="Include Bedrock?"
              help={HelpText.includeBedrock}
              value={includeBedrock}
              onValueChange={(includeBedrock) => dispatch(update({includeBedrock}))}/>
          )}
        </>}
      <h3>Depth</h3>
      <IonItem>
        <IonLabel>Pile Depth</IonLabel>
        <IonInput type="number"
          disabled={readonly}
          value={_.round(lengthUnit?.toDisplay(selected?.pileLength), 1)}
          onIonChange={getValue((value) => {
            dispatch(updatePileFilter({pileLength: _.round(lengthUnit?.toBase(value))}))
          })}
          min={1}
          max={200}/>
        <IonText slot="end">
          {lengthUnit?.label}
        </IonText>
      </IonItem>
      <NumberInput label={<>Pile Above(+)/<wbr/>Below(-) Grade</>}
        value={_.round(lengthUnit?.toDisplay(aboveGrade), 1)}
        onValueChange={(value) => dispatch(update({aboveGrade: _.round(lengthUnit?.toBase(value))}))}
        unit={lengthUnit}
        className="label-max-width"
        readonly={readonly}/>
      <IonItem>
        <IonToggle
          readonly={readonly}
          checked={batteredPile}
          onClick={getValue((batteredPile) => dispatch(update({batteredPile})))}>
          <span className="clickable">Battered Pile:</span>
        </IonToggle>
      </IonItem>
      {batteredPile && (
        <NumberInput label="Batter Angle"
          value={batterAngle || 0}
          onValueChange={(batterAngle) => dispatch(update({batterAngle}))}
          unit={degreeUnits['degrees']}
          max={60}
          min={-60}
          readonly={readonly}/>
      )}
    </IonList>

    {readonly ? (leadSegment && (
      <IonList lines="full">
        <h3>Selected Helix Arrangement</h3>
        <IonItem>
          {leadSegment.helixConfig}{leadSegment.custom ? " (custom)" : ""}
        </IonItem>
      </IonList>
    )) : <>
      {selected?.helixConfig && (
        <IonList lines="full">
          <h3>Your Helix Arrangement</h3>
            {!loading && !leadSegment && (
              <IonItem color="danger">This Lead is incompatible with your selected options</IonItem>
            )}
          <IonItem>
            {selected?.helixConfig}{leadSegment && leadSegment.custom ? " (custom)" : ""}
            <IonButton onClick={clearHelixSelection} slot="end" color="warning">
              <IonIcon icon={removeOutline} slot="icon-only" size="large"/>
            </IonButton>
          </IonItem>
        </IonList>
      )}

      <IonList lines="full">
        <h4>
          Select a {leadSegment && 'Different'} Helix Arrangement:
          {showLoading && <IonSpinner name="lines-sharp-small"/>}
        </h4>
        {!showLoading && !_.isEmpty(leadList) && (
          <IonSegment value={activeTab} onIonChange={preventDefault(getValue(setActiveTab))} mode="md">
            <IonSegmentButton value="preset">Preset Arrangement</IonSegmentButton>
            <IonSegmentButton value="custom">Custom</IonSegmentButton>
          </IonSegment>
        )}
        <SegmentViews value={activeTab}>
          <SegmentView value="preset">
            <IonList lines="full">
            <IonItem>
              <DualCutterFilter/>
            </IonItem>
              {!showLoading && ((_.isEmpty(leadList) || !selected?.pileLength) ? (
                <div className="blank">
                  No products matching this filter were found.
                  Please change your search filters.
                </div>
              ) : (
                leadOptions(leadList).map((lead, i) => (
                  <Lead key={`${lead.key}${i}`} lead={lead} setLead={changeLead}/>
                ))
              ))}
            </IonList>
          </SegmentView>
          <SegmentView value="custom">
            <CustomHelix
              lead={leadSegment}
              leadPrototype={_.first(leadList)}
              helixSpacing={_.get(selectedProductType, ['helix_spacings', selected?.pitch])}
              clearHelixSelection={clearHelixSelection}
              setLead={changeLead}/>
          </SegmentView>
        </SegmentViews>
      </IonList>
    </>}
  </>;
}

export default withOnline(PileOptions);
