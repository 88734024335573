import torqueUnitFor from 'config/torque-units';
import { torques, requiredInstallTorque as getRequiredInstalTorque } from 'lib/capacity-calculations';
import maxGraphDepth from 'lib/graph-depth';
import { toSignificant } from 'lib/number';
import DepthGraph from 'components/graph/DepthGraph';

const maxTorque = (pileSegments) => {
  const lead = _.find(pileSegments, {lead_extension: 'Lead'});
  return lead?.max_torque ?? 0;
};

const requiredTorqueLabel = ({torqueUnit, requiredInstallTorque}) => (
  `Required Install Torque = ${toSignificant(torqueUnit?.toDisplay(requiredInstallTorque), 3).toLocaleString()}`
);

const guidesForLoad = (load, requiredInstallTorque, torqueUnit)  => {
  const {pileSegments, groutedShaft, compression, tension} = load;
  const torqueGuides = {
    'Manufacturer Torque Rating': maxTorque(pileSegments) / 2000
  };
  if (!groutedShaft && (compression > 0 || tension > 0)) {
    torqueGuides[requiredTorqueLabel({requiredInstallTorque, torqueUnit})] = requiredInstallTorque;
  }
  return torqueGuides;
};

export default function TorqueGraph({project={}, load, maxDim}) {
  const {data, settings: {lengthUnit}={}} = project;
  const loads = load ? [load] : data?.loads;
  const loadIndex = (id) => _.findIndex(data?.loads, {id});
  const torqueUnit = torqueUnitFor(lengthUnit);

  return (
    <DepthGraph
      loads={
        _.map(loads, ({id, name, ...load}) => ({
          id, name,
          data: torques(project, loadIndex(id)),
          envelope: [torques(project, loadIndex(id), -1), torques(project, loadIndex(id), 1)],
          guides: guidesForLoad(load, getRequiredInstalTorque(project, loadIndex(id)), torqueUnit)
        }))
      }
      unitX={torqueUnit}
      unitY={lengthUnit}
      maxDepth={maxGraphDepth({data: project.data})}
      baseScale="25"
      maxDim={maxDim} />
  );
}
