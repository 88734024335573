import { useState, useEffect } from 'react';
import { IonButton, IonItem, IonList, IonRouterLink, IonSpinner } from '@ionic/react';
import { useSelector, useStore } from 'react-redux';

import { getValue } from '../../helpers/events';
import MagnumInput from '../helpers/MagnumInput';
import useModel from '../../hooks/useModel'

import { regStatusSelector } from 'selectors/userSelector';

import RegistrationActions from 'actions/RegistrationActions';
import UserActions from 'actions/UserActions';

import CountrySelect from 'components/helpers/CountrySelect';

const NEW_USER = {
  name: '',
  company: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  country: 'US',
  phone: '',
  email: ''
};
const KEYS = Object.keys(NEW_USER);

export default function UserForm(props) {
  const newUser = _.isEmpty(props.user);
  const [user, setUserProperty, mergeUser] = useModel(() => props.user ? _.pick(props.user, KEYS) : _.clone(NEW_USER));
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const store = useStore();
  const regStatus = useSelector(regStatusSelector);

  useEffect(() => {
    const {userId, errors} = regStatus;
    if (userId && !errors) {
      props.onSuccess();
    } else {
      setErrors(errors);
      setLoading(false);
    }
  }, [regStatus]);

  const setUser = (field) => (e) => {
    e.preventDefault();
    setUserProperty(field, e.target.value || e.detail.value);
    setErrors(null);
  };

  const validatePasswords = (errors) => {
    if (password !== passwordConfirmation) {
      errors.push('Passwords must match');
    }
  }

  const isValid = () => {
    const errors = [];
    newUser && validatePasswords(errors);
    setErrors(errors.join('; ') || null);
    return _.isEmpty(errors);
  }

  const persistUser = (event) => {
    event.preventDefault();

    if (isValid()) {
      setLoading(true);
      setErrors(null);
      if (newUser) {
        RegistrationActions.newRegistration({...user, password});
      } else {
        UserActions.update(user);
      }
    }
  }

  const {actionLabel} = props;
  const {name, company, address, city, state, zipcode, country, phone, email} = user;

  return (
    <div className="registration-page" data-testid="account-page">
      <form className="list" onSubmit={persistUser} disabled={loading}>
        <IonList>
          <IonItem>
            <MagnumInput value={name} onChange={setUser('name')} label="Name"/>
          </IonItem>
          <IonItem>
            <MagnumInput value={company} onChange={setUser('company')} label="Company"/>
          </IonItem>
          <IonItem>
            <MagnumInput value={address} onChange={setUser('address')} label="Address"/>
          </IonItem>
          <IonItem>
            <MagnumInput value={city} onChange={setUser('city')} label="City"/>
          </IonItem>
          <IonItem>
            <MagnumInput value={state} onChange={setUser('state')} label="State/Province"/>
          </IonItem>
          <IonItem>
            <MagnumInput value={zipcode} onChange={setUser('zipcode')} label="Postal Code"/>
          </IonItem>
          <IonItem>
            <CountrySelect value={country || 'US'} onIonChange={setUser('country')}/>
          </IonItem>
          <IonItem>
            <MagnumInput value={phone} onChange={setUser('phone')} label="Phone"/>
          </IonItem>
          <IonItem>
            <MagnumInput value={email} onChange={setUser('email')} autoComplete="username" label="Email"/>
          </IonItem>
          {newUser && <>
            <IonItem>
              <MagnumInput type="password" value={password} onChange={getValue(setPassword)} autoComplete="new-password" label="Password"/>
            </IonItem>
            <IonItem>
              <MagnumInput type="password" value={passwordConfirmation} onChange={getValue(setPasswordConfirmation)} autoComplete="new-password" label="Password Confirmation"/>
            </IonItem>
          </>}
        </IonList>
        <div className="ion-padding">
          {errors && <p className="error">{errors}</p>}
          <IonButton type="submit" disabled={loading} expand="block">
            {loading && <IonSpinner name="lines-sharp-small"/>}
            {actionLabel}
          </IonButton>
          <div className="ion-margin-top">
            <IonRouterLink routerLink="/userinfo">Why does Magnum need this information?</IonRouterLink>
          </div>
          <div className="ion-margin-top">
            <IonRouterLink routerLink="/privacy">Privacy Policy</IonRouterLink>
          </div>
          {!newUser && (
            <div className="ion-margin-top">
              <IonRouterLink color="danger" routerLink="/destroy_requests">Delete Account</IonRouterLink>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
