import SLIDER from 'images/slider.png';

export default function SoilTexturePatterns({idNonce}) {
  return (
    <defs>
      <pattern id={`slider-control-${idNonce}`} width="14" height="10">
        <image xlinkHref={SLIDER} x="0" y="0" width="14" height="10" />
      </pattern>
      <pattern id={`grout`} patternUnits="userSpaceOnUse" width="8" height="8">
        <rect fill="#888888" x="0" y="0" width="8" height="8" />
        <polygon fill="#bbbbbb" stroke="#555555" points="1,1 0.4,2 1.5,1.5" strokeWidth="0.2" />
        <polygon fill="#bbbbbb" stroke="#555555" points="2,6 1.3,7.3 2.7,7.1" strokeWidth="0.2" />
        <polygon fill="#bbbbbb" stroke="#555555" points="5,5 6,5.4 4.8,6.2" strokeWidth="0.2" />
        <polygon fill="#bbbbbb" stroke="#555555" points="4,4 3.2,3 3.1,4.3" strokeWidth="0.2" />
        <polygon fill="#bbbbbb" stroke="#555555" points="5,0.8 4.5,1.6 5.4,1.6" strokeWidth="0.2" />
        <polygon fill="#bbbbbb" stroke="#555555" points="7,3.4 7.6,4.5 7.7,3.2" strokeWidth="0.2" />
      </pattern>
      <pattern id={`pattern-clay-${idNonce}`} patternUnits="userSpaceOnUse" width="8" height="8">
        <rect fill="#989383" x="0" y="0" width="8" height="8" />
        <line x1="-1" y1="11" x2="11" y2="-1" strokeWidth="0.5" stroke="#b6ae96"/>
        <line x1="-1" y1="3" x2="3" y2="-1" strokeWidth="0.5" stroke="#b6ae96"/>
        <line x1="-1" y1="7" x2="7" y2="-1" strokeWidth="0.5" stroke="#a7a08c"/>
        <line x1="-1" y1="15" x2="15" y2="-1" strokeWidth="0.5" stroke="#a7a08c"/>
      </pattern>
      <pattern id={`pattern-sand-${idNonce}`} patternUnits="userSpaceOnUse" width="2" height="2">
        <rect fill="#a2997b" x="0" y="0" width="2" height="2" />
        <circle fill="#634b09" cx="1" cy="1" r="0.3" />
      </pattern>
      <pattern id={`pattern-silt-${idNonce}`} patternUnits="userSpaceOnUse" width="8" height="8">
        <rect fill="#bbb299" x="0" y="0" width="8" height="8" />
        <line x1="-1" y1="7" x2="7" y2="-1" strokeWidth="0.7" stroke="#8a836c" strokeDasharray="2,1"/>
        <line x1="-1" y1="15" x2="15" y2="-1" strokeWidth="0.7" stroke="#8a836c" strokeDasharray="2,1"/>
      </pattern>
      <pattern id={`pattern-silty-clay-${idNonce}`} patternUnits="userSpaceOnUse" width="8" height="8">
        <rect fill="#624a05" x="0" y="0" width="8" height="8" />
        <line x1="-1" y1="7" x2="7" y2="-1" strokeWidth="0.5" stroke="#a79a77"/>
        <line x1="-1" y1="15" x2="15" y2="-1" strokeWidth="0.5" stroke="#a79a77"/>
        <line x1="-1" y1="3" x2="3" y2="-1" strokeWidth="0.5" stroke="#989382" strokeDasharray="2,1"/>
        <line x1="-1" y1="11" x2="11" y2="-1" strokeWidth="0.5" stroke="#989382" strokeDasharray="2,1"/>
      </pattern>
      <pattern id={`pattern-silty-sand-${idNonce}`} patternUnits="userSpaceOnUse" width="10" height="10">
        <rect fill="#372d10" x="0" y="0" width="10" height="10" />
        {_.times(5, (i) => _.times(5, (j) => (
          <circle key={`${i}-${j}`} fill="#564c2f" cx={i*2+1} cy={j*2+1} r="0.3" />
        )))}
        <line x1="-1" y1="3" x2="3" y2="-1" strokeWidth="0.8" stroke="#867f68" strokeDasharray="1.5,1.5"/>
        <line x1="-1" y1="13" x2="13" y2="-1" strokeWidth="0.8" stroke="#867f68" strokeDasharray="1.5,1.5"/>
      </pattern>
      <pattern id={`pattern-bedrock-${idNonce}`} patternUnits="userSpaceOnUse" width="8" height="8">
        <rect fill="#444C4F" x="0" y="0" width="10" height="10" />
      </pattern>
      <pattern id={`pattern-sensitive-${idNonce}`} patternUnits="userSpaceOnUse" width="10" height="10">
        <rect fill="#746494" x="0" y="0" width="10" height="10" />
      </pattern>
    </defs>
  );
}
