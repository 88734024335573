import { IonIcon, IonItem, IonToggle} from '@ionic/react';
import { helpCircle } from 'ionicons/icons';
import ProjectSettingsActions from 'actions/ProjectSettingsActions';
import useHelpAlert from 'hooks/useHelpAlert';
import { getValue } from 'helpers/events';

export default function({value, onValueChange, property, label, help}) {
  const showHelp = useHelpAlert({title: label, ...(_.isPlainObject(help) ? help : {content: help})});
  const toggleLabel = help ? (
    <span className="clickable" onClick={help && showHelp}>
      {label} <IonIcon icon={helpCircle}/>
    </span>
  ) : label;

  const onChange = (checked) => ProjectSettingsActions.set({[property]: checked});
  const onIonChange = getValue(onValueChange || onChange);

  return (
    <IonItem>
      <IonToggle checked={value} onClick={onIonChange}>
        {toggleLabel}
      </IonToggle>
    </IonItem>
  );
}
