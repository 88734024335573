import { IonSelect, IonSelectOption } from '@ionic/react';
import countries from 'i18n-iso-countries';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const PRIORITY_COUNTRIES = ["US", "CA"];

const countryList = countries.getNames("en", {select: "official"});
const prioritizedCountryList = _.uniqBy([
  ..._.map(PRIORITY_COUNTRIES, (code) => ({code, name: countryList[code]})),
  ..._.map(countryList, (name, code) => ({code, name})),
], 'code');

export default function CountrySelect({value, onChange, ...props}) {
  value = value.replace("USA", "US");
  return (
    <IonSelect label="Country" value={value} onIonChange={onChange} {...props} data-testid="country-select">
      {prioritizedCountryList.map(({code, name}) => (
        <IonSelectOption key={code} value={code}>{name}</IonSelectOption>
      ))}
    </IonSelect>
  );
}
