import { createSelector } from 'reselect';

import {
  BLOW_COUNT_INTERVAL_E, HELIX_CAP_COEF, SHAFT_CAP_COEF, SHAFT_FRICTION_COEF, SHAFT_FRICTION_REDUCTION, LEAD_GROUT_STRENGTH_N70
} from 'lib/constants';
import SoilLabels from 'config/soil-labels';
import PartList from './part-list';

function safe(value, safe_value) {
  return isNaN(value) ? (safe_value ?? 1) : value;
}

const UNDEFINED_SOIL = {value: 'undefined', label: 'Undefined', soilCoef: 0, coneCoef: 0};

export const lookupSoilAtDepth = (boundaries, soilList) => {
  return (depth) =>{
    const layerIndex = _.sortedIndex(boundaries, depth);
    return _.find(SoilLabels, {'value': _.get(soilList, [layerIndex, 'name'])}) || UNDEFINED_SOIL;
  };
};

export const testUnitConversion = (oldUnit, newUnit, boundaries, soilList) => {
  return (c, i) => {
    const soil = lookupSoilAtDepth(boundaries, soilList)(i * BLOW_COUNT_INTERVAL_E);
    return _.round(newUnit?.toDisplay(oldUnit?.toBase(c, soil), soil), newUnit?.precision);
  }
};

export const requiredInstallTorque = ({data: {loads, safetyFactor}}, loadIndex=0) => {
  const {compression, tension, pileSegments} = _.get(loads, loadIndex) || {};
  const lead = _.find(pileSegments, {lead_extension: 'Lead'});
  if (!lead) return null;
  return Math.max(tension, compression) * safetyFactor / lead.torque;
};

export const calculateSTDData = (meanSeries, stdSeries, sigma=1) => {
  return _.zip(meanSeries, stdSeries).map(([mean=0, std=0]) => {
    return Math.max(mean + (std * sigma), 0);
  });
};

const getProject = (project) => project;
const getLoadIndex = (_project, loadIndex) => loadIndex || 0;
const getSigma = (_project, _loadIndex, sigma) => sigma || 0;
const getGraphFunction = (_project, _loadIndex, _sigma, graphFunction) => graphFunction;

const getData = (project) => project.data;
const getSettings = (project) => project.settings;
const getDepth = (_project, _loadIndex, _sigma, depth) => depth;
const getOverrides = (_project, _loadIndex, _sigma, _depth, overrides) => overrides || {};

const getTestUnit = createSelector(getSettings, (settings) => settings?.testUnit);
const getLengthUnit = createSelector(getSettings, (settings) => settings?.lengthUnit);

const getBlowCounts = createSelector(
  getData, getSigma,
  (data, sigma) => calculateSTDData(data?.blowCounts, data?.blowCountsSD, sigma)
);
const getSafetyFactor = createSelector(
  getData, getOverrides,
  (data, overrides) => overrides.safetyFactor ?? data?.safetyFactor
);
const getLoad = createSelector(getData, getLoadIndex, (data, loadIndex) => data?.loads?.[loadIndex]);
const getPileSegments = createSelector(getLoad, (load) => load?.pileSegments);
const getShafts = createSelector(getPileSegments, (pileSegments) => PartList.shafts(pileSegments));
const getHelices = createSelector(getPileSegments, (pileSegments) => PartList.helices(pileSegments));
const getPileLength = createSelector(getPileSegments, (pileSegments) => PartList.totalLength(pileSegments));
const getAboveGrade = createSelector(getLoad, (load) => load.aboveGrade);
const getGroutedShaft = createSelector(
  getLoad, getOverrides,
  (load, overrides) => overrides.groutedShaft ?? load.groutedShaft
);
const getIncludeShaftFriction = createSelector(
  getLoad, getOverrides,
  (load, overrides) => overrides.includeShaftFriction ?? load.includeShaftFriction
);
const getPostGrout = createSelector(
  getGroutedShaft, getLoad,
  (groutedShaft, load) => groutedShaft ? load.postGroutLead : false
);
const getBatterAngleAdjustment = createSelector(
  getLoad,
  (load) => {
    const angle = load.batteredPile ? load.batterAngle || 0 : 0;
    return Math.cos((angle * Math.PI) / 180);
  }
);
const getGroutDiameter = createSelector(
  getLoad,
  (load) => {
  return load.groutedShaft ? load.groutDiameter : false}
);
const getRed = createSelector(
  getIncludeShaftFriction,
  getLoad,
  getGroutedShaft,
  (includeShaftFriction, {reduceShaftFriction}, groutedShaft) => {
    if (groutedShaft) return 1;
    const shaftFriction = includeShaftFriction ? SHAFT_FRICTION_COEF : 0;
    return reduceShaftFriction ? (shaftFriction * SHAFT_FRICTION_REDUCTION) : shaftFriction;
  }
);

const getSoilAtDepth = createSelector(
  getData, getDepth, getBatterAngleAdjustment,
  (data, depth, batterAngleAdjustment) => {
    const {boundaries, soilTypes} = data;
    try {
      return lookupSoilAtDepth(boundaries, soilTypes)(depth * batterAngleAdjustment);
    } catch (e) {
      console.warn("Failed to find soil type at provided depth", data, depth * batterAngleAdjustment)
      return _.first(SoilLabels);
    }
  }, {
    maxSize: 100
  }
)
const getSoilCoefAtDepth = createSelector(
  getSoilAtDepth,
  (soilAtDepth) => soilAtDepth.soilCoef
);
const getShaftSoilCoefAtDepth = createSelector(
  getSoilAtDepth,
  getLoad,
  (soilAtDepth, load) => {
    if (soilAtDepth.value === 'sensitive' && !load.includeFillSensitive) return 0;
    if (soilAtDepth.value === 'bedrock' && !load.includeBedrock) return 0;
    return soilAtDepth.soilCoef;
  }
);
const getBlowCountAtDepth = createSelector(
  getBlowCounts, getDepth, getSoilAtDepth, getBatterAngleAdjustment, getTestUnit,
  (blowCounts, depth, soilAtDepth, batterAngleAdjustment, testUnit) => {
    const blowCountIndex = Math.max(depth * batterAngleAdjustment / BLOW_COUNT_INTERVAL_E, 0);
    const blowCount1 = blowCounts[Math.floor(blowCountIndex)];
    const blowCount2 = blowCounts[Math.ceil(blowCountIndex)];
    const interpolation = (blowCount2 - blowCount1) * (blowCountIndex % 1);
    const rawBlowCount = blowCount1 + interpolation;
    return testUnit?.toBase(rawBlowCount, soilAtDepth);
  }, {
    maxSize: 100
  }
)

const getHelix = (project, loadIndex, sigma, depth, overrides, shaftOrHelix) => shaftOrHelix;
const getShaft = getHelix;

const getCapFromHelix = createSelector(
  getDepth, getHelix, getPostGrout, getAboveGrade, getBlowCountAtDepth, getSoilCoefAtDepth,
  (depth, helix, postGroutLead=false, aboveGrade, blowCountAtDepth, soilCoefAtDepth) => {
    if (depth <= Math.max(0, -aboveGrade)) {
      return 0;
    } else {
      const soilStrength = Math.max(blowCountAtDepth, postGroutLead ? LEAD_GROUT_STRENGTH_N70 : 0);
      return HELIX_CAP_COEF * soilCoefAtDepth * soilStrength * helix.area;
    }
  }, {
    maxSize: 200
  }
);

const getAverageSoilFactor = createSelector(
  getProject, getLoadIndex, getSigma, getDepth, getOverrides, getShaft, getShaftSoilCoefAtDepth, getBlowCountAtDepth,
  (project, loadIndex, sigma, depth, overrides, shaft, soilCoefAtDepth, blowCountAtDepth) => {
    return _.mean([
      soilCoefAtDepth * blowCountAtDepth,
      ..._.times(shaft.shaftLength, (n) => (
        getShaftSoilCoefAtDepth(project, loadIndex, sigma, depth + n, overrides)
          * getBlowCountAtDepth(project, loadIndex, sigma, depth + n, overrides)
      ))
    ]);
  }, {
    maxSize: 200
  }
);

const getCapFromShaft = createSelector(
  getDepth, getShaft, getRed, getGroutDiameter, getAboveGrade, getAverageSoilFactor,
  (depth, shaft, red, groutDiameter, aboveGrade, averageSoilFactor) => {
    if (depth <= Math.max(0, -aboveGrade)) {
      return 0;
    } else if (!_.isEmpty(shaft.helices)) {
      return 0;
    } else {
      const diameter = groutDiameter || shaft.diameter;
      return SHAFT_CAP_COEF * averageSoilFactor * diameter * shaft.shaftLength * red;
    }
  }, {
    maxSize: 100
  }
);

const getShaftCap = createSelector(
  getProject, getLoadIndex, getSigma, getDepth, getOverrides, getShafts,
  (project, loadIndex, sigma, depth, overrides, shafts) => {
    return shafts.map((shaft) => {
      const factionOfShaftWithinDepth = _.clamp((depth - shaft.positionFromTop) / shaft.shaftLength, 0, 1);
      return factionOfShaftWithinDepth * getCapFromShaft(project, loadIndex, sigma, shaft.positionFromTop, overrides, shaft);
    }).reduce(_.add, 0);
  }, {
    maxSize: 120
  }
);
const getHelixCap = createSelector(
  getProject, getLoadIndex, getSigma, getDepth, getOverrides, getHelices,
  (project, loadIndex, sigma, depth, overrides, helices) => {
    return helices.map((helix) => {
      const helixDepth = depth - helix.positionFromBottom;
      return getCapFromHelix(project, loadIndex, sigma, helixDepth, overrides, helix);
    }).reduce(_.add, 0);
  }, {
    maxSize: 120
  }
);

const totalCapacityAtDepth = createSelector(
  getHelixCap, getShaftCap, getSafetyFactor,
  (helixCap, shaftCap, safetyFactor) => safe((helixCap + shaftCap) / safetyFactor), {
    maxSize: 60
  }
);

const getMaxDepth = createSelector(
  getLengthUnit, getPileLength,
  (lengthUnit, pileLength) => lengthUnit?.toDisplay(pileLength)
);
const getInterval = createSelector(getSettings, (settings) => settings?.interval);

const buildGraphData = createSelector(
  getProject, getLoadIndex, getSigma, getGraphFunction, getOverrides, getMaxDepth, getInterval, getLengthUnit,
  (project, loadIndex, sigma, graphFunction, overrides, maxDepth, interval, lengthUnit) => {
    const dataSet = [];
    for (let depth = 0; depth < (maxDepth + interval/2); depth += interval) {
      dataSet.push({y: depth, x: graphFunction(project, loadIndex, sigma, lengthUnit?.toBase(depth), overrides)});
    }
    return dataSet;
  }
)

export const capacities = createSelector(
  getProject, getLoadIndex, getSigma,
  (project, loadIndex, sigma) => buildGraphData(project, loadIndex, sigma, totalCapacityAtDepth)
);

const getBaseCapacity = createSelector(
  getProject, getLoadIndex, getSigma, getDepth,
  (project, loadIndex, sigma, depth) => {
    const overrides = {
      safetyFactor: 1,
      groutedShaft: false,
    };
    return totalCapacityAtDepth(project, loadIndex, sigma, depth, overrides);
  }
);
const getMaxTorque = createSelector(
  getPileSegments,
  (pileSegments) => PartList.maxTorque(pileSegments)
);

const torque = createSelector(
  getBaseCapacity, getMaxTorque,
  (baseCapacity, maxTorque) => safe(baseCapacity / maxTorque, 0)
);
export const torques = createSelector(
  getProject, getLoadIndex, getSigma,
  (project, loadIndex, sigma) => buildGraphData(project, loadIndex, sigma, torque)
);
