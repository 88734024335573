import { createSelector } from "reselect";

export const isAdmin = (state) => state?.user?.userInfo?.is_admin;
export const allProjectsSelector = createSelector(
  _.property('publicProjects'),
  _.property('projects'),
  (publicProjects = [], projects = []) => {
    return _.uniqBy([...publicProjects, ...projects], 'id');
  }
);
export const appIsOnline = _.property('online');
