import { IonRouterLink, IonContent, IonItem, IonToggle } from '@ionic/react';
import { connect } from 'react-redux';

import { Geolocation } from '@capacitor/geolocation';

import { allProjectsSelector, isAdmin } from 'selectors/AppSelector';
import { withCoords } from 'selectors/geoSelectors';

import makeCancelable from 'helpers/cancelable-promise';
import { getValue } from 'helpers/events';
import ProjectActions from 'actions/ProjectActions';
import ProjectsLayout from 'components/layouts/Projects';
import { withOnline } from 'components/helpers/online-helper';
import Map, {
  Marker, InfoWindow, position, markerIconFor, US_CENTER
} from 'components/helpers/map';

function mapStateToProps(state, _ownProps) {
  return {
    projectList: allProjectsSelector(state),
    isAdmin: isAdmin(state),
  }
}
function mapDispatchToProps(_dispatch) {
  return {
    getPublicProjects: ProjectActions.getPublicProjects,
    getProjects: ProjectActions.getProjects,
  }
}
function withReduxConnect(Component) {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
}

class GlobalProjects extends React.Component {
  state = {
    center: US_CENTER,
    displayedProject: null,
    showUnpublished: false,
    dirty: false,
  }

  map = React.createRef()

  componentDidMount() {
    if (this.props.online) this.loadProjects();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.online && this.props.online) this.loadProjects();
  }

  componentWillUnmount() {
    this._geolocate && this._geolocate.cancel();
  }

  loadProjects = () => {
    this.props.getPublicProjects();
    this.props.getProjects();
    if (Geolocation) {
    this._geolocate = makeCancelable(Geolocation.getCurrentPosition());
      this._geolocate.promise.then((position) => {
        if (!this.state.dirty) this.setState({
          center: {lat: position.coords.latitude, lng: position.coords.longitude}
        });
      }).catch(_.noop);
    }
  }

  selectMarker = (id, position) => {
    this.setState(({center}) => ({displayedProject: id}));
    if (position) this.map.current.panTo(position);
  }

  setShowUnpublished = (showUnpublished) => {
    this.setState({showUnpublished});
  }

  setCenter = () => {
    const center = this.map.current?.getCenter();
    if (center) this.setState({center, dirty: true});
  }

  offlineRender = () => {
    return (
      <ProjectsLayout>
        <IonContent>
          <p className="error">You must be online to view global projects.</p>
        </IonContent>
      </ProjectsLayout>
    );
  }

  render() {
    if (this.props.online) {
      return this.onlineRender();
    }

    return this.offlineRender();
  }

  onlineRender = () => {
    const {isAdmin, projectList} = this.props;

    const {center, displayedProject, showUnpublished} = this.state;
    const verifiedProjectList = _.reject(projectList, {verified: false, owned: false});
    const filteredProjectList = showUnpublished ? verifiedProjectList : _.reject(verifiedProjectList, {published: false, owned: false});
    return (
      <ProjectsLayout>
        <div className="flex-column">
          <Map
            onLoad={(map) => this.map.current = map }
            mapContainerClassName="maps-container"
            zoom={4}
            center={center}
            onCenterChanged={this.setCenter}
          >
            {withCoords(filteredProjectList).map((project) => (
              <Marker key={project.id}
                position={position(project)}
                icon={markerIconFor(project)}
                onClick={() => this.selectMarker(project.id, position(project))}
                title={project.name}
              >
                {project.id == displayedProject && (
                  <InfoWindow onCloseClick={() => this.selectMarker(null)}>
                    <div>
                      <IonRouterLink routerLink={`/projects/${project.id}/`}>{project.name}</IonRouterLink>
                      {!project.owned && !project.published && "(unpublished)"}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
          </Map>
          {isAdmin && (
            <IonItem>
              <IonToggle checked={showUnpublished} onIonChange={getValue(this.setShowUnpublished)}>
                Show unpublished projects
              </IonToggle>
            </IonItem>
          )}
        </div>
      </ProjectsLayout>
    );
  }
}

export default withReduxConnect(withOnline(GlobalProjects));
