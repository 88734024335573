import { createContext, useEffect, useCallback } from 'react';
import { matchPath, withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { IonRouterLink, IonSpinner, IonButton, IonPopover, IonIcon } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';

import ProjectActions from 'actions/ProjectActions';

import { userInfoSelector } from 'selectors/userSelector';
import { projectByIdSelector, currentProjectIdSelector } from 'selectors/projectSelector';

import NavBar from 'components/layouts/helpers/navbar'
import ProjectMenu from 'components/project/ProjectMenu';
import MainLayout from 'components/layouts/MainLayout';
import { appUpdateRequired } from 'helpers/project-schema';

export const ProjectContext = createContext({});

const loadingScreen = (
  <div className="loading-container visible active">
    <div className="loading">
      <IonSpinner name="lines-sharp"/>
    </div>
  </div>
);

function ProjectLayout(props) {
  const {params: {id: idStr, segment}} = matchPath(props.match.url, {path: '/projects/:id/:segment?'});
  const id = parseInt(idStr, 10);
  const {history, InnerComponent} = props;

  const project = useSelector(projectByIdSelector(id));

  const projectStatus = useSelector(_.property('projectStatus'));
  const currentProjectId = useSelector(currentProjectIdSelector);
  const user = useSelector(userInfoSelector);

  const getProject = useCallback(_.throttle(() => {
    if (!projectStatus?.error && !project || project?.id !== id) ProjectActions.getProject(id);
  }, 2_000, {leading: true}), [projectStatus, project, id]);

  useEffect(getProject, [id]);

  useEffect(() =>{
    if (currentProjectId !== id) {
      ProjectActions.setCurrentProject(id);
    }
  }, [id]);

  useEffect(() => {
    if (!project && projectStatus?.error) {
      history.push('/projects');
    }
  }, [project, projectStatus?.error]);

  const {owned, name} = project ?? {};
  const readonly = !project?.owned && !user.global_editor;
  const projectLoaded = project && name && user;

  if (projectLoaded && appUpdateRequired(project)) {
    return (
      <MainLayout
        title={project?.name}
        parentName="Projects"
        parentPath={owned ? "/projects" : "/global-projects"}
      >
        <div className="padding">
          <p className="error">
            This project was created with a newer version of GeoApp. Please update to view.
          </p>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout
      title={
        <IonRouterLink routerLink={`/projects/${id}/settings`} color="light">
          {projectLoaded && project?.name || (projectStatus?.error ? "error" : "loading")}
        </IonRouterLink>
      }
      parentName="Projects"
      parentPath={owned ? "/projects" : "/global-projects"}
      primaryButton={<>
        <IonButton fill="clear" id={`project-menu-${segment}`} title="Project Menu">
          <IonIcon icon={ellipsisHorizontal} size="large" slot="end"/>
        </IonButton>
        <IonPopover trigger={`project-menu-${segment}`} dismissOnSelect triggerAction="click">
          <ProjectMenu project={project}/>
        </IonPopover>
      </>}
      navBar={
        <NavBar pathPrefix={`/projects/${id}/`} tabs={[
          {title: "Loads", path: "loads"},
          {title: "Soil", path: "soil"},
          {title: "Pile", path: "pile"},
          {title: "Results", path: "results"}
        ]}/>
      }
    >
      <ProjectContext.Provider value={project}>
        {projectLoaded ? (
          <InnerComponent user={user} readonly={readonly}/>
        ) : projectStatus?.error ? (
            <div className="ion-padding"><p className="error">{projectStatus?.errorMsg}</p></div>
          ) : loadingScreen
        }
      </ProjectContext.Provider>
    </MainLayout>
  );
};

export default withRouter(ProjectLayout)
