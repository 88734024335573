import { IonIcon, IonList, IonItem, IonLabel } from '@ionic/react';
import { removeCircle } from 'ionicons/icons';
import SoilLogCallout from './SoilLogCallout';

export default function SoilLogDeleteCallout({y, removeSoilLayer}) {
  return (
    <SoilLogCallout y={y}>
      <IonList lines="none" color="dark">
        <IonItem onClick={removeSoilLayer} button detail="false">
          <IonIcon icon={removeCircle} size="large" color="dark" slot="start"/>
          <IonLabel>Remove Boundary</IonLabel>
        </IonItem>
      </IonList>
    </SoilLogCallout>
  );
}
