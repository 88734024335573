import { IonButton, IonInput, IonItem, IonList } from "@ionic/react";

export default function ChangePassword(props) {
  const {
    changePassword,
    onCodeChange,
    newPassword,
    submittedCode,
    onPasswordChange,
    resetToken
  } = props;

  return <>
    <IonList>
      {submittedCode !== 'prefilled' && (
        <IonItem>
          <IonInput
            label="6 Digit Security Code"
            type="text"
            name="submittedCode"
            value={submittedCode}
            placeholder="security code from email"
            onIonChange={onCodeChange}
            labelPlacement="floating"/>
        </IonItem>
      )}

      <IonItem>
        <IonInput
          label="New Password"
          type="password"
          name="newPassword"
          value={newPassword}
          placeholder="new password"
          onIonChange={onPasswordChange}
          labelPlacement="floating"/>
      </IonItem>
    </IonList>

    <IonButton onClick={changePassword} expand="block">
      Change Password
    </IonButton>

    <IonButton onClick={resetToken} color="dark" expand="block">
      Get New Code
    </IonButton>

    <div className="ion-padding">
      <h3>Password Reset Instructions</h3>
      <p>The application is sending an email to the address provided with a 6 digit security code for the requested password reset.
      Please enter the code provided in the email with your new password and click the 'Change Password' button.</p>
      <p>If you did not receive an email (may take several minutes before arriving), please click the 'Get New Code' to
      re-enter an email address and receive a new security code.</p>
    </div>
  </>;
}
