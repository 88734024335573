import LengthUnits from 'config/length-units';
import SmallLengthUnits from 'config/small-length-units';
import ForceUnits from 'config/force-units';
import { soilTestUnit } from 'config/soil-test-units';
import { MAX_LEVEL, BLOW_COUNT_INTERVAL, CURRENT_SCHEMA_VERSION } from 'lib/constants';

export const PILE = {
  diameter: 3.0,
  coupling: 'Bolted',
  coating: 'Galvanized',
  wall: 0.25
};

const SYSTEM = 'english';
const defaultInterval = BLOW_COUNT_INTERVAL[SYSTEM];
export const SETTINGS = {
  system: SYSTEM,
  unitType: 'tons-feet',
  lengthUnit: LengthUnits['feet'],
  smallLengthUnit: SmallLengthUnits['inches'],
  forceUnit: ForceUnits['tons'],
  interval: defaultInterval,
  testUnit: soilTestUnit('SPT 70', SYSTEM)
};
export const LOAD = Object.freeze({
  id: "00000000-0000-0000-0000-000000000000",
  name: "",
  compression: null,
  tension: null,
  lateral: null,
  pileCount: 1,
  pileSegments: [],
  aboveGrade: 0,
  groutedShaft: false,
  groutDiameter: '',
  pileOptions: PILE,
  includeShaftFriction: false,
  reduceShaftFriction: false,
  includeFillSensitive: false,
  includeBedrock: false
});
export const SOIL_TYPES = Object.freeze([
  {id: 0, name: 'sand'}
]);
export const DEFAULT_BLOW_COUNT = Object.freeze(_.times(MAX_LEVEL/defaultInterval + 1, _.constant(0)));
export const DATA = Object.freeze({
  schema: CURRENT_SCHEMA_VERSION,
  safetyFactor: 2,
  loads: [LOAD],
  blowCounts: DEFAULT_BLOW_COUNT,
  boundaries: Object.freeze([MAX_LEVEL]),
  soilTypes: SOIL_TYPES,
  bulkSoil: null
});

export const PROJECT = Object.freeze({settings: SETTINGS, data: DATA});

export const DEFAULT_PROJECT_STATUS = {
  error: false,
  errorMsg: "",
  projectID: null
};
