import projects from 'reducers/projects';
import products from 'reducers/products';
import currentProject from 'reducers/projects/current';
import projectStatus from 'reducers/project-status';
import user from 'reducers/user';
import publicProjects from 'reducers/projects/public';
import online from 'reducers/online';

export default function rootReducer(state={}, action) {
  return currentProject({
    projects: projects(state.projects, action),
    products: products(state.products, action),
    projectStatus: projectStatus(state.projectStatus, action),
    publicProjects: publicProjects(state.publicProjects, action),
    user: user(state.user, action),
    online: online(state.online, action)
  }, action);
}
