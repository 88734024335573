import LengthUnits from 'config/length-units';
import SmallLengthUnits from 'config/small-length-units';
import ForceUnits from 'config/force-units';
import {soilTestUnit} from 'config/soil-test-units';
import * as DEFAULTS from 'reducers/projects/defaults';
import {normalizeSegments} from 'reducers/products';

export function normalizeSettings(projectSettings={}) {
  projectSettings = projectSettings || {};

  const {
    lengthUnit: {value:lengthUnitType}={}, smallLengthUnit: {value:smallLengthUnitType}={},
    forceUnit: {value:forceUnitType}={}, testUnit: {value:SoilTestUnitType}={},
    ...settings
  } = projectSettings;

  const {
    system:defaultSystem,
    lengthUnit,
    forceUnit,
    smallLengthUnit,
    testUnit,
    ...defaults
  } = DEFAULTS.SETTINGS;

  const system = settings.system || defaultSystem;

  return {
    ...defaults,
    ...settings,
    system,
    pile: {...DEFAULTS.PILE, ...settings.pile},
    lengthUnit: LengthUnits[lengthUnitType] || lengthUnit,
    smallLengthUnit: SmallLengthUnits[smallLengthUnitType] || SmallLengthUnits[lengthUnitType] || smallLengthUnit,
    forceUnit: ForceUnits[forceUnitType] || forceUnit,
    testUnit: soilTestUnit(SoilTestUnitType || testUnit.value, system)
  };
}

export function normalizeData(initialData={}) {
  initialData = initialData || {};

  const {pileSegments=[], ...data} = initialData;
  return {
    ...DEFAULTS.DATA,
    ...data,
    pileSegments: normalizeSegments(pileSegments || [])
  };
}

export function normalizeProject(project={}) {
  return {
    ...DEFAULTS.PROJECT,
    ...project,
    settings: normalizeSettings(project.settings),
    data: normalizeData(project.data)
  };
};
