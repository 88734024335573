import { useStore, Provider as ReduxProvider, useSelector } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { IonItem, IonIcon, IonSpinner } from '@ionic/react';
import { cloudDownload } from 'ionicons/icons';

import API from 'API';
import { cutSheetPages } from 'helpers/report';
import { productTypesSelector } from 'selectors/productSelector';
import { withOnline } from 'components/helpers/online-helper';
import Report from './Report';

class ReportButton extends React.Component {
  state = {loading: false}

  download = (e) => {
    const {online, project, productTypes, ReduxPassthrough} = this.props;
    if (!online) {
      return alert("You must be online to download a report.");
    }
    const markup = renderToStaticMarkup(
      <ReduxPassthrough>
        <Report project={project}/>
      </ReduxPassthrough>
    );
    const cut_sheet_pages = cutSheetPages(project, productTypes);
    this.setState({loading: true});
    API.downloadReport({id: project.id, markup, cut_sheet_pages, callback: this.openToPdf});
  }

  openToPdf = ({url}) => {
    window.location.href = url;
    this.setState({loading: false});
  }

  render() {
    if (!this.props.online) return null;
    return (
      <IonItem iconLeft onClick={this.download}>
        {this.state.loading ? (
          <IonSpinner name="lines-sharp-small" slot="start"/>
        ) : (
          <IonIcon icon={cloudDownload} slot="start"/>
        )}
        Download Report (pdf)
      </IonItem>
    );
  }
}

function withReduxProvider(Component) {
  return function ReduxStoreWrapper(props) {
    const store = useStore();
    const productTypes = useSelector(productTypesSelector);
    function ReduxPassthrough({children}) {
      return <ReduxProvider store={store}>{children}</ReduxProvider>
    };
    
    return <Component {...props} productTypes={productTypes} ReduxPassthrough={ReduxPassthrough}/>;
  }
}

export default withReduxProvider(withOnline(ReportButton));
