import { set } from "lodash/fp";

const DEFAULT = {
  userInfo: {},
  loginInfo: {},
  resetToken: null,
  userStoreErrors: '',
  regStatus: {
    errors: null,
    userId: null
  }
};

export default function user(state=DEFAULT, action) {
  switch(action.type) {
    case 'LOGIN':
      return {
        ...state,
        userStoreErrors: '',
        authentication: { token: action.token },
        userInfo: {...state.userInfo, ...(action.userInfo || {}), email: state.userInfo.email || action.email}
      };
    case 'NEW_REGISTRATION':
      if (action.registration.errors ?? true) {
        return {
          ...state,
          userInfo: action.registration.user,
          regStatus: {
            errors: null, userId: action.registration.user.id
          },
          authentication: {token: action.registration.access_token},
        };
      }
      return state;
    case 'AUTHENTICATION_ERROR':
      return {...state, userStoreErrors: action.errors};
    case 'DESTRUCTION_ERROR':
      return {...state, userStoreErrors: action.errors, destroyRequestSuccessful: null};
    case 'REGISTRATION_ERROR':
      return {...state, regStatus: {errors: action.errors}};
    case 'ACCEPTANCE':
      if (!action.acceptance.errors) {
        return {...state,
          regStatus: {...state.regStatus, accepted: true}
        };
      }
      return {...state,
        regStatus: {
          ...state.regStatus,
          errors: action.acceptance.errors
        }
      };
    case 'RECEIVED_USER_INFO':
      return {...state, userInfo: action.userInfo};
    case 'user/settings/update':
      const newSettings = {..._.get(state, 'userInfo.settings', {}), ...action.settings};
      return set('userInfo.settings', newSettings, state);
    case 'DESTRUCTION_REQUEST':
      return {...DEFAULT, destroyRequestSuccessful: true};
    case 'DESTROYED_USER':
      return {...DEFAULT, destroySuccessful: true, userStoreErrors: null};
    case 'LOGOUT':
    case 'CLEAR_USER_INFO':
      return DEFAULT;
    case 'RECEIVED_PASSWORD_RESET':
      return {...state, resetToken: action.token };
    case 'RECEIVED_PASSWORD_RESET_ERROR':
      return {...state, userStoreErrors: action.errors};
    default:
      return state;
  }
}
