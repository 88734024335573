import { pick } from 'lodash/fp';
import { LOAD } from 'reducers/projects/defaults';

export const DELETE_LOAD_CONFIRM_MESSAGE =
  "Are you sure you want to delete this load and any pile selections for it?";

const A_CHAR_CODE = 'A'.charCodeAt();
export const loadNameDefault = (index) => `Load ${String.fromCharCode(A_CHAR_CODE + index)}`;
export const loadName = (load, index) => load.name || loadNameDefault(index);

export const loadChanged = (load) => {
  const significant = pick(['name', 'compression', 'tension', 'pileSegments']);
  return _.isEqual(significant(load), significant(LOAD));
};
