export class EventTargetProxy {
  _listeners = []
  _domnodeWas = null

  _updateListeners = () => {
    if (this.domnode.current !== this._domnodeWas) {
      this._listeners.forEach((type, method, options) => {
        this._domnodeWas.removeEventListener(type, method);
        this.domnode.current.addEventListener(type, method, options);
      })
      this._domnodeWas = this.domnode.current;
    }
  }

  domnode = (el) => {
    this.domnode.current = el;
    this._updateListeners();
  }

  addEventListener = (type, method, options) => {
    this._listeners.push([type, method, options]);
    this.domnode.current && this.domnode.current.addEventListener(type, method, options);
  }

  removeEventListener = (type, method, _options) => {
    this._listeners = _.reject(this._listeners, ([t, m]) => t === type && m === method);
    this.domnode.current && this.domnode.current.removeEventListener(type, method);
  }
}
