import { set } from 'lodash/fp';
import { useState } from 'react';
import { assignRight } from 'helpers/assignRight';

function useModel(initial) {
  const [model, setState] = useState(() => {
    if (_.isFunction(initial)) {
      initial = initial();
    }
    if (_.isObject(initial)) {
      return initial;
    }
  });

  const setProperty = _.curry((name, value) => setState(set(name, value)));
  const mergeModel = (model) => setState(assignRight(model));
  return [model, setProperty, mergeModel, setState];
}

export const withDirty = ([model, setProperty, mergeModel, setState]) => {
  const [dirty, setDirty] = useState(false);
  const dirtyWhen = (fn) => (...args) => {
    setDirty(true);
    return fn(...args);
  }
  const clean = () => setDirty(false);
  return [model, dirty, dirtyWhen(setProperty), dirtyWhen(mergeModel), clean, setState];
}

export default useModel;
