import LOGO from 'images/magnum-geoapp-logo.png';
import { usePageNumber } from 'hooks/usePageNumber';

const CURRENT_YEAR = new Date().getFullYear();

export default function CutSheetPage({number}) {
  const pageNumber = usePageNumber();

  return (
    <div className="page">
      <div className="page-header">
        <img src={window.location.origin + LOGO} className="uk-float-right logo" />
      </div>
      <div className="page-footer">
        <p>MAGNUM GeoApp&reg; Software, Version {VERSION}, Copyright {CURRENT_YEAR}.&nbsp; <b>Page MP{number ?? pageNumber}</b></p>
      </div>
    </div>
  );
}
