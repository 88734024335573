import { useDispatch } from 'react-redux';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { updateLoad } from 'actions/projectData';
import { stopPropagation, numerifyInputValue } from 'helpers/events';

export default function PileCount({load, pileCount, readonly}) {
  const dispatch = useDispatch();
  const changePileCount = stopPropagation(numerifyInputValue((value) => dispatch(updateLoad(load.id, {pileCount: value}))));

  return (
    <IonItem>
      <IonLabel>Pile Count</IonLabel>
      <IonInput type="number" min="1" step="1" value={pileCount} onIonChange={changePileCount} readonly={readonly}/>
    </IonItem>
  );
}
