import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import xhr from 'xhr';
import config from 'config/application';
import ServerActions from 'actions/ServerActions';
import { authHeaders, createParams, onSuccess, JSONonSuccess } from 'helpers/api';
import { appUpdateRequired } from 'helpers/project-schema';

const API = {
  getProjects() {
    xhr.get(config.apiHost + '/api/v1/projects',
      authHeaders(),
      JSONonSuccess(ServerActions.receivedProjectList())
    );
  },

  addProject({id, createdOfflineAt, ...project}) {
    xhr.post(config.apiHost + '/api/v1/projects',
      {json: { project, created_at: createdOfflineAt }, ...authHeaders()},
      onSuccess(ServerActions.receivedProject(id), ServerActions.projectCreateError(project, createdOfflineAt))
    );
  },

  getProject(projectId) {
    xhr.get(config.apiHost + `/api/v1/projects/${projectId}`,
      authHeaders(),
      JSONonSuccess(ServerActions.receivedProject(), ServerActions.projectError)
    );
  },

  deleteProject(projectId) {
    xhr.del(config.apiHost + `/api/v1/projects/${projectId}`,
      authHeaders(),
      JSONonSuccess(ServerActions.receivedProjectList({deleted: projectId}), ServerActions.projectDeleteError(projectId))
    );
  },

  updateProject(project) {
    if (project.createdOfflineAt) return;
    if (appUpdateRequired(project)) return;

    xhr.patch(config.apiHost + `/api/v1/projects/${project.id}`,
      {json: { project }, ...authHeaders()},
      onSuccess(ServerActions.receivedProject(), ServerActions.projectUpdateError(project))
    );
  },

  silentUpdateProject(project) {
    if (project.createdOfflineAt) return;
    xhr.patch(config.apiHost + `/api/v1/projects/${project.id}`,
      {json: { project }, ...authHeaders()},
      onSuccess(_.noop, ServerActions.projectUpdateError(project))
    );
  },

  getPublicProjects() {
    xhr.get(config.apiHost + '/api/v1/public_projects',
      authHeaders(),
      JSONonSuccess(ServerActions.receivedPublicProjectList)
    );
  },

  addRegistration(user) {
    xhr.post(config.apiHost + '/api/v1/signups',
      {json: {user}},
      onSuccess(ServerActions.newRegistration, ServerActions.registrationError)
    );
  },

  updateUser(user) {
    xhr.patch(config.apiHost + '/api/v1/user',
      {json: {user}, ...authHeaders()},
      onSuccess(ServerActions.receivedCurrentUser, ServerActions.registrationError)
    );
  },

  deleteUser() {
    return new Promise((resolve, reject) => {
      xhr.del(config.apiHost + '/api/v1/user',
        {...authHeaders()},
        onSuccess((body) => {
          ServerActions.destroyedUser(body);
          resolve(body);
        }, (error, statusCode) => {
          ServerActions.receivedDestroyError(error, statusCode);
          reject(new Error(error));
        })
      );
    });
  },

  deleteUnauthenticatedUser({email, token}) {
    xhr.patch(config.apiHost + '/api/v1/destroy_requests',
      {json: {email, token}},
      onSuccess(ServerActions.destroyedUser, ServerActions.receivedDestroyError)
    );
  },

  setDoNotTrack(do_not_track) {
    xhr.patch(config.apiHost + '/api/v1/user',
      {json: {user: {do_not_track}}, ...authHeaders()},
      _.noop
    );
  },

  passwordReset(email) {
    xhr.post(config.apiHost + `/api/v1/password_resets`,
      {json: { password_reset: { email: email } } },
      onSuccess(ServerActions.receivedPasswordReset, ServerActions.receivedLoginError)
    );
  },

  changePassword(new_password, authorization_token, email) {
    xhr.post(config.apiHost + `/api/v1/passwords`,
       {json: {password: {new_password, authorization_token, email}}},
      onSuccess(({access_token}) => {
        ServerActions.receivedAccessToken(access_token, email);
        API.initializeAppData();
      }, ServerActions.receivedLoginError)
    );
  },

  addAcceptance(userId, newsletter) {
    xhr.patch(config.apiHost + `/api/v1/signups/${userId}`,
      {json: {user: {newsletter}}, ...authHeaders()},
      onSuccess((response) => {
        ServerActions.newAcceptance(response);
      })
    );
  },

  initializeAppData() {
    API.getCurrentUser();
    API.getProductTypes();
  },

  setUserSettings(settings) {
    xhr.patch(config.apiHost + `/api/v1/user`,
      {json: {user: {settings}}, ...authHeaders()},
      onSuccess((response) => {
        ServerActions.receivedCurrentUser(response);
      })
    );
  },

  getProducts(filters) {
    return new Promise((resolve, reject) => {
      xhr.get(config.apiHost + `/api/v1/products${createParams(filters)}`,
        authHeaders(),
        JSONonSuccess(resolve, reject)
      );
    });
  },

  getProductTypes() {
    xhr.get(config.apiHost + `/api/v1/product_types`,
      authHeaders(),
      JSONonSuccess(ServerActions.receivedProductTypes)
    );
  },

  getCurrentUser() {
    xhr.get(config.apiHost + '/api/v1/user',
      authHeaders(),
      JSONonSuccess(ServerActions.receivedCurrentUser)
    );
  },

  downloadReport({id, markup, cut_sheet_pages, callback=_.noop}) {
    xhr.post(config.apiHost + `/api/v1/projects/${id}/report`,
      {json: {markup, cut_sheet_pages}, ...authHeaders()},
      onSuccess(callback)
    );
  },

  order({id, callback=_.noop}) {
    xhr.get(config.apiHost + `/api/v1/projects/${id}/order`,
      authHeaders(),
      JSONonSuccess(callback, callback)
    );
  },

  makeAuthRequest(username, password) {
    xhr.post(config.apiHost + '/oauth/token',
      {json: {grant_type: 'password', username, password}},
      onSuccess(function(response) {
        if (!response.access_token) return;

        ServerActions.receivedAccessToken(response.access_token, username);
        API.initializeAppData();
        if (window.device.platform == 'ios') {
          API.requestAppTrackingTransparency();
        }
      }, function({error}) {
        ServerActions.receivedLoginError(error);
      })
    );
  },

  requestDestroyUser(email) {
    xhr.post(config.apiHost + `/api/v1/destroy_requests`,
      {json: { email }},
      onSuccess(ServerActions.newDestroyRequest, ServerActions.receivedDestroyError)
    );
  },

  async requestAppTrackingTransparency() {
    const response = await AppTrackingTransparency.requestPermission();
    API.setDoNotTrack(response.status !== 'authorized');
  }
};

export default API;
