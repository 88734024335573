import { createSelector } from 'reselect';
import { userSettingsSelector } from './userSelector';

export const productTypesSelector = _.property('products.productTypeList');
export const unfilteredSegmentList = _.property('products.segmentList');

const getOptions = _.nthArg(1);
export const leadListSelectorBase = createSelector(
  getOptions,
  unfilteredSegmentList,
  userSettingsSelector.at('includeDualCutter'),
  (options, segments, includeDualCutter) => {
    return _.sortBy(filterLeadList(options, segments, includeDualCutter), ['length', 'area']);
  }
);
export const leadListSelector = (options) => (state) => leadListSelectorBase(state, options);
leadListSelector.defaults = leadListSelector();

export const leadBySKUSelector = (sku) => _.flow(leadListSelector, get(sku));

function dualFilter(includeDualCutter, segments) {
  if (includeDualCutter ?? true) return segments;

  return _.reject(segments, ({helices}) => _.some(helices, {type: 'dual'}));
}

function filterLengths({minlength=0, maxlength=1e6}, segments) {
  return _.filter(segments, (segment) => {
    return segment.length >= minlength && segment.length <= maxlength;
  });
}

function filterLeadList({length, pileLength, ...options}={}, segments, includeDualCutter) {
  const maxlength = isNaN(pileLength*1) ? undefined : pileLength * 12;

  const leads = _.filter(segments, {
    lead_extension: 'Lead',
    ...pickClean(options, 'prefix', 'diameter', 'pitch', 'coupling', 'coating', 'wall')
  });

  return dualFilter(includeDualCutter, filterLengths({maxlength}, leads));
}

function pickClean(obj, ...propNames) {
  return _.pickBy(_.pick(obj, propNames));
}
