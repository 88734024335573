import { useDispatch } from 'react-redux';
import { IonInput, IonItem, IonLabel, IonText } from '@ionic/react';
import { updateLoad } from 'actions/projectData';
import { titleize } from 'lib/text';
import { stopPropagation } from 'helpers/events';

export default function LoadInput({unit, loadType, load, readonly}) {
  const dispatch = useDispatch();
  const changeLoad = ({target}) => {
    const baseValue = unit.toBase(target.value);
    dispatch(updateLoad(load.id, {[loadType]: baseValue}));
  };
  const displayValue = _.round(unit?.toDisplay(load[loadType]), 2) || '';

  return (
    <IonItem>
      <IonLabel>{titleize(loadType)}</IonLabel>
      <IonText slot="end">{unit.abbreviation}</IonText>
      <IonInput type="number" value={displayValue} onIonInput={changeLoad} onIonChange={stopPropagation(changeLoad)} readonly={readonly}/>
    </IonItem>
  );
}
