import { IonButton, IonInput, IonItem, IonList } from "@ionic/react";
import { preventDefault } from 'helpers/events';

export default function PasswordReset({inputChange, sendPasswordRequest, value: email}) {
  return (
    <form onSubmit={preventDefault(sendPasswordRequest)}>
      <IonList>
        <IonItem>
          <IonInput
            label="Email"
            type="email"
            name="email"
            value={email}
            placeholder="yourname@example.com"
            onIonChange={inputChange}
            labelPlacement="floating"/>
        </IonItem>
      </IonList>

      <IonButton type="submit" expand="block">
        Reset Password
      </IonButton>
    </form>
  );
}
