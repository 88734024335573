import {useEffect, useState} from 'react';
import { IonButton } from '@ionic/react';

const MILLISECOND = 1;
const SECOND = 1000 * MILLISECOND;
const MINUTE = 60 * SECOND;
const INTERVAL = 30 * MINUTE;

const parseDom = (str) => new window.DOMParser().parseFromString(str, "text/html");
const getResponseDocument = async (rs) => _.get(parseDom(await rs.text()), 'documentElement');
const sameOrigin = (src) => new URL(src).origin === window.location.origin;
const scriptURL = (thisDocument) => {
  const scripts = _.invoke(thisDocument, 'getElementsByTagName', 'script');
  const matchedScript = _.find(scripts, (tag) => tag.src && sameOrigin(tag.src));
  return _.get(matchedScript, 'src');
};
const getServerScriptVersion = async () => {
  try {
    const response = await fetch(window.location);
    return scriptURL(await getResponseDocument(response));
  } catch (e) {
    if (e.toString() !== 'TypeError: Failed to fetch') {
      throw e;
    }
  }
};

export default function VersionChecker() {
  const [outOfDate, setOutOfDate] = useState(false);

  const compareAppVersions = async () => {
    const serverScriptSrc = await getServerScriptVersion();
    if (serverScriptSrc && serverScriptSrc !== scriptURL(document)) {
      setOutOfDate(true);
    }
  };

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      const timer = setInterval(compareAppVersions, INTERVAL);
      return () => clearInterval(timer);
    }
  }, []);

  const resetPage = () => {
    window.location.reload(true);
  }

  if (outOfDate) {
    return <div className="out-of-date">
      A new version is available.
      <IonButton onClick={resetPage} size="small">Reload</IonButton>
    </div>;
  }

  return null;
}
