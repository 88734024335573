import store from 'store';
import API from 'API';
import { Preferences } from '@capacitor/preferences';
import { currentProjectSelector, projectsSelector } from './selectors/projectSelector';

const API_SAVE_FREQUENCY = 1000;

export const persist = () => {
  const previous = {};
  store.subscribe(function() {
    const {authentication: {token=""}={}, userInfo={}} = _.get(store.getState(), 'user', {});
    const {email=""} = userInfo;
    if (token !== previous.token) {
      previous.token = token;
      Preferences.set({key: 'token', value: token});
    }
    if (email !== previous.email) {
      previous.email = email;
      Preferences.set({key: 'currentUserEmail', value: email});
    }
    if (userInfo !== previous.userInfo) {
      previous.userInfo = userInfo;
      Preferences.set({key: 'userInfo', value: JSON.stringify(userInfo)});
    }
    const {products=[]} = store.getState();
    if (products !== previous.products) {
      previous.products = products;
      Preferences.set({key: 'products', value: JSON.stringify(products)});
    }
  });
}

const truncateForChromeStorageLimit = (projects) => (
  window.device.platform === 'web' ? _.take(projects, 100) : projects
);

export const persistProject = _.debounce(() => {
  const currentProject = currentProjectSelector(store.getState());
  const global_editor = _.get(store.getState(), 'user.userInfo.global_editor');

  if (currentProject && (currentProject.owned || global_editor)) {
    API.silentUpdateProject(currentProject);

    const projects = projectsSelector(store.getState());
    Preferences.set({
      key: 'projectList',
      value: JSON.stringify(truncateForChromeStorageLimit(projects))
    });
  }
},
API_SAVE_FREQUENCY);


export const loadLocalStorage = async function() {
  await Preferences.migrate();
  await Preferences.removeOld();
  const {value:token} = await Preferences.get({key: 'token'});
  const wasLoggedIn = !_.isEmpty(token) && token !== 'undefined';
  if (wasLoggedIn) {
    let userInfo = {};
    const {value} = await Preferences.get({key: 'userInfo'});
    if (value) {
      try {
        userInfo = JSON.parse(value)
      } catch (e) {
        console.warn("Failed to parse userInfo", e);
      }
    }
    const {value:email} = await Preferences.get({key: 'currentUserEmail'});
    store.dispatch({type: 'LOGIN', token, email, userInfo});

    const {value:projectList} = await Preferences.get({key: 'projectList'});
    if (projectList) {
      try {
        store.dispatch({
          type: 'RECEIVED_PROJECT_LIST',
          projectList: JSON.parse(projectList) || []
        });
      } catch (e) {
        console.warn("Failed to parse projectList", e);
      }
    }

    const {value:products} = await Preferences.get({key: 'products'});
    if (products) {
      try {
        store.dispatch({type: 'RESTORE', products: JSON.parse(products)});
      } catch (e) {
        console.warn("Failed to parse products", e);
      }
    }
  }
}

export const syncOfflineProjects = () => {
  const {projects, online} = store.getState();
  if (online) {
    _.filter(projects, "deletedOfflineAt").map((project) => {
      API.deleteProject(project.id);
    });
    _.filter(projects, "createdOfflineAt").map((project) => {
      API.addProject(project);
    });
    _.filter(projects, "updatedOfflineAt").map(({updatedOfflineAt, ...project}) => {
      API.updateProject(project);
    });
  }
}

export default {persist, persistProject, loadLocalStorage, syncOfflineProjects};
