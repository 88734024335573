import { useState, useEffect } from 'react';
import { IonButton, useIonModal } from '@ionic/react';

import helpText from 'config/help-text';
import maxGraphDepth from 'lib/graph-depth';
import SoilActions from 'actions/SoilActions';
import BlowCountActions from 'actions/BlowCountActions';

import useHelpAlert from 'hooks/useHelpAlert';
import withProject from 'components/helpers/withProject';
import { bulkSoilConfirm } from 'components/helpers/confirm';
import SoilLogGraph from './SoilLogGraph';
import BulkImport from './BulkImport';

function SoilLog(props) {
  const {user, readonly, project, maxDim} = props;
  const [state, setState] = useState({callout: null, soilData: ''});
  
  const displayHelp = useHelpAlert(helpText.soilLog);

  useEffect(() => {
    const notReportPage = _.isUndefined(maxDim);
    const userDismissed = user?.settings?.soil_help ?? false;
    if (notReportPage && !userDismissed) displayHelp();
  }, []);

  const mergeState = (update) => setState((state) => ({...state, ...update}));

  const setSoilData = ({blowCounts, blowCountsSD, soilBoundaries, soilTypes, rawData}) => {
    dismissBulkImportDialog();
    BlowCountActions.setBlowCounts(blowCounts);
    SoilActions.setBlowCountsSD(blowCountsSD);
    if (soilTypes.length === soilBoundaries.length) {
      SoilActions.setSoilTypes(soilTypes);
      SoilActions.setSoilBoundaries(soilBoundaries);
    } else {
      console.warn("something went wrong with the bulk soil parse", blowCounts, soilBoundaries, soilTypes, rawData)
    }
    SoilActions.setBulkSoil(rawData);
  };

  const [presentBulkImportDialog, dismissBulkImportDialog] = useIonModal(BulkImport, {
    dismiss: (data, role) => dismissBulkImportDialog(data, role),
    project,
    onSave: setSoilData,
  });

  const setCallout = ({Component, ...calloutProps}={}) => {
    mergeState({callout: Component && <Component {...calloutProps} />});
  };

  return (
    <div className="soil-log" onClick={setCallout}>
      {!readonly && (
        <div style={{margin: '6px 0px 0px 20px'}}>
          <IonButton
            color="warning"
            size="small"
            onClick={() => bulkSoilConfirm(project.data.bulkSoil, SoilActions.resetSoil)}
          >
            RESET
          </IonButton>
          <IonButton
            color="light"
            size="small"
            className="float-right"
            onClick={() => presentBulkImportDialog({})}
          >
            BULK IMPORT
          </IonButton>
        </div>
      )}
      <div style={{position: 'relative'}}>
        <SoilLogGraph {...project.data} {...project.settings}
          maxDim={maxDim}
          maxDepth={maxGraphDepth(project)}
          readonly={readonly}
          setCallout={setCallout} />
        {state.callout}
      </div>
    </div>
  );
}

export default withProject(SoilLog);
