import { useContext } from 'react';
import { capacities } from 'lib/capacity-calculations';
import { ProjectContext } from 'components/layouts/Project';

export default function RequiredCapacity({load, loadIndex}) {
  const project = useContext(ProjectContext);
  const {tension, compression} = load ?? {};

  const maxCapacity = _.maxOf(capacities(project, loadIndex), 'x') ?? 0;
  const maxRequirement = _.max([tension, compression]);
  const requiredCapacityAchieved = maxCapacity >= maxRequirement;

  return (
    <div className="required-capacity">
      {maxRequirement > 0 ? (
        requiredCapacityAchieved ? (
          <div className="success">Required Capacity Achieved!</div>
        ) : (
          <div className="incomplete">Required Capacity Not Achieved</div>
        )
      ) : (
        <div className="unspecified">Specify Capacity in Loads</div>
      )}
    </div>
  );
}
