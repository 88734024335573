import { useSelector } from 'react-redux';
import {
  IonBackButton,
  IonButtons,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

import {loggedInSelector, userInfoSelector} from 'selectors/userSelector';
import { appIsOnline } from 'selectors/AppSelector';

import MainNav from 'components/MainNav';
import VersionChecker from './VersionChecker';
import MAGNUM_LOGO from 'images/magnum-geoapp-logo.png';

export default function MainLayout({
  children, navBar, footer, title, noBack, parentPath, parentName="", primaryButton, narrow
}) {
  const loggedIn = useSelector(loggedInSelector)
  const online = useSelector(appIsOnline);
  const user = useSelector(userInfoSelector);

  children = children || (
    <div className="ion-padding">
      <p>You are {!loggedIn && 'not'} logged in.</p>
    </div>
  );

  return <>
    <MainNav user={user} loggedIn={loggedIn}/>
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar className="top-nav">
          <img src={MAGNUM_LOGO} className="logo" />
          {!online && <p className="offline-indicator">Offline</p>}
          <p className="version">Version {VERSION}</p>
          <VersionChecker />
          <IonButtons slot="end"><IonMenuButton/></IonButtons>
        </IonToolbar>

        {title && (
          <IonToolbar color="dark" mode="ios">
          {!noBack && (
            <IonButtons slot="start">
              {parentPath ? (
                <IonButton routerLink={parentPath} routerDirection="back">
                  <IonIcon aria-hidden="true" icon={chevronBackOutline}/>
                  {parentName}
                </IonButton>
              ) : (
                <IonBackButton defaultHref="/"/>
              )}
            </IonButtons>
          )}
          <IonTitle>{title}</IonTitle>
          {primaryButton && <IonButtons slot="end">{primaryButton}</IonButtons>}
        </IonToolbar>
        )}
        {navBar}
      </IonHeader>
      <IonContent>
        {narrow ? <div className="narrow-container">{children}</div> : children}
      </IonContent>
      {footer && <IonFooter>{footer}</IonFooter>}
    </IonPage>
  </>;
}
